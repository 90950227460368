import smoothscroll from 'smoothscroll-polyfill';
export default {
    init() {
        this.node = document.querySelector('.StickySubNav');
        if( this.node ) {
            smoothscroll.polyfill();
            this.finalize();
        }
    },
    finalize() {
        var t = this;

        t.checkIsMobile();
        window.addEventListener('resize', function() {
            t.checkIsMobile();
        });

        // -- cross the line - //
            t.node.insertAdjacentHTML( 'beforebegin', '<div id="'+t.node.getAttribute('data-id')+'"class="StickySubNav--line"></div>' );
            var observer1 = new IntersectionObserver(
                function( entries, observer) {
                    entries.forEach( function( entry ,index ) {
                        if( entry.target.getAttribute('id') == t.node.getAttribute('data-id') ) {
                            if( entry.intersectionRatio == 0 ) {
                                t.node.classList.add('StickySubNav--sticky');
                            } else {
                                t.node.classList.remove('StickySubNav--sticky');
                            }
                        }
                    });
                },
                {
                    threshold: 0
                }
            );
            observer1.observe( document.getElementById( t.node.getAttribute('data-id') ) );
        // -- END -- //
            

        // -- check is in section -- //
            var items = t.node.querySelectorAll('.item__link');
            var observer2 = new IntersectionObserver(
                function( entries, observer) {
                    items.forEach( function( item ,index2 ) {
                        item.classList.remove('active');
                    });
                    entries.forEach( function( entry, index ) {
                        items.forEach( function( item ,index2 ) {
                            if( item.getAttribute('href') == '#'+entry.target.getAttribute('id') && entry.isIntersecting ) {
                                item.classList.add('active');
                            }
                        });

                    });
                },
                {   
                    rootMargin: '-50%',
                    threshold: 0
                }
            );
            items.forEach( function( item, index ) {
                if( item.getAttribute('href')[0] == '#' && document.getElementById( item.getAttribute('href').replace('#','') ) ) {

                    item.addEventListener('click', function(e) {
                        e.preventDefault();
                        let scrollPosition = document.getElementById( item.getAttribute('href').replace('#','') ).getBoundingClientRect().top + window.scrollY;
                        window.scrollTo({ 
                            top: scrollPosition - ( document.querySelector('header').offsetHeight +t.node.offsetHeight ),
                            left: 0, 
                            behavior: 'smooth'
                        }); 
                    });

                    observer2.observe( document.getElementById( item.getAttribute('href').replace('#','') ) );
                }
            });
        // -- END -- // 

    },
    checkIsMobile() {
        let t = this;
        let tempWidth = 0;
        t.node.querySelectorAll('.content__item').forEach( function( item, index ) {
            tempWidth += item.offsetWidth;
        });
        if( tempWidth + 50 > t.node.offsetWidth ) {
            t.node.classList.add('StickySubNav--mobile');
        } else {
            t.node.classList.remove('StickySubNav--mobile');
        }
    }
}