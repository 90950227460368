import smoothscroll from 'smoothscroll-polyfill';
import WysiwygTooltip from '../../Components/WysiwygTooltip/WysiwygTooltip';
import LanguageSelect from '../../Components/LanguageSelect/LanguageSelect';

export default {
    init() {
        if( document.querySelector('.Header--search') ) {
            var t = this;
            t.searchElements = null;
            t.node = document.querySelector('.Header--search');

            smoothscroll.polyfill();
            LanguageSelect.init();

            // -- switch form -- //
                document.querySelectorAll('input[name=form-type]').forEach( function( radio, index ) {
                    radio.addEventListener('change', function() {
                        t.searchCleanElements();
                        t.node.querySelector('.form__search').classList.remove('active');
                        t.node.querySelector('.form__search input').value = '';
                        t.node.querySelector('.form__reload').classList.remove('active');
                        t.node.querySelector('.form__reload input').value = '';
                        t.node.querySelector('.form__'+radio.value).classList.add('active');
                    });
                });
            // -- END -- //
            
            // -- disable search form default action - //
                t.node.querySelector('.form__search').addEventListener('submit', function(e){
                    e.preventDefault();
                });
            // -- END -- //

            // -- switch search element -- //
                var searchControl = document.querySelector('.Header--search .search__control');
                var searchCurrent = searchControl.querySelector('.results__current');
                var searchMax = searchControl.querySelector('.results__all');
                var searchNext = searchControl.querySelector('.buttons__next');
                var searchPrev = searchControl.querySelector('.buttons__prev');

                searchNext.addEventListener('click', function() {
                    if( parseInt(searchMax.textContent) != 0 ) {
                        if( parseInt(searchCurrent.textContent) < parseInt(searchMax.textContent) ) {
                            searchCurrent.textContent = parseInt(searchCurrent.textContent)+1;
                        } else {
                            searchCurrent.textContent = '1';
                        }
                        
                        if( document.querySelectorAll('marker.header__results')[ parseInt(searchCurrent.textContent)-1 ] ) {
                            t.searchScroll( document.querySelectorAll('marker.header__results')[ parseInt(searchCurrent.textContent)-1 ] );
                        }
                    } 
                });

                searchPrev.addEventListener('click', function() {
                    if( parseInt(searchMax.textContent) != 0 ) {
                        if( parseInt(searchCurrent.textContent) > 1 ) {
                            searchCurrent.textContent = parseInt(searchCurrent.textContent)-1;
                        } else {
                            searchCurrent.textContent = parseInt(searchMax.textContent);
                        }
                        
                        if( document.querySelectorAll('marker.header__results')[ parseInt(searchCurrent.textContent)-1 ] ) {
                            t.searchScroll( document.querySelectorAll('marker.header__results')[ parseInt(searchCurrent.textContent)-1 ] );
                        }
                    } 
                });

            // -- END -- //
            
        }

    },
    searchInDom( search = '', elements = [] ) {
        var t = this;
        t.searchElements = elements;
        t.searchCleanElements();

        if( search && elements.length > 0 ) {

            let found = 0;

            elements.forEach( function( single, index ) {
                single.innerHTML = single.innerHTML.replaceAll(search.toLowerCase(),'<marker class="header__results">'+search.toLowerCase()+'</marker>');
                single.innerHTML = single.innerHTML.replaceAll(search.toUpperCase(),'<marker class="header__results">'+search.toUpperCase()+'</marker>');
                single.innerHTML = single.innerHTML.replaceAll( search[0].toUpperCase() + search.slice(1) ,'<marker class="header__results">'+search[0].toUpperCase() + search.slice(1)+'</marker>');
                found += single.querySelectorAll('marker').length;
            });
            t.searchControl( found );

            // if( found > 0 ) {
            //     t.searchScroll( document.querySelectorAll('marker.header__results')[ 0 ] );
            // }

        }  else {
            t.searchControl( 0 );
        }
        WysiwygTooltip.init();

    },
    searchCleanElements() {
        var t = this;
        if( t.searchElements ) {
            t.searchElements.forEach( function( single, index ) {
                single.querySelectorAll('marker.active').forEach( function( item,index ) {
                    item.classList.remove('active');
                });
                single.innerHTML = single.innerHTML.replaceAll('<marker class="header__results">','').replaceAll('</marker>','');
            });
        }
    },
    searchControl( foundElements = 0 ) {

        if( document.querySelector('.Header--search .search__control') ) {
            let controls = document.querySelector('.Header--search .search__control');
            controls.setAttribute('data-found', foundElements);
            controls.querySelector('.results__all').textContent = foundElements;
            controls.querySelector('.results__current').textContent = '1';
        }
    },
    searchScroll( element ) {
        // -- set current -- //
            if( document.querySelector('marker.header__results.active') ) {
                document.querySelector('marker.header__results.active').classList.remove('active');
            }
            element.classList.add('active');
        // -- END -- //

        let elementTop = element.getBoundingClientRect().top;

        window.scrollTo({ 
            top: window.scrollY + elementTop - document.querySelector('header').offsetHeight,
            left: 0, 
            behavior: 'smooth'
        });

    }
}