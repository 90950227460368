import FaqItem from '../../Components/FaqItem/FaqItem.js';
import AlgoliaResults from '../../Components/AlgoliaResults/AlgoliaResults';

export default {
	init() {

		FaqItem.init();
		
		var container = document.querySelector('main.FaqArchive');
		if( container ) {

			if( container.querySelector('.FaqArchive__content') ) {

				// -- aside -- //
					container.querySelectorAll('.categories__list .single__child.active').forEach( function( box, index ) {
						box.style.height = box.querySelector('.child__list').offsetHeight + 'px';
						box.classList.remove('active');
					});
					container.querySelectorAll('.categories__list .single__row .row__button').forEach( function(button,index) {
						button.addEventListener('click', function() {
							if( button.classList.contains('active') ) {
								button.classList.remove('active');
								button.closest('.list__single').querySelector('.single__child').style.height = '0px';
							} else {
								button.classList.add('active');
								button.closest('.list__single').querySelector('.single__child').style.height =  button.closest('.list__single').querySelector('.single__child .child__list').offsetHeight + 'px';
							}
						});
					});
				// -- END -- //

				// -- aside mobile -- //
					var filterBtnOpen = container.querySelector('.content__right .right__header .header__button');
					var filterBtnClose = container.querySelector('.content__left .categories__header .header__button');
					var aside = container.querySelector('aside.content__left');
					var asideContent = aside.querySelector('.left__content');
					filterBtnOpen.addEventListener('click', function() {
						aside.classList.add('active');
						document.body.classList.add('disableScroll');
					});
					filterBtnClose.addEventListener('click', function() {
						aside.classList.remove('active');
						document.body.classList.remove('disableScroll');
					});
					aside.addEventListener('click', function() {
						aside.classList.remove('active');
						document.body.classList.remove('disableScroll');
					});
					asideContent.addEventListener('click', function(e) {
						e.stopPropagation();
					});
				// -- END -- //

			}

			if( document.querySelector('.ImageLibraryHeader') && document.querySelector('.ImageLibraryHeader form.content__search') ) {
				var obj = new AlgoliaResults({
					'listener' : document.querySelector('.ImageLibraryHeader input[name="search"]'),
					'elementToAppend' : document.querySelector('.ImageLibraryHeader form.content__search'),
					'indexName' : 'cpt_faq',
					'icon' : document.querySelector('.ImageLibraryHeader .content__title').getAttribute('data-url'),
				});
			}

		}
	},
	finalize() {

	}
}