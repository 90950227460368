import ShareBox from '../ShareBox/ShareBox';
export default {
    init() {
        if( document.querySelector('.EncyclopediaTile') ) {
            ShareBox.init();

            document.querySelectorAll('.EncyclopediaTile .EncyclopediaTile__link').forEach( function( link, index ) {
                link.addEventListener('click', function(e) {
                    var endpointUrl = document.querySelector('Footer').getAttribute('data-endpoint');
                    let request = null;
                    request = new XMLHttpRequest();
                    request.open( "POST", endpointUrl , true );
                    request.onreadystatechange = function() {
                        console.log( request );
                    }
                    request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
                    request.send( 'action=resourcesItemPopularyCounter&resourcesID='+ link.closest('.EncyclopediaTile').getAttribute('data-id') ) ;
                });
            });

        }
    },
    finalize() {

    }
}