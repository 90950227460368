import slick from 'slick-carousel';
import SharingToolkitPopup from '../../Components/SharingToolkitPopup/SharingToolkitPopup';
import $ from 'jquery';
import smoothscroll from 'smoothscroll-polyfill';
export default {
	init(){
		if( !document.querySelector('main.SharingToolkit') ) { return; }
	},
	finalize(){
		var t = this;
		var container = document.querySelector('main.SharingToolkit');

		t.collapsedGroup( container );
		t.toolTipClickDisplay( container );
		t.popupActions();

		SharingToolkitPopup.init();
		smoothscroll.polyfill();

		var settingsParams = container.querySelectorAll('.content__param');
		var settingParamInterval = null;
		settingsParams.forEach( function( row, index ) {

			row.querySelectorAll('input').forEach( function( input,index )  {

				if( (input.getAttribute('type') == 'text' || input.getAttribute('type') == 'number') && input.getAttribute('name') != 'listSearch' ) {
					input.addEventListener('input', function() {
						clearTimeout(settingParamInterval);
						settingParamInterval = setTimeout(function() {
							
							if( row.classList.contains('content__param--color') ) {
								if( input.value ) {
									row.querySelector('input[type="color"]').value = '#'+input.value;
								} else {
									row.querySelector('input[type="color"]').value = '#FFFFFF';
								}
							}
							if( input.getAttribute('type') == 'number' ) {
								if( !input.value ) {
									input.value = input.getAttribute('min');
								}
								if( parseInt( input.value ) < parseInt( input.getAttribute('min') ) ) {
									input.value = input.getAttribute('min');
								} 
								if( parseInt( input.value ) > parseInt( input.getAttribute('max') ) ) {
									input.value = input.getAttribute('max');
								}
							}
							if( input.getAttribute('name') == 'iframe_width' && input.value ) {
								input.closest('.content__row').querySelector('iframe').setAttribute('width', input.value );
								t.recalcIframeParameters();
							}

							t.rebuildIframeSrc( row.closest('section') );
						}, 500);
					});
				} else if( input.getAttribute('type') == 'color' ) {
					input.addEventListener('input', function() {

						clearTimeout(settingParamInterval);
						settingParamInterval = setTimeout(function() {
							
							if( row.classList.contains('content__param--color') ) {
								row.querySelector('input[type="text"]').value = input.value.replace('#','');
							}
							t.rebuildIframeSrc( row.closest('section') );

						}, 1500);
					});
				} else {
					input.addEventListener('click', function(e) {
						e.stopPropagation();
					});
					input.addEventListener('change', function(e) {
						if( row.classList.contains('content__param--select') ) {
							t.selectNewValue(row);
						}
						if( input.getAttribute('name') == 'change_basic_url' && input.value ) {
							input.closest('.content__row').querySelector('iframe').setAttribute('data-basic', input.value);
						}
						t.rebuildIframeSrc( row.closest('section') );
					});
				}

			});
	
		});

		var sections = container.querySelectorAll('.SharingToolkit__customize');
		sections.forEach( function( section, index ) {
			t.rebuildIframeSrc(section);
		});
		var selects = container.querySelectorAll('.content__param--select');
		if( selects.length > 0 ) {
			t.selectActions( selects );
		}

		t.recalcIframeParameters();
		window.addEventListener('resize', function() {
			t.recalcIframeParameters();
		});

		if( container.querySelector('.SharingToolkit__flyers') ) {
			t.flyersCarousel( container.querySelector('.SharingToolkit__flyers') );
		}

	},
	mobileScrollTop( section ) {
		let mobileResolution = 960;
		let elementTopPosition = section.querySelector('.iframe__size').getBoundingClientRect().top;
		let headerHeight = document.querySelector('header').offsetHeight;

		if( window.innerWidth <= mobileResolution ) {
			window.scrollTo({
	            top: ( elementTopPosition + window.scrollY) - (headerHeight + section.querySelector('.iframe__size').offsetTop) ,
	            left: 0,
	            behavior: 'smooth'
	        });
		}
	},
	rebuildIframeSrc( section ) {
		let params = [];

		section.querySelector('.iframe__mask').classList.remove('disable');

		section.querySelectorAll('.content__param').forEach( function( param, index ) {
			let temp = null;

			if( param.classList.contains('content__param--text') ) {
				temp = param.querySelector('input[name="'+param.getAttribute('data-name')+'"]').value.replaceAll(' ','%20').replaceAll('&','%26');
			} else if( param.classList.contains('content__param--radio') ) {
				if( param.querySelector('input[name="'+param.getAttribute('data-name')+'"]:checked') ) {
					temp = param.querySelector('input[name="'+param.getAttribute('data-name')+'"]:checked').value;
				}
			} else if( param.classList.contains('content__param--color') ) {
				if( param.querySelector('input[name="'+param.getAttribute('data-name')+'"]').value ) { 
					temp = param.querySelector('input[name="'+param.getAttribute('data-name')+'"]').value;
				}
			} else if( param.classList.contains('content__param--checkbox') ) {
				if( param.querySelector('input:checked') ) {
					temp = param.querySelector('input:checked').value;
				}
			} else if( param.classList.contains('content__param--select') ) {
				if( param.querySelectorAll('input[name="'+param.getAttribute('data-name')+'"]:checked').length > 0 ) {
					temp = [];
					param.querySelectorAll('input[name="'+param.getAttribute('data-name')+'"]:checked').forEach( function( input,index ) {
						temp.push(input.value);
					});
					temp = temp.join(',');
				}				
			}
			if( param.getAttribute('data-name') == 'change_basic_url' || param.getAttribute('data-name') == 'iframe_width' ) {
				temp = null;
			}
			if( temp || param.classList.contains('content__param--text') ) {
				params.push( param.getAttribute('data-name')+'='+temp );
			}
		});

		if( section.querySelector('iframe').getAttribute('data-static') ) {
			params.push( section.querySelector('iframe').getAttribute('data-static') );
		}
		section.querySelector('iframe').addEventListener('load', function(e) {
			section.querySelector('.iframe__mask').classList.add('disable');
		});

		if( params.length ) {
			section.querySelector('iframe').setAttribute('src', section.querySelector('iframe').getAttribute('data-basic')+'?'+params.join('&') );
		} else {
			section.querySelector('iframe').setAttribute('src', section.querySelector('iframe').getAttribute('data-basic') );
		}
		
	},
	recalcIframeParameters() {
		let iframesBoxs = document.querySelectorAll('main.SharingToolkit .SharingToolkit__customize .iframe__size');
		iframesBoxs.forEach( function( box, index ) {
			let scale = ( (box.offsetWidth*100) / box.querySelector('iframe').offsetWidth) / 100;
			box.querySelector('iframe').style.transform = 'scale(' + scale + ')';
			box.querySelector('iframe').style.height = (box.offsetHeight*100) / (box.offsetHeight * scale) +'%';
		});
	},
	collapsedGroup( container ) {
		var buttons = container.querySelectorAll('button.single__display');
		buttons.forEach( function( button, index ) {

			let content = button.closest('.settings__single').querySelector('.single__content');
			let contentAbsolute = button.closest('.settings__single').querySelector('.single__content--absolute');

			content.style.height = (contentAbsolute.offsetHeight+2) + 'px';
			contentAbsolute.style.position = 'absolute';			

			button.addEventListener('click', function() {

				if( button.closest('.settings__single').classList.contains('active') ) {
					button.closest('.settings__single').classList.remove('active');
					button.closest('.settings__single').querySelector('.single__content').style.overflow = 'hidden';
					button.closest('.settings__single').querySelector('.single__content').style.height = '0px';
				} else {
					button.closest('.settings__single').classList.add('active');
					button.closest('.settings__single').querySelector('.single__content').style.overflow = 'visible';
					button.closest('.settings__single').querySelector('.single__content').style.height = (button.closest('.settings__single').querySelector('.single__content--absolute').offsetHeight+2) + 'px';
				}

			});

		});
		window.addEventListener('resize', function() {
			container.querySelectorAll('.settings__single.active').forEach( function( box, index ) {
				box.querySelector('.single__content').style.height = box.querySelector('.single__content--absolute').offsetHeight + 'px';
			});
		});
	},
	toolTipClickDisplay( container ) {
		var buttons = container.querySelectorAll('.tooltip__button');
		buttons.forEach( function( button, index ) {

			button.closest('.label__tooltip').querySelector('.tooltip__content').style.left = button.offsetLeft + (button.offsetWidth/2)+ 'px';

			button.addEventListener('click', function(e) {
				e.stopPropagation();
				if( button.closest('.label__tooltip').classList.contains('active') ) {
					button.closest('.label__tooltip').classList.remove('active');
				} else {
					button.closest('.label__tooltip').classList.add('active');
				}

			});
		});
		window.addEventListener('resize', function() {
			buttons.forEach( function( button, index ) {
				button.closest('.label__tooltip').querySelector('.tooltip__content').style.left = button.offsetLeft + (button.offsetWidth/2)+ 'px';
			});
		});
		window.addEventListener('click', function() {
			buttons.forEach( function( button,index ) {
				button.closest('.label__tooltip').classList.remove('active');
			});
		});
	},
	flyersCarousel( section ) {
		var carousel = section.querySelector('.flyers__carousel');
		$(carousel).slick({
			slidesToShow: 4,
			slidestoScroll: 1,
			prevArrow: '<button class="carousel__control carousel__control--prev"><img src="'+window.location.origin+'/wp-content/themes/open-critical-care/assets/svg/arrow-left-carousel.svg"></button>',
			nextArrow: '<button class="carousel__control carousel__control--next"><img src="'+window.location.origin+'/wp-content/themes/open-critical-care/assets/svg/arrow-left-carousel.svg"></button>',
			rows: false,
			responsive: [
				{
					breakpoint: 1181,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 769,
					settings: {
						slidesToShow: 3,
						arrows: false,
						dots: true,
					}
				},
				{
					breakpoint: 661,
					settings: {
						slidesToShow: 2,
						arrows: false,
						dots: true,
					}	
				}
			]
		});
		
		carousel.querySelectorAll('.carousel__control').forEach( function( button, index ) {
			button.querySelector('img').style.top = carousel.querySelector('.slick-current .image-wrapper').offsetHeight/2 +'px';
		});
		window.addEventListener('resize', function() {
			carousel.querySelectorAll('.carousel__control').forEach( function( button, index ) {
				button.querySelector('img').style.top = carousel.querySelector('.slick-current .image-wrapper').offsetHeight/2 +'px';
			});
		});
	},
	selectActions( selects ) {
		var authorsSearchTimeout = null;
		selects.forEach( function( select, index ) {
			select.addEventListener('click', function(e){
				e.stopPropagation();
			});
			select.querySelector('.select__open').addEventListener('click', function(e) {
				e.stopPropagation();
				if( select.querySelector('.select__open').classList.contains('active') ) {
					select.querySelector('.select__open').classList.remove('active'); 
					select.closest('.single__content').style.height = (select.closest('.single__content--absolute').offsetHeight+2) + 'px';
				} else {
					select.querySelector('.select__open').classList.add('active');
					select.closest('.single__content').classList.add('disableTransition');
					select.closest('.single__content').style.height = (select.closest('.single__content--absolute').offsetHeight+2) + 'px';

					if( window.innerWidth > 960 ) {
						// -- get top position -- //
							let tempHeight = 0;
							let tempGroup = null
							select.closest('.content__size').querySelectorAll('.settings__single').forEach( function( group, index2 ) {
								if( !group.querySelector('.content__param--select[data-name="'+select.getAttribute('data-name')+'"]') ) {
									tempHeight += group.offsetHeight
								} else if( tempGroup === null ) {
									tempGroup = group;
								}
							});
							tempGroup.querySelectorAll('content__param').forEach( function( row, inde2) {
								if( !row.querySelector('.content__param--select[data-name="'+select.getAttribute('data-name')+'"]') ) {
									tempHeight += row.offsetHeight;
								}
							});
							tempHeight += parseFloat( getComputedStyle(select.closest('.single__content--absolute'), null).getPropertyValue('padding-top').replace('px','') );
							tempHeight -= 3;
						// -- end -- //
						select.closest('.content__size').scrollTop = tempHeight;
					}
					select.closest('.single__content').classList.remove('disableTransition');
				}
			});
			select.querySelector('input[name="listSearch"]').addEventListener('input', function() {
				if( select.querySelector('input[name="listSearch"]').value ) {

					clearTimeout( authorsSearchTimeout );
					authorsSearchTimeout = setTimeout(function() {

						select.querySelectorAll('.list__single input').forEach( function(input,index) {
							
							if( input.getAttribute('data-name').toLowerCase().indexOf( select.querySelector('input[name="listSearch"]').value.toLowerCase() ) >= 0 ) {
								input.closest('.list__single').classList.remove('deactive');
							} else {
								input.closest('.list__single').classList.add('deactive');
							}

						});	
						select.closest('.single__content').style.height = select.closest('.single__content--absolute').offsetHeight + 'px';

					}, 200);

				} else {

					select.querySelectorAll('.list__single input').forEach( function( input, index ){
						input.closest('.list__single').classList.remove('deactive');
					});
					select.closest('.single__content').style.height = select.closest('.single__content--absolute').offsetHeight + 'px';

				}

			});
		});
		window.addEventListener('click', function() {
			selects.forEach( function( select, index ) {
				if( select.closest('.settings__single.active') ) {
					select.querySelector('.select__open').classList.remove('active'); 
					select.closest('.single__content').style.height = (select.closest('.single__content--absolute').offsetHeight+2) + 'px';
				}
			});
		});
	},
	selectNewValue( select ) {
		let temp = [];
		if( select.querySelectorAll('.list__single input:checked').length > 0 ) {
			select.querySelectorAll('.list__single input:checked').forEach( function( input,index ) {
				temp.push( input.getAttribute('data-name') );
			});
			select.querySelector('.select__open span').textContent = temp.join(',');
		} else {
			select.querySelector('.select__open span').textContent = '';
		}
	},
	popupActions() {
		var popup = document.querySelector('.SharingToolkitPopup');
		if( popup ) {

			document.querySelectorAll('.generate__iframe').forEach( function( button, index ) {
				button.addEventListener('click', function() {

					let iframe = button.closest('section').querySelector('iframe');
					let iframeID = 'iframe-'+Date.now();
					
					let newContent = '<iframe id="'+iframeID+'" src="'+iframe.getAttribute('src')+'" title="'+iframe.getAttribute('data-title')+'" style="width: 100%;"></iframe>';
					if( button.closest('section').querySelector('input[name="iframe_width"]') ) {
						newContent = '<iframe id="'+iframeID+'" src="'+iframe.getAttribute('src')+'" title="'+iframe.getAttribute('data-title')+'" style="max-width: '+iframe.getAttribute('width')+'px; width: 100%;"></iframe>';
					}
					if( button.closest('.customize__content').querySelector('input[type=checkbox][name=auto-height]') && button.closest('.customize__content').querySelector('input[type=checkbox][name=auto-height]').checked ) {
						newContent += "<script type='text/javascript'> window.addEventListener( 'message', function(event) { if( event.origin == '"+window.location.origin+"' ) { document.querySelector('#"+iframeID+"').style.height = event.data + 'px'; } } ); </script>";
					}

					// -- copy -- //
						let element = document.createElement('input');
						element.setAttribute('readonly','');
						element.style.position = 'absolute';
						element.style.left = '-99999px';
						element.value = newContent;

						document.body.appendChild( element );
						element.select();
						document.execCommand('copy');
						document.body.removeChild(element);
					// -- END -- //

					popup.querySelector('textarea').value = newContent;
					popup.classList.add('active');
				});
			});

		}
	}
}