export default {
    init() {
        var t = this;
        document.addEventListener('click', function(e) {
            
            if( e.target.classList.contains('ImageLibraryItem__layout') ) {
                t.closeItemMask();
            }

            if( e.target.classList.contains('ImageLibraryItem__close') ) {
                t.closeItemMask();
            }

            document.addEventListener('keydown', function(e) {
                if(e.keyCode == 27){
                    t.closeItemMask();
                }
            });

        });
    },
    closeItemMask() {
        document.body.classList.remove('disableScroll');
        document.querySelectorAll('.ImageLibraryItem--mask').forEach( function( element, index ) {
            element.classList.remove('active');
            setTimeout(() => {
                element.remove();
            }, 200);
        });
    },
    finalize() {}
}