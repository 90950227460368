import ResourcesItem from '../../Components/ResourcesItem/ResourcesItem.js';

export default { 

	init() {

    	ResourcesItem.init();

	},
	finalize() {

	}

}