import { google, outlook, ics } from "calendar-link";

export default {

	init(){

		var container = document.querySelector('main.TrainingsSingle');

		if( container ) {
			
			let event = {
				title : container.getAttribute('data-title'),
				start : container.getAttribute('data-start'),
				end : container.getAttribute('data-end'),
				duration : [ container.getAttribute('data-duration'),'hours' ]
			}

			container.querySelector('.outlook_calendar').setAttribute( 'href', outlook( event ) );
			container.querySelector('.google_calendar').setAttribute( 'href', google( event ) );
			container.querySelector('.i_calendar').setAttribute('href', ics( event ) );
					
			
					
			container.querySelector('.heading__back').addEventListener('click', function(e) {

				if( history.length > 1 ) {
					e.preventDefault();
					history.back();
				}

			});


		}

	},
	finalize() {

	}

}