import smoothscroll from 'smoothscroll-polyfill';
export default {
    init() {
        var containers = document.querySelectorAll('.FileCarousel');
        smoothscroll.polyfill();
        containers.forEach( function( container, index ) {
            // -- scroll to settings -- //
                var button = container.querySelector('.header__scroll');
                if( button && button.hasAttribute('data-section-id') && button.getAttribute('data-section-id') && document.getElementById( button.getAttribute('data-section-id') ) ) {
                    
                    button.addEventListener('click', function() {
                        window.scroll({
                            top: document.getElementById( button.getAttribute('data-section-id') ).offsetTop - document.querySelector('header').offsetHeight ,
                            behavior: 'smooth'
                        });
                    });
                }
            // -- END -- //
        });
    },
    finalize() {}
}