export default {
    init() {
        var t = this;
        t.node = document.querySelector('.ImageLibraryHeader');
        if( t.node ) {
            // -- close info bar -- //
                t.blueBarClose();
            // -- END -- //
        }
    },
    blueBarClose() {
		var t = this;
		var bar = t.node.querySelector('.ImageLibraryHeader__bar');
		if( bar ) {
			bar.querySelector('.bar__close').addEventListener('click', function() {
				bar.remove();
			});
		}
	},
}