import smoothscroll from 'smoothscroll-polyfill';
export default {
    init() {
        var t = this;
        smoothscroll.polyfill();
        
        document.addEventListener('click', function(e) {
            
            if( e.target.classList.contains('row__button') && e.target.closest('.LevelsAccordion') ) {

                if( e.target.classList.contains('active') ) {
                    e.target.classList.remove('active');
                    e.target.closest('.list__item').querySelector('.item__content').style.height = '0px';
                    t.changeLevelHeight( e.target, parseInt( e.target.closest('.list__item').getAttribute('data-level')-1 ) );
                } else {
                    e.target.classList.add('active');
                    e.target.closest('.list__item').querySelector('.item__content').style.height = ( e.target.closest('.list__item').querySelector('.item__content--absolute').offsetHeight + 2) + 'px';
                    t.changeLevelHeight( e.target, parseInt( e.target.closest('.list__item').getAttribute('data-level') ) );
                }

            }

            if( e.target.closest('.LevelsAccordion') && e.target.tagName.toLowerCase() == 'a' ) {
                
                if( e.target.getAttribute('href').split('#')[1] ) {
                    if( document.querySelector('#'+e.target.getAttribute('href').split('#')[1]) ) {

                        e.preventDefault();
                        t.scrollToElement( e.target.getAttribute('href').split('#')[1] );
        
                    } else {
                        t.searchChild( e.target, e );
                    }
                }
            }

        });

        window.addEventListener('resize', function() {

            let maxLevel = -1;
            document.querySelectorAll('.LevelsAccordion .row__button.active').forEach( function( button, index ) {
                if( parseInt( button.closest('.list__item').getAttribute('data-level') ) > maxLevel ) {
                    maxLevel = parseInt( button.closest('.list__item').getAttribute('data-level') );
                }
            });
            while( maxLevel >= 0 ) {
                document.querySelectorAll('.LevelsAccordion .row__button.active').forEach( function( button, index ) {
                    if( button.closest('.list__item[data-level="'+maxLevel+'"]') ) {
                        button.closest('.list__item[data-level="'+maxLevel+'"]').closest('.list__item').querySelector('.item__content').style.height = ( button.closest('.list__item[data-level="'+maxLevel+'"]').closest('.list__item').querySelector('.item__content--absolute').offsetHeight + 1) + 'px';
                    }
                });   
                maxLevel = maxLevel -1;

            }

        });
        setTimeout(
            function() {
                t.removeOpen();
            },
            200
        );
        if( window.location.hash ) {
            let link = document.querySelector('a[href="'+window.location.hash+'"]');
            if( link && link.closest('.LevelsAccordion') && !document.querySelector(window.location.hash) ) {
                setTimeout(
                    function() {
                        t.searchChild( link );
                    },  
                    500 
                );
                
            }
        }

    },
    changeLevelHeight( element = null, level = 0 ) {
        var t = this;
        if( element != null && level >= 0 ) {

            setTimeout(() => {
                element.closest('.list__item--'+ level ).querySelector('.item__content').style.height = ( element.closest('.list__item--'+ level ).querySelector('.item__content--absolute').offsetHeight + 2 )+ 'px'; 
                t.changeLevelHeight( element ,level - 1 );
            }, 200);
        }

    },
    removeOpen() {
        document.querySelectorAll('.LevelsAccordion').forEach( function( box,index ) {
            if( box.classList.contains('LevelsAccordion--open') ) {
                let maxLevel = -1;
                box.querySelectorAll('.row__button.active').forEach( function( button, index ) {
                    if( parseInt( button.closest('.list__item').getAttribute('data-level') ) > maxLevel ) {
                        maxLevel = parseInt( button.closest('.list__item').getAttribute('data-level') );
                    }
                });
                while( maxLevel >= 0 ) {
                    box.querySelectorAll('.row__button.active').forEach( function( button, index ) {
                        if( button.closest('.list__item[data-level="'+maxLevel+'"]') ) {
                            button.closest('.list__item[data-level="'+maxLevel+'"]').closest('.list__item').querySelector('.item__content').style.height = ( button.closest('.list__item[data-level="'+maxLevel+'"]').closest('.list__item').querySelector('.item__content--absolute').offsetHeight + 2) + 'px';
                        }
                    });   
                    maxLevel = maxLevel -1;
    
                }
                box.classList.remove('LevelsAccordion--open');

            }
        });
    },
    searchChild( linkEl, event = null ) {
        var t = this;
        let tempParent = linkEl.closest('.list__item');
        let tempElement = null;
        if( tempParent.querySelector('.item__content') ) {

            tempElement = tempParent.querySelector('.item__content .item__content--absolute .list__item');
            tempElement = tempElement.querySelector('.item__row a');
            if( tempElement.getAttribute('href').split('#')[1] && document.querySelector('#'+tempElement.getAttribute('href').split('#')[1]) ) {
                if( event ) {
                    event.preventDefault();
                }
                t.scrollToElement( tempElement.getAttribute('href').split('#')[1] );
            } else {
                t.searchChild( tempElement, event );
            }
        }
    },
    scrollToElement( hash = null ) {
        if( hash ) {
            let scrollPosition = document.querySelector('#'+hash).getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ 
                top: scrollPosition - document.querySelector('header').offsetHeight,
                left: 0, 
                behavior: 'smooth'
            });
        }
    },
    finalize() {}
}