export default {
    init() {
        document.querySelectorAll('.SharingToolkitPopup').forEach( function( popup, index ) {

            popup.querySelectorAll('.popup__close').forEach( function( button, index ) {
                button.addEventListener('click', function() {
                    popup.querySelector('textarea').value = '';
                    popup.classList.remove('active');
                });
            });
            
        });
    },
    finalize() {}
}