export default {

	init() {

		let url = new URL( window.location.href );
		// don't show cookie if page load by iframe
		if( url.searchParams.get("iframe") === null ) {

			var t = this;
			var req = new XMLHttpRequest();
			req.onreadystatechange = function() {
				
				if( req.readyState == 4 && req.status == 200 && req.responseText  ) {
					
					document.querySelector('body').insertAdjacentHTML('beforeend', req.responseText);

					if( document.querySelector('.Cookie') ) {	
						t.finalize( document.querySelector('.Cookie') );
					}

				}

			}

			req.open( 'POST', window.location.origin + '/wp-admin/admin-ajax.php' , true );
			req.setRequestHeader( 'Content-type', 'application/x-www-form-urlencoded' );
			req.send( 'action=cookieInfo' );

		}

	},
	finalize( container ) { 
		var t = this;
		var cookieBasic = container.querySelector('.Cookie__basic');
		var cookieExtended = container.querySelector('.Cookie__extended');
		var cookieExtendedDetailsButtons = cookieExtended.querySelectorAll('.single__button');
		var cookieExtendedDetails = cookieExtended.querySelectorAll('.single__details');

		var cookieInput = cookieExtended.querySelectorAll('.single__cookie');

		function clearCookies() {

			if( t.checkCookie('targeting_cookies') === '0' || t.checkCookie('performance_cookies') === '0' ) {
				t.setCookie('_gat_gtag_UA_174152989_1', 0, 0);
			}
			if( t.checkCookie('performance_cookies') === '0' ) {
				t.setCookie('_ga', 0, 0);
				t.setCookie('_gid', 0, 0);
			}

		}

		// -- checkCookie -- //

			if( container.hasAttribute('data-display') && container.getAttribute('data-display') === 'false' ) {
				cookieInput.forEach( function( input,index ) {
					t.setCookie( input.getAttribute('name') );
				});
				t.removeCookieContainer();
			}

			if( t.checkCookie('show_cookie') == '1' ) {
				t.removeCookieContainer();
			} else {
				setTimeout(function() {
					cookieBasic.classList.add('active');
				}, 500);
			}
			clearCookies();
		// -- END -- //


		// -- close cookie -- //
			container.querySelectorAll('.Cookie__close').forEach( function( button,index ) {
				button.addEventListener('click', t.removeCookieContainer );
			});
		// -- END -- //


		// -- accept all cookie -- //
			container.querySelector('.Cookie__accept__all').addEventListener('click', function() {

				cookieInput.forEach( function( input,index ) {

					t.setCookie( input.getAttribute('name') );

				});
				t.setCookie( 'show_cookie' );
				t.removeCookieContainer();
				clearCookies();

			});	
		// -- END -- //


		// -- accept selected cookie -- //
			container.querySelectorAll('.Cookie__accept').forEach( function( button,index ) {

				button.addEventListener('click', function() {

					cookieInput.forEach( function( input, index ) {

						if( input.checked ) {
							t.setCookie( input.getAttribute('name') );
						} else {
							t.setCookie( input.getAttribute('name'), '0' );
						}
						
					});
					t.setCookie( 'show_cookie' );
					t.removeCookieContainer();
					clearCookies();

				});

			});
		// -- END -- //


		// -- change layout to basic -> extended -- //
			cookieBasic.querySelector('.basic__extended').addEventListener('click', function() {
				cookieBasic.classList.remove('active');
				cookieExtended.classList.add('active');
			});
		// -- END -- //


		// -- change layout to extended -> basic -- //
			cookieExtended.querySelector('.Cookie__back').addEventListener('click', function() {
				cookieBasic.querySelector('.basic__row .Cookie__accept').classList.remove('hide');
				cookieBasic.querySelector('.basic__row .Cookie__accept__all').classList.add('hide');
				cookieBasic.classList.add('active');
				cookieExtended.classList.remove('active');
			});
		// -- END -- //


		// -- extended show details && resize -- //
			if( cookieExtendedDetailsButtons.length > 0 ) {

				cookieExtendedDetailsButtons.forEach( function( button,index ) {

					button.addEventListener('click', function() {
						if( button.classList.contains('active') ) {
							button.classList.remove('active');
							cookieExtendedDetails[index].style.height = '0px';
						} else {
							button.classList.add('active');
							cookieExtendedDetails[index].style.height = cookieExtendedDetails[index].querySelector('.single__details--absolute').offsetHeight + 'px';
						}
					});
												
				});

				window.addEventListener('resize', function() {

					cookieExtendedDetailsButtons.forEach( function( button,index ) {

						if( button.classList.contains('active') ) {
							cookieExtendedDetails[index].style.height = cookieExtendedDetails[index].querySelector('.single__details--absolute').offsetHeight + 'px';
						}

					});

				});

			}
		// -- END -- //


		// -- security for always selected -- // 

			cookieExtended.querySelectorAll('.single__cookie:disabled').forEach( function( input, index ) {

				input.addEventListener('change', function() {
					input.checked = true;
				});

			});

		// -- END -- //

	},
	removeCookieContainer() {

		if( document.querySelector('.Cookie') ) {

			document.querySelector('.Cookie__basic').classList.remove('active');
			document.querySelector('.Cookie__extended').classList.remove('active');
			setTimeout(function() {
				if( document.querySelector('.Cookie') ) {
					document.querySelector('.Cookie').remove();
				}
			}, 200);

		}

	},
	setCookie(cname, cvalue = '1', exdays = 7 ) {
	    var d = new Date();
	    d.setTime(d.getTime() + (exdays*24*60*60*1000));
	    var expires = "expires="+ d.toUTCString();
	    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	},
	checkCookie(cname) {

	    var name = cname + "=";
	    var decodedCookie = decodeURIComponent(document.cookie);
	    var ca = decodedCookie.split(';');
	    for(var i = 0; i <ca.length; i++) {
	        var c = ca[i];
	        while (c.charAt(0) == ' ') {
	            c = c.substring(1);
	        }
	        if (c.indexOf(name) == 0) {
	            return c.substring(name.length, c.length);
	        }
	    }
	    return "";

	}


}