export default {

	init() {

		var container = document.querySelectorAll('.FilterTip');
		
		if( container.length > 0 ) {

			container.forEach( function( box,index ) {

				if( box.querySelector('button') ) {

					box.querySelector('button').addEventListener('click', function() {

						box.remove();

					});

				}

			});

		}

	},
	finalize() {

	}

}