import LazyLoad from '../../../common/scripts/lazyLoad';
import $ from 'jquery';
import ShareButton from '../../Components/ShareButton/ShareButton';
import ResourcesItem from '../../Components/ResourcesItem/ResourcesItem.js';
import Subpage from '../../Layout/Subpage';
import FaqItem from '../../Components/FaqItem/FaqItem.js';

import AlgoliaResults from '../../Components/AlgoliaResults/AlgoliaResults';

export default { 

	init() {

		let controller = new AbortController();
    	let signal = controller.signal;

    	let controllerForNumbers = new AbortController();
    	let signalForNumbers = controllerForNumbers.signal;

    	ShareButton.init();
    	ResourcesItem.init();
    	FaqItem.init();

		$("#openFilters").click(function() {
			$(this).toggleClass('close');
			$('.Resources__filtersBox').toggleClass('close');
		});

		$(".Resources__filtersBox .Resources__filtersGroup--topics .ResourceButton").click(function() {

			if($(this).closest('.Resources__filtersGroup').hasClass('allActive')){
				$(".Resources__filtersBox .Resources__filtersGroup--subtopics").removeClass('active');
			}
			$(".Resources__filtersBox .Resources__filtersGroup--subtopics[data-subtopic='" + $(this).data("slug") +"']").toggleClass('active');
		});

		$(".Resources__filtersBox .ResourceButton").click(function() {

			if($(this).closest('.Resources__filtersGroup').hasClass('allActive')){
				$(this).closest('.Resources__filtersGroup').find('.ResourceButton').removeClass('active');
				$(this).closest('.Resources__filtersGroup').removeClass('allActive');
			}
			$(this).toggleClass('active');

			if($(this).closest('.Resources__filtersGroup').find('.ResourceButton').length == $(this).closest('.Resources__filtersGroup').find('.ResourceButton.active').length) {
				$(this).closest('.Resources__filtersGroup').addClass('allActive');
			}

			refreshContent();
			updateNumbers();
		});

		$(".Resources__filters .resetFilters").click(function() {
			$(".Resources__deselectAll").trigger("click");
			$(".Resources__filtersGroup-filters .ResourceButton").removeClass('active');
		});

		$(".Resources__heading-info").click(function() {

			if( Subpage.isTouchDevice() ) {

				$(".TooltipMobile .row").removeClass('active');
				$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"']").addClass('active');
				$('.TooltipMobile').addClass('active');
				$('body').addClass('disableScroll');

			}

		});

		$(".TooltipMobile__close").click(function() {
			$('.TooltipMobile').removeClass('active');
			$('body').removeClass('disableScroll');
		});

		$(".Resources__heading-filter button:not(.select__open)").click(function() {
			$(this).closest('.Resources__heading-filter').removeClass('allActive');
			$(this).toggleClass('active');
			refreshContent();
			updateNumbers();
		});

		$(".Resources__selectAll").click(function() {

			$(this).closest('.Resources__filtersGroup').addClass('allActive');

			$(this).closest('.Resources__filtersGroup').find('.ResourceButton').addClass('active');

			if($(this).hasClass('Resources__selectAll--contentTopics')) {
				$(".Resources__filtersBox .Resources__filtersGroup--subtopics").addClass('active');
			}

			refreshContent();
			updateNumbers();
			
		});


		$(".Resources__deselectAll").click(function() {

			$(this).closest('.Resources__filtersGroup').removeClass('allActive');
		
			$(this).closest('.Resources__filtersGroup').find('.ResourceButton').removeClass('active');

			if($(this).hasClass('Resources__deselectAll--contentTopics')) {
				$(".Resources__filtersBox .Resources__filtersGroup--subtopics").removeClass('active');
			}
			
			refreshContent();
			updateNumbers();
			
		});


		if( $(".Resources__heading input[name='search']").val() ) {
			updateNumbers();
		}

		// -- Select order -- //

			$(document).on('click', '.order__select', function(e) {

				e.stopPropagation();

				if( $(this).hasClass('active') ) {

					$(this).removeClass('active');

				} else {

					$(this).addClass('active');

				}

			});

			$(document).on('click', '.select__button', function(e) {

				$('.select__value span').text( $(this).text() );

				$('.select__button').removeClass('clicked');
				$(this).addClass('clicked');

				refreshContent();

			});

			$(document).on('click', function() {

				if( $('.order__select').hasClass('active') ) {

					$('.order__select').removeClass('active');

				}

			});

		// -- END -- //


		// -- Banner -- //

			var banner = document.querySelector('.heading__banner');
			if( banner ) {

				banner.querySelector('.banner__close').addEventListener('click', function() {
					banner.remove();
				});

			}

		// -- END -- //


		// -- authors -- //

			var authorSelect = document.querySelector('.Resources__heading-filter--author');
			if( authorSelect ) {

				var authorsList = authorSelect.querySelectorAll('.list__single input');
				var authorsSearch = authorSelect.querySelector('input[name="authorName"]');
				var authorsSearchTimeout = null;

				var buttonOpen = authorSelect.querySelector('.select__open');
				var buttonOpenSpan = buttonOpen.querySelector('span');

				authorSelect.querySelector('.row__button--apply').addEventListener('click', function() {
					buttonOpen.classList.remove('active');
				});

				buttonOpen.addEventListener('click', function() {

					if( buttonOpen.classList.contains('active') ) {
						buttonOpen.classList.remove('active'); 
					} else {
						buttonOpen.classList.add('active');
					}

				});

				// -- search -- //

					authorsSearch.addEventListener('input', function() {

						if( authorsSearch.value ) {

							clearTimeout( authorsSearchTimeout );
							authorsSearchTimeout = null
							authorsSearchTimeout = setTimeout(function() {

								authorsList.forEach( function(input,index) {
									
									if( input.getAttribute('data-name').toLowerCase().indexOf( authorsSearch.value.toLowerCase() ) >= 0 )  {
										input.closest('.list__single').classList.remove('deactive');
									} else {
										input.closest('.list__single').classList.add('deactive');
									}

								});	

							}, 200);

						} else {

							authorsList.forEach( function( input, index ){
								input.closest('.list__single').classList.remove('deactive');
							});

						}

					});

				// -- END -- //

				// -- select/deselect all -- //

					var selectAll = authorSelect.querySelector('.Resources__selectAll');
					var deselectAll = authorSelect.querySelector('.Resources__deselectAll');
					var clearAll = authorSelect.querySelector('.row__button--clear');

					selectAll.addEventListener('click', function() {

						selectAll.classList.remove('active');
						deselectAll.classList.add('active');	

						authorsList.forEach( function( input,index ) {
							input.checked = true;
							input.closest('.list__single').classList.add('active');
						});

						authorNewButtonSpanValue();

					});

					clearAll.addEventListener('click', function() {

						selectAll.classList.add('active');
						deselectAll.classList.remove('active');	

						authorsList.forEach( function( input,index ) {
							input.checked = false;
							input.closest('.list__single').classList.remove('active');
						});

						authorNewButtonSpanValue();
						refreshContent();
						updateNumbers();

					});

					deselectAll.addEventListener('click', function() {

						selectAll.classList.add('active');
						deselectAll.classList.remove('active');	

						authorsList.forEach( function( input,index ) {
							input.checked = false;
							input.closest('.list__single').classList.remove('active');
						});

						authorNewButtonSpanValue();


					});

				// -- END -- //

				authorsList.forEach( function( input,index ) {

					input.addEventListener('change', function() {

						if( input.checked ) {
							input.closest('.list__single').classList.add('active');
						} else {
							input.closest('.list__single').classList.remove('active');
						}

						if( authorSelect.querySelectorAll('.list__single input:checked').length == authorsList.length ) {
							selectAll.classList.remove('active');
							deselectAll.classList.add('active');
						} else {
							selectAll.classList.add('active');
							deselectAll.classList.remove('active');
						}

						authorNewButtonSpanValue();
						refreshContent();
						updateNumbers();

					});

				});

				function authorNewButtonSpanValue() {

					let newValue = [];
					authorsList.forEach( function( input, index ) {
						if( input.checked ) {
							newValue.push( input.closest('.list__single').querySelector('p').textContent );
						}
					});

					newValue = newValue.join(', ');

					if( newValue ) {
						buttonOpenSpan.textContent = newValue;
					} else {
						buttonOpenSpan.textContent = buttonOpenSpan.getAttribute('data-default');
					}

				}

			}

		// -- END -- //


		$(document).on('click', '.Resources__item--more button', function(e){

			let showMoreButton = $(this).parent();
			showMoreButton.remove();

            fetch(generateAjaxURL(showMoreButton.data("paged")), {
                method: 'get',
            }).then(res => res.text()).then(response => {
			    $('.Resources__items-list').append(response);
			    LazyLoad.init();
			}).catch(function(err) {
                //console.error(` Err: ${err}`);
            });
		});

		function updateNumbers() {

			let counters = {};

			$('.Resources__filters .Resources__filtersBox .Resources__filtersGroup.active .ResourceButton').each(function() {

				let excludeFilter = $(this).data("filter");
				let excludeFilterValue = $(this).data("slug");

				let params = {};
				params[excludeFilter] = excludeFilterValue;
				
				$(".Resources__filters .Resources__filtersBox .Resources__filtersGroup.active .ResourceButton[data-filter!='" + excludeFilter +"'].active").each(function( index ) {
					
					if(params[$(this).data("filter")]) {
						params[$(this).data("filter")] += ',';
						params[$(this).data("filter")] += $(this).data("slug");
					}
					else {
						params[$(this).data("filter")] = $(this).data("slug");
					}
				});

				$('.Resources__heading-filter button.active').each(function() {
					if(params[$(this).data("filter")]) {
						params[$(this).data("filter")] += ',';
						params[$(this).data("filter")] += $(this).data("slug");
					}
					else {
						params[$(this).data("filter")] = $(this).data("slug");
					}			
				});

				if( $(".Resources__heading input[name='search']").val() ) {
					params['search'] = $(".Resources__heading input[name='search']").val();
				}


				if( document.querySelectorAll('input[name="author"]:checked').length > 0 ) {
					let tempArr = [];
					document.querySelectorAll('input[name="author"]:checked').forEach( function( input,index ) {

						tempArr.push(input.value);

					});
					if( tempArr ) {
						params['authors'] = tempArr.join(',');
					}
				}

				counters[excludeFilterValue] = params;
				
			});

			controllerForNumbers.abort();

			controllerForNumbers = new AbortController();
			signalForNumbers = controllerForNumbers.signal;


			fetch(document.querySelector('footer').getAttribute('data-endpoint') + '?action=countPosts', {
		        method: "post",
		        signal: signalForNumbers,
		        headers: {
		        	'Content-Type': 'application/json'
			    },
		        body: JSON.stringify(counters)
		    })
		    .then(res => res.text()).then(response => {

		    	var obj = JSON.parse(response);

			    $('.Resources__filters .Resources__filtersBox .Resources__filtersGroup.active .ResourceButton').each(function() {

			    	$(this).find('.ResourceButton-number').text( obj[$(this).data("slug")] );

			    });

			}).catch(function(err) {
                //console.error(` Err: ${err}`);
            });

		}
		


		function generateAjaxURL(paged = 1) {

			var params = [];
			var contentTypes = [];
			var contentTopics = [];
			var contentSubTopics = [];
			var contentLevels = [];
			var verifiedResource = [];
			var contentLanguages = [];
			var duration = [];
			var authors = [];

			var ajaxURL = window.location.origin + window.location.pathname;

			$('.Resources__filters .Resources__filtersBox .Resources__filtersGroup.active .ResourceButton.active').each(function( index ) {
			  	
			  	let filterType = $(this).data("filter");

				if(filterType == 'content-type') {
					contentTypes.push( $(this).data("slug") );
				}
				else if(filterType == 'content-topic') {
					contentTopics.push( $(this).data("slug") );
				}
				else if(filterType == 'content-subtopic') {
					contentSubTopics.push( $(this).data("slug") );
					
					var index = contentTopics.indexOf($(this).data("topic-slug"));
					if (index > -1) {
					  contentTopics.splice(index, 1);
					}
				}
				else if( filterType == 'duration' ) {
					duration.push( $(this).data('slug') );
				}
			});

			$('.Resources__heading-filter button.active').each(function( index ) {
			  	
			  	let filterType = $(this).data("filter");
			  	
				if(filterType == 'content-level') {
					contentLevels.push( $(this).data("slug") );
				}
				else if(filterType == 'content-lang') {
					contentLanguages.push( $(this).data("slug") );
				}
				else if(filterType == 'verified_resource') {
					verifiedResource.push( $(this).data("slug") );
				}
				else if( filterType == 'duration' ) {
					duration.push( $(this).data('slug') );
				}
			});

			$('.Resources__heading-filter--author .content__list .list__single input').each( function(index, input) {

				if( input.checked ) {
					authors.push( input.value );
				}

			});


			if( contentTypes.length > 0 ) {

				params.push('content-type='+ contentTypes.join(',') );

			}

			if( contentSubTopics.length > 0 ) {

				params.push('content-subtopic='+ contentSubTopics.join(',') );

			}

			if( contentTopics.length > 0) {

				params.push('content-topic='+ contentTopics.join(',') );

			}

			if( contentLanguages.length > 0) {

				params.push('content-lang='+ contentLanguages.join(',') );

			}

			if( contentLevels.length > 0) {

				params.push('content-level='+ contentLevels.join(',') );

			}
			if( duration.length > 0 ) {
				params.push('duration='+duration.join(',') );
			}
			if( authors.length > 0 ) {
				params.push('authors='+authors.join(',') );	
			}

			if( verifiedResource.length > 0) {

				params.push('verified_resource='+ verifiedResource.join(',') );

			}

			if( $(".Resources__heading input[name='search']").val() ) {

				params.push('search='+ $(".Resources__heading input[name='search']").val() );

			}

			if( $('.select__button.clicked') ) {

				params.push('orderby=' + $('.select__button.clicked').attr('data-value') );

			}

			if( paged > 1) {

				params.push('pagenumber='+ paged );

			}

			params.push('ajax=true' );

			if( params.length > 0 ) {

				ajaxURL = ajaxURL+'?'+params.join('&');

			}

			return ajaxURL;
		}

		function refreshContent() {
			removeFaqItemsOnRefreshContent();

			controller.abort();

			controller = new AbortController();
			signal = controller.signal;

            fetch(generateAjaxURL(), {
                method: 'get',
                signal: signal,
            }).then(res => res.text()).then(response => {

			    $('.Resources__items-list').empty().append(response);
			    LazyLoad.init();

			}).catch(function(err) {
                //console.error(` Err: ${err}`);
            });

		}	

		// -- faq items hide (if exists) -- //
			function removeFaqItemsOnRefreshContent() {

				if( document.querySelectorAll('.items__typeName').length > 0 ) {
					document.querySelectorAll('.items__typeName').forEach( function( heading, index ) {
						heading.remove();
					});
				}
				if( document.querySelector('.items__faq--items') ) {
					document.querySelector('.items__faq--items').remove();
				}

			}
		// -- END -- //

		// -- algolia -- //
			var obj = new AlgoliaResults({
				'listener' : document.querySelector('input[name="search"]'),
				'elementToAppend' : document.querySelector('form.Resources__heading-search'),
				'indexName' : 'cpt_resources',
				'target': '_blank'
			});
		// -- END -- //

	},
	finalize() {

	}

}