export default {
    init() {
        var t = this;
        document.querySelectorAll('.FilterNameCheckbox').forEach( function( container, index ) {
            t.finalize(container, index);
        });
    },
    finalize( container, index ) {
        var button = container.querySelector('.row__button');
        var content = container.querySelector('.FilterNameCheckbox__content');
        if( content ) {
            button.addEventListener('click', function() {
                document.querySelectorAll('.FilterNameCheckbox').forEach( function( box2, index2 ) {
                    if( index == index2 ) {
                        container.classList.toggle('active');
                    } else {
                        box2.classList.remove('active');
                    }
                });
            });
            content.querySelectorAll('input').forEach( function( input, index ) {
                input.addEventListener('change', function() {
                    button.setAttribute( 'data-number', content.querySelectorAll('input:checked').length );
                });
            });
            container.addEventListener('click', function(e){
                e.stopPropagation();
            });
            document.addEventListener('click', function() {
                document.querySelectorAll('.FilterNameCheckbox').forEach( function( box2, index2 ) {
                    box2.classList.remove('active');
                });
            });
        }
    }
}