import FilterCheckboxsList from '../../Components/FilterCheckboxsList/FilterCheckboxsList.js';
import FilterSearchSelect from '../../Components/FilterSearchSelect/FilterSearchSelect.js';
import FilterRange from '../../Components/FilterRange/FilterRange.js';
import FilterSelect from '../../Components/FilterSelect/FilterSelect.js';
import CourseTile from '../../Components/CourseTile/CourseTile.js';
import LazyLoad from '../../../common/scripts/lazyLoad';

export default {
    init() {

        if( !document.querySelector('body').classList.contains('CourseCatalog') ) {
            return;
        }

        FilterCheckboxsList.init();
        FilterSearchSelect.init();
        FilterSelect.init();
        FilterRange.init();
        CourseTile.init();

        var t = this;
        t.node = document.querySelector('main');

        // -- fields -- //
            t.fields = [
                {
                    'name' : 'content-source',
                    'type' : 'checkbox'
                },
                {
                    'name' : 'level', 
                    'type' : 'range'
                },
                { 
                    'name' : 'content-topic' , 
                    'type' : 'checkbox'
                },
                {
                    'name' : 'duration',
                    'type' : 'checkbox',
                },
                {
                    'name' : 'content-lang',
                    'type' : 'checkbox',
                },
                {
                    'name' : 'author',
                    'type' : 'checkbox',
                },
                {
                    'name' : 'orderby',
                    'type' : 'radio'
                },
                {
                    'name' : 'search',
                    'type' : 'text',
                },
                {
                    'name' : 'layout',
                    'type' : 'radio'
                }
            ];
            let textTime = null;
            t.fields.forEach( function( row, index ) {
                t.node.querySelectorAll('input[name="'+row.name+'"]').forEach( function( input,indexI ) {
                    if( row.type == 'text' ) {
                        input.addEventListener('input', function() {
                            clearTimeout( textTime );
                            textTime = setTimeout(() => {
                                t.reloadContent();
                            }, 2000);
                        });
                    } else {
                        input.addEventListener('change', function() {
                            t.reloadContent();
                        });
                    }
                });
            });
        // -- END -- //
        
        // -- search form -- //
            var searchForm = t.node.querySelector('.ImageLibraryHeader .content__search');
            searchForm.addEventListener('submit', function(e) {
                e.preventDefault();
                clearTimeout( textTime );
                t.reloadContent();
            });
        // -- END -- //
        
        // -- mobile filters -- //
            t.node.querySelector('.header__filter').addEventListener('click', function() {
                document.body.classList.add('disableScroll');
                t.node.querySelector('.section__filters').classList.add('active');
            });
            t.node.querySelector('.header__close').addEventListener('click', function() {
                document.body.classList.remove('disableScroll');
                t.node.querySelector('.section__filters').classList.remove('active');
            });
            t.node.querySelector('.section__filters').addEventListener('click', function(e) {
                if( !e.target.closest('.filters__content') ) {
                    document.body.classList.remove('disableScroll');
                    t.node.querySelector('.section__filters').classList.remove('active');
                }
            });
        // -- END -- //

        // -- pagination -- //
            document.addEventListener('click', function(e) {
                if( e.target.hasAttribute('data-number') ) {
                    t.reloadContent( e.target.getAttribute('data-number') );
                }
            });
        // -- END -- //

        t.node.querySelectorAll('.FilterLabel__select').forEach( function( button, index ) {
			button.addEventListener('click', function() {
				t.reloadContent();
			});
		});

        document.addEventListener('click', function(e) {
            if( e.target.closest('.resetAllFilters') ) {

                t.node.querySelectorAll('.FilterLabel__select').forEach( function( selectButton, index ) {
                    if( selectButton.classList.contains('clicked') ) {
                        selectButton.classList.remove('clicked');
                    }
                });
                t.node.querySelectorAll('.FilterSearchSelect').forEach( function( box, index ){
                    box.querySelector('.FilterSearchSelect__button .button__value').innerHTML = '<span>'+box.querySelector('.FilterSearchSelect__button .button__value').getAttribute('data-default')+'</span>';
                });

                t.fields.forEach( function( row, index ) {
                    if( row.type == 'checkbox' ) {
                        t.node.querySelectorAll('[name="'+row.name+'"]').forEach( function(input, indexI) {
                            input.checked = false;
                        });
                    } else if ( row.type == 'text' ) {
                        t.node.querySelectorAll('[name="'+row.name+'"]').forEach( function(input, indexI) {
                            input.value = null;
                        });
                    }
                });
                t.reloadContent();
            }
        });

    },
    reloadContent( paged = 1 ) {
        let t = this;
        var refreshContainer = t.node.querySelector('.CourseCatalog__section');
        refreshContainer.classList.add('reload');

        // -- set new url -- //
            window.history.pushState( "", "", t.generateNewUrl( paged, true ) );
        // -- END -- //

        // -- refresh -- //

            fetch( t.generateNewUrl(paged), {
                method: 'GET',
            })
            .then( response => response.text() )
            .then( function(html) {
                
                var xmlDoc = document.implementation.createHTMLDocument("example");
                xmlDoc.documentElement.innerHTML = html;
                t.node.querySelector('.content__reload').innerHTML = xmlDoc.querySelector('.content__reload').innerHTML;
                window.scrollTo({ 
                    top: refreshContainer.offsetTop - document.querySelector('header').offsetHeight,
                    left: 0, 
                    behavior: 'smooth'
                });

                LazyLoad.init();
                refreshContainer.classList.remove('reload');
            })
            .catch( function( error ) {
                if( document.body.classList.contains('logged-in') ) {
                    console.log( error );
                    console.log( refreshContainer );
                }
                refreshContainer.classList.remove('reload');
            });

        // -- END -- //

    },
    generateNewUrl( paged = 1, front = false ) {
        let t = this;
        let args = [];

        t.fields.forEach( function( row, index ) {
            let arr = [];
            switch( row.type ) {
                case 'checkbox':
                        t.node.querySelectorAll('[name="'+row.name+'"]:checked').forEach( function( input, indexI ) {
                            arr.push( input.value );
                        });
                        if( arr.length > 0 ) {
                            args.push( row.name+'='+ arr.join(',') );
                        }
                    break;
                    
                case 'radio' :
                        if( t.node.querySelector('[name="'+row.name+'"]') && t.node.querySelector('[name="'+row.name+'"]:checked') ) {
                            args.push( row.name+'='+t.node.querySelector('[name="'+row.name+'"]:checked').value );
                        }
                    break;

                case 'range' :
                        if( t.node.querySelector('[name="'+row.name+'"]') && t.node.querySelector('[name="'+row.name+'"]').value ) {
                            args.push( row.name+'='+ t.node.querySelector('.CustomInputRange [data-range="'+t.node.querySelector('[name="'+row.name+'"]').value+'"]').getAttribute('data-value') );
                        }
                    break;
                case 'text' :
                        if( t.node.querySelector('[name="'+row.name+'"]').value ) {
                            args.push( row.name+'='+t.node.querySelector('[name="'+row.name+'"]').value );
                        }
                    break;
            }
        });
        if( paged != 1 ) {
            args.push('page_num='+paged);
        }
        if( front == false ) {
            document.querySelectorAll('.section__filters .filters__content .content__hidden').forEach( ( input, index ) => {
                args.push( input.getAttribute('name') + '=' + input.value );
            });
            args.push('ajax=1');
        }

        if( args ) {
            return window.location.origin + window.location.pathname + '?' + args.join('&');
        } else {
            return window.location.origin + window.location.pathname;
        }

    },
    finalize() {}
}