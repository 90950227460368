// Defaults
import './style.scss';
import Router from './scripts/Router';
import LazyLoad from './scripts/lazyLoad';
import Subpage from '../src/Layout/Subpage';

// Pages
import homepage from '../src/Pages/Homepage/Homepage';
import contact from '../src/Pages/Contact/Contact'
import resources from '../src/Pages/Resources/Resources'
import resource from '../src/Pages/ResourceSingle/ResourceSingle'
import datadashboard from '../src/Pages/DataDashboard/DataDashboard'
import interactivedatadashboard from '../src/Pages/InteractiveDataDashboard/InteractiveDataDashboard';
import guidelinesdashboard from '../src/Pages/GuidelinesDashboard/GuidelinesDashboard';
import suggestedtrainings from '../src/Pages/SuggestedTrainings/SuggestedTrainings'
import covid from '../src/Pages/Covid/Covid';
import trainingsarchive from '../src/Pages/TrainingsArchive/TrainingsArchive';
import trainingssingle from '../src/Pages/TrainingsSingle/TrainingsSingle';
import faqarchive from '../src/Pages/FaqArchive/FaqArchive';
import faqsingle from '../src/Pages/FaqSingle/FaqSingle';
import sharingtoolkit from '../src/Pages/SharingToolkit/SharingToolkit';
import resourcesnew from '../src/Pages/ResourcesNew/ResourcesNew';
import imagelibrary from '../src/Pages/ImageLibrary/ImageLibrary';
import encyclopediaarchive from '../src/Pages/EncyclopediaArchive/EncyclopediaArchive';
import encyclopediasingle from '../src/Pages/EncyclopediaSingle/EncyclopediaSingle';
import generatorpdf from '../src/Pages/GeneratorPDF/GeneratorPDF';
import coursecatalog from '../src/Pages/CourseCatalog/CourseCatalog';
import coursecatalog2 from '../src/Pages/CourseCatalog2/CourseCatalog2';
import suggestedtrainingstopic from '../src/Pages/SuggestedTrainingsTopic/SuggestedTrainingsTopic';
import suggestedtrainingstopic2 from '../src/Pages/SuggestedTrainingsTopic2/SuggestedTrainingsTopic2';
import defaulttemplate from '../src/Pages/DefaultTemplate/DefaultTemplate';


LazyLoad.init();
Subpage.init();

const routes = new Router({
	homepage,
	contact,
	resources,
	resource,
	datadashboard,
	suggestedtrainings,
	covid,
	trainingsarchive,
	trainingssingle,
	faqarchive,
	faqsingle,
	sharingtoolkit,
	resourcesnew,
	imagelibrary,
	encyclopediaarchive,
	encyclopediasingle,
	generatorpdf,
	coursecatalog,
	coursecatalog2,
	suggestedtrainingstopic,
	suggestedtrainingstopic2,
	defaulttemplate,
	interactivedatadashboard,
	guidelinesdashboard
});

document.addEventListener("DOMContentLoaded", function() {
	routes.loadEvents();
});