export default {

	init() {

		var container = document.querySelector('main.Contact');

		if( container ) {

			function contactForm() {

				var contactForm = container.querySelector('form.form');

				if( contactForm ) {

					var submitButton = contactForm.querySelector('button.form__submit');
					var formSubmit = false;
					var formInputs = contactForm.querySelectorAll('.fields__single .single__input');
					var reCaptchaKey = contactForm.getAttribute('data-site-key');
					contactForm.removeAttribute('data-site-key');


					var callbackEmpty = contactForm.getAttribute('data-empty');
					var callbackEmail = contactForm.getAttribute('data-email');
					var callbackError = contactForm.getAttribute('data-error');
					var callbackSuccess = contactForm.getAttribute('data-success');


					var requestData = new FormData();

					function callback( message = '' , status = 'error' ) {

						document.querySelectorAll('.form__callback').forEach( function( info, index ) {

							info.remove();

						});

						if( message )  {

							submitButton.insertAdjacentHTML('beforebegin','<p class="form__callback '+status+'">'+message+'</p>');

						}

					}

					contactForm.addEventListener('submit', function(e) {
						e.preventDefault();

						if( submitButton.classList.contains('disable') || formSubmit ) {
							return;
						}
						submitButton.classList.add('disable');

						formInputs.forEach( function( input, index ) {

							if( input.hasAttribute('required') ) {

								if( !input.value ) {

									input.classList.add('empty');

								} else {

									input.classList.remove('empty');

								}

								if( input.getAttribute('name') == 'email' ) {

									if( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input.value) ) {

										input.classList.remove('error');

									} else {

										input.classList.add('error');

									}

								}

							}

						});
						
						if( contactForm.querySelectorAll('.single__input.empty').length > 0 ) {
							callback( callbackEmpty );
							submitButton.classList.remove('disable');
							return;
						}
						if( contactForm.querySelectorAll('.single__input.error').length > 0 ) {
							callback( callbackEmail );
							submitButton.classList.remove('disable');
							return;
						} 

						grecaptcha.ready(function() {
							grecaptcha.execute( reCaptchaKey, { action: 'submit_contact_form'} )
							.then(function(token) {

								requestData = new FormData();
								requestData.append( 'action', 'sendMail' );
								requestData.append( 'nonce', token  );
								formInputs.forEach( function( field,index ) {
									if( field.value ) {
										requestData.append( field.getAttribute('name') , field.value );
									}
								});

								fetch( document.querySelector('footer').getAttribute('data-endpoint'), {
									method : 'POST',
									body : requestData
								})
								.then( ( response ) => {
									return response.json();
								})
								.then( ( json ) => {
									console.log( json );
									if( json.status && json.status == 'success' ) {
										callback( callbackSuccess, 'success');
										formSubmit = true;
									} else {
										callback( callbackError );
										submitButton.classList.remove('disable');
									}
								})
								.catch( ( message ) => {
									console.error( message );
									callback( callbackError );
								});
							})
							.catch( ( message ) => {
								console.error( message );
							});
						});


					});

				}

			}
			contactForm();



		}

	},
	finalize() {

	}

}