import FilterNameCheckbox from '../../Components/FilterNameCheckbox/FilterNameCheckbox.js';
import FilterSelect3 from '../../Components/FilterSelect3/FilterSelect3.js';
import EncyclopediaTile from '../../Components/EncyclopediaTile/EncyclopediaTile.js';
import LazyLoad from '../../../common/scripts/lazyLoad';

export default {
    init() {
        if( document.querySelector('main.EncyclopediaArchive') ) {
            FilterNameCheckbox.init();
            FilterSelect3.init();
            EncyclopediaTile.init();

            var container = document.querySelector('main.EncyclopediaArchive');

            var t = this;
            t.reloadTimeout = null;
            t.reloadSection = container.querySelector('section.EncyclopediaArchive__section');
            
            
            // -- aside filter box -- //
                var aside  = container.querySelector('.section__aside');
                var asideContent = aside.querySelector('.aside__content');
                aside.querySelectorAll('.filter__button').forEach( function( button, index ) {
                    button.addEventListener('click', function() {
                        if( button.classList.contains('active') ) {
                            button.classList.remove('active');
                            button.closest('.content__filter').querySelector('.filter__box').style.height = '0px';
                            button.closest('.content__filter').querySelector('.filter__box').classList.remove('active');
                        } else {
                            button.classList.add('active');
                            button.closest('.content__filter').querySelector('.filter__box').style.height = button.closest('.content__filter').querySelector('.filter__box .box__content').offsetHeight +'px';
                            setTimeout(() => {
                                button.closest('.content__filter').querySelector('.filter__box').classList.add('active');
                            }, 200);
                        }
                    });
                } );
                aside.querySelector('.clean__filters').addEventListener('click', function() {
                    aside.querySelectorAll('input[type=checkbox]').forEach( function( input, index ) {
                        if( input.checked ) {
                            input.click();
                        }
                    });  
                });
                aside.querySelectorAll('input[type=checkbox]').forEach( function(input, index) {
                    input.addEventListener('change', function() {
                        if( input.checked ) {
                            container.querySelector('.EncyclopediaArchive__section .content__filters .selected__box').insertAdjacentHTML('beforeend','<li><button data-name="'+input.getAttribute('name')+'" data-value="'+input.value+'" class="box__single">'+input.getAttribute('data-label')+'</button></li>');
                        } else {
                            container.querySelector('.EncyclopediaArchive__section .content__filters .selected__box button[data-name='+input.getAttribute('name')+'][data-value='+input.value+']').closest('li').remove();
                        }
                        t.FiltersBoxRecalcItems();
                    });
                });
                aside.addEventListener('click', function(e) {
                    if( !e.target.closest('.aside__content') ) {
                        aside.classList.remove('active');
                        document.body.classList.remove('disableScroll');
                    }
                });
                asideContent.querySelector('.content__header .header__close').addEventListener('click', function() {
                    aside.classList.remove('active');
                    document.body.classList.remove('disableScroll');
                });
            // -- END -- //
            

            // -- filters -- //
                var filters = container.querySelector('.EncyclopediaArchive__section .content__filters');
                var filtersButton = filters.querySelector('.filters__button');
                var filtersMobile = filters.querySelector('.filters__mobile');
                if( filtersButton.classList.contains('filters__button--initial') ) {
                    t.FiltersSetHeight();
                    filtersButton.classList.remove('filters__button--initial');
                }
                filtersButton.addEventListener('click', function() {
                    if( filtersButton.classList.contains('active') ) {
                        filtersButton.classList.remove('active');
                        filters.querySelector('.filters__selected').style.height = '0px';
                    } else {
                        filtersButton.classList.add('active');
                        t.FiltersSetHeight();
                    }
                });
                container.querySelector('.EncyclopediaArchive__section .content__filters .selected__box').addEventListener( 'click', function(e) {
                    if( e.target.classList.contains('box__single') ) {
                        let element = e.target.closest('li');
                        aside.querySelector('input[name="'+e.target.getAttribute('data-name')+'"][value="'+e.target.getAttribute('data-value')+'"]').click();
                        element.remove();
                        t.FiltersBoxRecalcItems();
                    }
                });
                filtersMobile.addEventListener('click', function() {
                    aside.classList.add('active');
                    document.body.classList.add('disableScroll');
                });
                window.addEventListener('resize',function() {
                    if( aside.classList.contains('active') ) {
                        if( window.innerWidth > 960 && document.body.classList.contains('disableScroll') ) {
                            document.body.classList.remove('disableScroll');
                        } else if( window.innerWidth <= 960 && !document.body.classList.contains('disableScroll') ) {
                            document.body.classList.add('disableScroll');
                        }
                    }
                    if( filtersButton.classList.contains('active') ) {
                        t.FiltersSetHeight();
                    }
                });
            // -- END -- //
            
            
            // -- reload actions -- //

                // -- search -- //
                    var searchInputTimeout = null;
                    document.querySelector('.ImageLibraryHeader form').addEventListener('submit', function(e) {
                        e.preventDefault();
                        clearTimeout( searchInputTimeout );
                        t.StartReload();
                    });
                    document.querySelector('.ImageLibraryHeader input[name=search]').addEventListener('input', function() {
                        clearTimeout( searchInputTimeout );
                        searchInputTimeout = setTimeout(
                            () => {
                                t.StartReload();
                            },
                            2000,
                        );
                    });
                // -- END -- //

                // -- filters -- // 
                    var filtersRadio = ['starts_with','language','population','level','type','category','orderby'];
                    filtersRadio.forEach( function( name, index ) {
                        if( document.querySelectorAll('input[name='+name+']').length > 0 ) {

                            document.querySelectorAll('input[name='+name+']').forEach( function(input,index2) {
                                input.addEventListener('change', function() {
                                    t.StartReload();
                                });
                            });
                        }
                    });
                // -- END -- //

                // -- pagination -- //
                    document.addEventListener('click', function(e) {
                        if( e.target.hasAttribute('data-number') && e.target.closest('.Pagination') ) {
                            t.StartReload( e.target.getAttribute('data-number') );
                        }
                    });
                // -- END -- //

            // -- END -- //

        }
    },
    FiltersBoxRecalcItems( container = document.querySelector('main.EncyclopediaArchive') ) {
        var t = this;
        container.querySelector('.EncyclopediaArchive__section .filters__button').setAttribute('data-items', container.querySelectorAll('.EncyclopediaArchive__section .content__filters .selected__box button').length );
        t.FiltersSetHeight();
    },
    FiltersSetHeight( container = document.querySelector('main.EncyclopediaArchive .content__filters') ) {
        if( container.querySelector('.filters__button').classList.contains('active') ) {
            container.querySelector('.filters__selected').style.height = container.querySelector('.filters__selected .selected__box').offsetHeight + 'px';
        }
    },
    StartReload( paged = 1 ) {
        var t = this;
        t.reloadSection.classList.add('reload');
        let args = t.ReloadGetArgs( paged );

        clearTimeout(t.reloadTimeout);
        t.reloadTimeout = setTimeout(() => {
            t.Reload( args );
            t.ReloadHistoryPush( args );
        }, 500);
        
    },
    ReloadGetArgs( paged = 1 ) {

        let search = document.querySelector('.ImageLibraryHeader input[name=search]');
        let filters = ['starts_with','language','population','level','type','category','orderby'];
        let args = [];

        if( search && search.value ) {
            args.push( 'search='+search.value );
        }
        filters.forEach( function( name, index ) {
            let filter = [];
            document.querySelectorAll('input[name='+name+']:checked').forEach( function( input,index2 ) {
                if( input.value ) {
                    filter.push( input.value );
                }
            });
            if( filter.length > 0 ) {
                args.push( name+'='+filter.join(',') );
            }
        });
        if( paged > 1 ) {
            args.push('page_num='+paged);
        }
        if( args.length > 0 ) {
            return '?'+args.join('&');
        } else {
            return '';
        }
    },
    Reload( args = '' ) {
        var t = this;
        if( args ) {
            args += '&ajax';
        } else {
            args = '?ajax';
        }

        fetch( window.location.origin+window.location.pathname+args, {
            method: 'get',
        })
        .then(res => res.text())
        .then(response => {

            var html = document.createElement('div');
            html.innerHTML = response;
            document.querySelector('.content__reload').innerHTML = html.querySelector('.content__reload').innerHTML;
            LazyLoad.init();

            setTimeout( function(){
                t.reloadSection.classList.remove('reload');
            },500 );
                

        }).catch(function(err) {
            t.reloadSection.classList.remove('reload');
            //console.error(` Err: ${err}`);
        });

    },
    ReloadHistoryPush( args ) {
        history.pushState({}, '', window.location.origin+window.location.pathname+args);
    }
}