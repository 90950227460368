import Subpage from '../../Layout/Subpage';
export default {
    init( action = null, baseOnCookies = false ) {
        var t = this;
		t.action = action;
		t.cookie = baseOnCookies;

    	// -- check cookie -- //
    		var showContainer = Subpage.checkCookie( t.action );
    	// -- END -- //

    	if( showContainer !== '1' || t.cookie == false ) {

			fetch( document.querySelector('footer').getAttribute('data-endpoint')+"?action="+t.action, {
				method: "GET",
			})
			.then( function( response ) {
				return response.text();
			})
			.then( function( html ) {

				if( html ) {
					document.querySelector('body').insertAdjacentHTML('beforeend', html );
					if( document.querySelector('.InfoModal') ) {
						t.finalize( document.querySelector('.InfoModal') );
					}
				}

			})
			.catch( function( error ) {
				console.log(error );
			});
		}
    },
    finalize( container = null ) {
        var t = this;
		if( container ) {

			setTimeout(function() {
				container.classList.add('active');
			}, 500);
			container.querySelector('.content__close').addEventListener('click', function() {
				container.classList.remove('active');
				setTimeout(function() {
					container.remove();
				}, 500);
			});
			container.querySelector('.content__accept').addEventListener('click', function() {
				if( t.cookie ) {
					Subpage.setCookie( t.action ,'1',1);
				}
				container.classList.remove('active');
				setTimeout(function() {
					container.remove();
				}, 500);
			});
		}
    }
}