import newsletter from '../../Sections/Newsletter/Newsletter';
import carouselHero from '../../Sections/CarouselHero/CarouselHero';
import carouselnoshading from '../../Sections/CarouselNoShading/CarouselNoShading';

export default {
	init() {
    	newsletter.init();
		carouselHero.init();
		carouselnoshading.init();
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
