import FaqItem from '../../Components/FaqItem/FaqItem.js';
import AlgoliaResults from '../../Components/AlgoliaResults/AlgoliaResults';
export default {
	init() {
		FaqItem.init();
		if( document.querySelector('.ImageLibraryHeader') && document.querySelector('.ImageLibraryHeader form.content__search') ) {
			var obj = new AlgoliaResults({
				'listener' : document.querySelector('.ImageLibraryHeader input[name="search"]'),
				'elementToAppend' : document.querySelector('.ImageLibraryHeader form.content__search'),
				'indexName' : 'cpt_faq',
				'icon' : document.querySelector('.ImageLibraryHeader .content__title').getAttribute('data-url'),
			});
		}
	},
	finalize() {

	}
}