import {computePosition, shift, flip, offset, autoPlacement} from '@floating-ui/dom';
export default {
    init( node = document ) {
        var t = this;

        node.querySelectorAll('.WysiwygTooltip').forEach( function( element, index ) {
            element.addEventListener('click', function() {
                element.classList.toggle('active');
                t.setPosition( element );
            }); 
            element.addEventListener('mouseenter', function() {
                element.classList.add('active');
                t.setPosition( element );
            });
            element.addEventListener('mouseleave', function() {
                element.classList.remove('active');
            }); 
        });

        window.addEventListener('resize', function() {
            node.querySelectorAll('.WysiwygTooltip.active').forEach( function( element, index ) {
                t.setPosition( element );
            });
        });
    },
    setPosition( element ) {

        computePosition(element, element.querySelector('span'), {
            placement: 'top',
            middleware: [
                offset(6),
                shift(),
                autoPlacement()
            ],
        }).then(({x, y}) => {
            Object.assign(element.querySelector('span').style, {
                left: `${x}px`,
                top: `${y}px`,
            });
        });

    }
}