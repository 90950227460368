import $ from 'jquery';

export default {
	init() {

		$('body').on('click','.ShareButton',function(e) {

    		e.preventDefault();

			let success = $(this).find('span');
			success.addClass('active');
			
			setTimeout(function(){ success.removeClass('active'); }, 1200);

			e.stopPropagation();

			let element = document.createElement('input');

			element.value = $(this).attr('href');
			element.setAttribute('readonly','');
			element.style.position = 'absolute';
			element.style.left = '-99999px';

			document.body.appendChild( element );

			element.select();
			document.execCommand('copy');
			document.body.removeChild(element);
			
		});

	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
