export default {
    init() {
        document.addEventListener('click', function(e) {
            if( e.target.classList.contains('ToolTip__icon') ) {
                if( e.target.closest('.ToolTip').classList.contains('active') ) {
                    e.target.closest('.ToolTip').classList.remove('active')
                } else {
                    e.target.closest('.ToolTip').classList.add('active');
                }
            }
            else if( e.target.classList.contains('ToolTip--parent') ) {
                if( e.target.querySelector('.ToolTip--child').classList.contains('active') ) {
                    e.target.querySelector('.ToolTip--child').classList.remove('active')
                } else {
                    e.target.querySelector('.ToolTip--child').classList.add('active');
                }
            } else if( e.target.classList.contains('ToolTip__close') ) {
                e.target.closest('.ToolTip').remove();
            } else {
                document.querySelectorAll('.ToolTip').forEach( function( item, index ) {
                    item.classList.remove('active');
                });
            }
        });
    },
    finalize() {

    }
}