export default {
    init() {
        document.addEventListener('click', function(e) {
            if( e.target.classList.contains('ImageLibraryDownloadList__button') ) {
                if( e.target.closest('.ImageLibraryDownloadList').classList.contains('active') ) {
                    e.target.closest('.ImageLibraryDownloadList').classList.remove('active');
                } else {
                    e.target.closest('.ImageLibraryDownloadList').classList.add('active');
                }
            }
            if( !e.target.closest('.ImageLibraryDownloadList') ) {
                document.querySelectorAll('.ImageLibraryDownloadList').forEach( function( list, index ) {
                    list.classList.remove('active');
                });
            }
        });
    },
    finalize() {

    }
}