import $ from 'jquery';
import ScrollTop from '../Components/ScrollTop/ScrollTop';
import Cookie from '../Components/Cookie/Cookie';
import FeedbackForm from '../Sections/FeedbackForm/FeedbackForm';

import WysiwygTooltip from '../Components/WysiwygTooltip/WysiwygTooltip';
import Header from '../Sections/Header/Header';
import HeaderSearch from '../Sections/HeaderSearch/HeaderSearch';
import MobileMenu from '../Sections/MobileMenu/MobileMenu';
import GoogleTranslate from '../Components/GoogleTranslate/GoogleTranslate';

import AlgoliaResults from '../Components/AlgoliaResults/AlgoliaResults';
import FixedTopLinkBatch from '../Components/FixedTopLinkBatch/FixedTopLinkBatch';

export default {

	init() {
		var t = this;
		t.iframeGetHeight();

		// -- remove preload -- //
			if(document.readyState === 'complete') {
				document.body.classList.remove('preload');
			}
			else {
				document.addEventListener('readystatechange', function(e) {
					if( e.target.readyState === 'complete' ) {
						document.body.classList.remove('preload');
					}
				});
			}
		// -- END -- //

		ScrollTop.init();
		Cookie.init();
		FeedbackForm.init();
		WysiwygTooltip.init();
		Header.init();
		HeaderSearch.init();
		GoogleTranslate.init();
		FixedTopLinkBatch.init();
		
		MobileMenu.init();

		function initLandbot() {

			if( typeof(window.LANDBOT_ID) == 'undefined' ) {
				return;
			}

	        if (!window.LANDBOT_LOADED) {
	            setTimeout(initLandbot, 100);
	            return;
	        }

	        var myLandbot = new Landbot.Popup({ 
                configUrl: 'https://chats.landbot.io/v3/' + window.LANDBOT_ID + '/index.json', 
            }); 

            $('body').on('click','.Button--landbot',function(){
			    event.preventDefault();
                myLandbot.open();
			});

			$(".MobileMenu .Button--landbot").click(function() {
				event.preventDefault();
                myLandbot.open();
			});

	    }

		this.HeaderSearch()

		if(document.readyState === 'complete') {
			setTimeout(
				initLandbot,
				5000
			);
        }
        else {
            document.addEventListener('readystatechange', function(e) {
            	if( e.target.readyState === 'complete' ) {
					setTimeout(
						initLandbot,
						5000
					);
            	}
            });
        }

		// -- unregister serviceWorker -- //
			if( window.navigator && navigator.serviceWorker ) {
				navigator.serviceWorker.getRegistrations()
				.then(function(registrations) {
					for(let registration of registrations) {
						registration.unregister();
					}
				});
			}
		// -- END -- //

		// -- header change language -- //
			var langButtons = document.querySelectorAll('button[data-g-lang]');
			var translateModule = document.querySelector('.GoogleTranslate');
			var langSwitchTimer = null;
			if( langButtons.length > 0 && translateModule ) {
				langButtons.forEach( ( button, index ) => {
					button.addEventListener('click', () => {
						
						clearInterval( langSwitchTimer );
						langSwitchTimer = null;

						if( translateModule.querySelector('select') && translateModule.querySelector('select').value != button.getAttribute('data-g-lang') ) {

							langSwitchTimer = setInterval(
								() => {
									translateModule.querySelector('select').value = button.getAttribute('data-g-lang');
									translateModule.querySelector('select').dispatchEvent( new Event('change') );

									if( translateModule.querySelector('select').value == button.getAttribute('data-g-lang') ) {
										clearInterval( langSwitchTimer );
										langSwitchTimer = null;
									}

								},
								500
							);

						}
					});
				});
			}
		// -- END -- //

	},
	isTouchDevice() {

		var isTouch = null;

		if ("ontouchstart" in window || navigator.msMaxTouchPoints) {

		    isTouch = true;

		} else {

		    isTouch = false;

		}

		return isTouch;

	},
	checkCookie( cookieName ) {

		var name = cookieName + "=";
	    var decodedCookie = decodeURIComponent(document.cookie);
	    var ca = decodedCookie.split(';');
	    for(var i = 0; i <ca.length; i++) {
	        var c = ca[i];
	        while (c.charAt(0) == ' ') {
	            c = c.substring(1);
	        }
	        if (c.indexOf(name) == 0) {
	            return c.substring(name.length, c.length);
	        }
	    }
	    return "";

	},
	setCookie(cname, cvalue, exdays) {
	    var d = new Date();
	    d.setTime(d.getTime() + (exdays*24*60*60*1000));
	    var expires = "expires="+ d.toUTCString();
	    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	},
	HeaderSearch() {
		var header = document.querySelector('header.Header--form');
		if( header ) {
			new AlgoliaResults({
				'listener' : header.querySelector('input[name="search"]'),
				'elementToAppend' : document.querySelector('form.content__search'),
				'indexName' : 'cpt_resources'
			})
		}
	},
	iframeGetHeight() {
		if( document.body.classList.contains('iframe--auto-height') ) {
			let resizeTimeout = null;
			window.parent.postMessage( document.querySelector('main').offsetHeight, '*');
			window.addEventListener('resize', function() {
				window.parent.postMessage( document.querySelector('main').offsetHeight, '*');
			});

			function outputsize() {
				clearInterval( resizeTimeout );
				resizeTimeout = setTimeout( 
					function() {
						window.parent.postMessage( document.querySelector('main').offsetHeight, '*');
					},
					300
				);
			}
			new ResizeObserver(outputsize).observe(document.querySelector('main') );

		}
	},
	finalize() {}
}