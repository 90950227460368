import $ from 'jquery';
import FilterTip from '../../Components/FilterTip/FilterTip';

export default {

	init() {

		var container = document.querySelector('main.suggested-trainings');

		if( container ) {

			var t = this;
			var topics = container.querySelectorAll('.SuggestedTrainings__topic');

			FilterTip.init();

			document.addEventListener('click', function(e) {

				if( e.target.classList.contains('.l1-button') || e.target.closest('.l1-button') ) {

					if( e.target.classList.contains('active') ) {

						e.target.classList.remove('active');
						e.target.closest('.SuggestedTrainings__topic').querySelector('.l1').style.height = '0px';	

					} else {

						e.target.classList.add('active');
						e.target.closest('.SuggestedTrainings__topic').querySelector('.l1').style.height = e.target.closest('.SuggestedTrainings__topic').querySelector('.l1-absolute').offsetHeight + 'px';
						
					}

				}

				if( e.target.classList.contains('.l2-button') || e.target.closest('.l2-button') ) {

					if( e.target.classList.contains('active') ) {

						e.target.classList.remove('active');
						e.target.parentNode.querySelector('.l2').style.height = '0px';	

						e.target.closest('.SuggestedTrainings__topic').querySelector('.l1').style.height = e.target.closest('.SuggestedTrainings__topic').querySelector('.l1').offsetHeight - e.target.parentNode.querySelector('.l2-absolute').offsetHeight + 'px';

					} else {

						e.target.classList.add('active');
						e.target.parentNode.querySelector('.l2').style.height = e.target.parentNode.querySelector('.l2-absolute').offsetHeight + 'px';
						
						e.target.closest('.SuggestedTrainings__topic').querySelector('.l1').style.height = e.target.closest('.SuggestedTrainings__topic').querySelector('.l1').offsetHeight + e.target.parentNode.querySelector('.l2-absolute').offsetHeight + 'px';

					}					

				}

			});

			function closeAllAccordions() {

				container.querySelectorAll('.l2-button').forEach( function( button2, index2 ) {

					if( button2.classList.contains('active') ) {

						button2.click();

					}

				});

				container.querySelectorAll('.l1-button').forEach( function( button2, index2 ) {

					if( button2.classList.contains('active') ) {

						button2.click();

					}

				});

				$(".SuggestedTrainings__items .expand-collapse-button span").text( $(".SuggestedTrainings__items .expand-collapse-button").data("expand") );

			}

			function openAllAccordions() {

				$('.SuggestedTrainings__subtopics, .SuggestedTrainings__resources').addClass('without-transition');

				

				container.querySelectorAll('.l2-button').forEach( function( button2, index2 ) {

					button2.classList.add('active');

				});

				container.querySelectorAll('.l1-button').forEach( function( button2, index2 ) {

					button2.classList.add('active');

				});

				document.querySelectorAll('.SuggestedTrainings__topic').forEach( function( single,index ) {

					single.querySelectorAll('.l2-absolute').forEach( function( box,index ) {

						box.parentElement.style.height = box.offsetHeight + 'px';

					});

					single.querySelector('.l1').style.height = single.querySelector('.l1-absolute').offsetHeight + 'px';

				});

				$('.SuggestedTrainings__subtopics, .SuggestedTrainings__resources').removeClass('without-transition');
				$(".SuggestedTrainings__items .expand-collapse-button span").text( $(".SuggestedTrainings__items .expand-collapse-button").data("collapse") );

			}


			function refreshContent() {

				if( container.getAttribute('data-accordion-open') !== '1' ) {
					closeAllAccordions();
				} 
				
				$('.SuggestedTrainings__topic').addClass('inactive');
				$('.SuggestedTrainings__subtopic').addClass('inactive');
				$('.ResourceBox').addClass('inactive');

				let condition = '';

				$( ".SuggestedTrainings__heading-filter" ).each(function( index ) {
					if( $(this).hasClass('allActive') ) {
						$(this).find('.SuggestedTrainings__heading-filter-level').addClass('active');
						$(this).find('.SuggestedTrainings__heading-filter-lang').addClass('active');
					}
				});

				if( $(".SuggestedTrainings__heading-filter-level.active").length > 0 && $(".SuggestedTrainings__heading-filter-lang.active").length > 0 ) {

					$(".SuggestedTrainings__heading-filter-level.active").each(function( index ) {

						condition = '[data-rblevel*="' + $(this).data("slug") + '"]';

						$(".SuggestedTrainings__heading-filter-lang.active").each(function( index ) {

							let rbactive = $('.ResourceBox' + condition + '[data-rblang*="' + $(this).data("slug") + '"]');

							rbactive.removeClass('inactive');
							rbactive.closest('.SuggestedTrainings__subtopic').removeClass('inactive');
							rbactive.closest('.SuggestedTrainings__topic').removeClass('inactive');

						});
						
					});

				}

				$('.SuggestedTrainings__topics').each( function( index, box ) {

					$(document).find('.subheader__available').addClass('active');
					$(document).find('.subheader__available span').text( $(this).find('.ResourceBox:not(.inactive)').length );

				});


				if($('.ResourceBox:not(.inactive)').length > 0) {
					$('.SuggestedTrainings__empty').removeClass('active');
				}
				else {
					$('.SuggestedTrainings__empty').addClass('active');
				}

				if( container.getAttribute('data-accordion-open') === '1' ) {
					openAllAccordions();
				}

			}

			$(".SuggestedTrainings__heading-filter-level").click(function() {

				$(this).closest('.SuggestedTrainings__heading-filter').removeClass('allActive');
				// $('.SuggestedTrainings__heading-filter-level').removeClass('active');
				$(this).toggleClass('active');
				refreshContent();

				let levels = [];

				if($(".SuggestedTrainings__heading-filter-level.active").length == $(".SuggestedTrainings__heading-filter-level").length || $(".SuggestedTrainings__heading-filter-level.active").length == 0) {
					$('.SuggestedTrainings__items-subheading').text($('.SuggestedTrainings__items-subheading').data("default"));
				}
				else {
					$(".SuggestedTrainings__heading-filter-level.active").each(function() {
						levels.push( $(this).data( "heading" ) );
					});
					$('.SuggestedTrainings__items-subheading').text(levels.join(", "));
				}

			});

			$(".SuggestedTrainings__heading-filter-lang").click(function() {

				$(this).closest('.SuggestedTrainings__heading-filter').removeClass('allActive');
				$(this).toggleClass('active');
				refreshContent();

			});

			$(".SuggestedTrainings__items .expand-collapse-button").click(function() {

				if( $.trim($(this).find('span').text()) == $(this).data("expand") ) {
					openAllAccordions();
				}
				else {
					closeAllAccordions();
				}

			});

			$(".SuggestedTrainings__heading-info").click(function() {	

				if( t.isTouchDevice() ) {

					$(".TooltipMobile .row").removeClass('active');
					$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"']").addClass('active');
					$('.TooltipMobile').addClass('active');
					$('body').addClass('disableScroll');

				}

			});

			$(".TooltipMobile__close").click(function() {
				$('.TooltipMobile').removeClass('active');
				$('body').removeClass('disableScroll');
			});

			//set default options - l1 + en
			$('.SuggestedTrainings__heading-filter-lang').closest('.SuggestedTrainings__heading-filter').removeClass('allActive');
			$('.SuggestedTrainings__heading-filter-lang[data-slug="english"]').click();

			// $('.SuggestedTrainings__heading-filter-lang[data-slug="english"]').toggleClass('active');
			// $('.SuggestedTrainings__heading-filter-level[data-slug="l1"]').click();

			if( container.getAttribute('data-accordion-open') === '1' ) {

				openAllAccordions();

			}

		}

	},
	isTouchDevice() {
		try {  
			document.createEvent("TouchEvent");  
			return true;  
		} catch (e) {  
			return false;  
		} 
	},
	finalize() {

	}

}	