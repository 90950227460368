export default {
    init() {

        document.querySelectorAll('.LanguageSelect').forEach( ( container, index ) => {
            this.finalize( container );
        });

    },
    finalize( container ) {

        let button = container.querySelector('.LanguageSelect__button');
        let buttonContent = button.querySelector('.button__content');

        let wrapper = container.querySelector('.LanguageSelect__wrapper');
        let wrapperContent = wrapper.querySelector('.wrapper__content');
        let wrapperButtons = wrapper.querySelectorAll('.single__button');

        button.addEventListener('click', () => {
            if( button.classList.contains('active') )  {
                button.classList.remove('active');
                wrapper.style.height = '0px';
            } else {
                button.classList.add('active');
                wrapper.style.height = wrapperContent.offsetHeight + 'px';
            }
        });

        wrapperButtons.forEach( ( button, index ) => {
            button.addEventListener('click', () => {
                buttonContent.textContent = button.getAttribute('data-g-lang-label');
            });
        });

        window.addEventListener('resize', function() {
            if( button.classList.contains('active') )  {
                wrapper.style.height = wrapperContent.offsetHeight + 'px';
            }
        });

        document.addEventListener('click', ( event ) => {
            if( !event.target.closest( '.LanguageSelect' ) ) {
                button.classList.remove('active');
                wrapper.style.height = '0px';
            }
        });

    }
}