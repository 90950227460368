import ShareBox2 from '../../Components/ShareBox2/ShareBox2';
import LevelsAccordion from '../../Components/LevelsAccordion/LevelsAccordion';
import FilterRange from '../../Components/FilterRange/FilterRange';
import ImageZoom from '../../Components/ImageZoom/ImageZoom';
import WysiwygTooltip from '../../Components/WysiwygTooltip/WysiwygTooltip';
import HeaderSearch from '../../Sections/HeaderSearch/HeaderSearch';
import LazyLoad from '../../../common/scripts/lazyLoad';
import smoothscroll from 'smoothscroll-polyfill';
import Subpage from '../../../src/Layout/Subpage';

export default {
    init() {
        if( document.querySelector('main.EncyclopediaSingle') ) {
            var t = this;

            smoothscroll.polyfill();
            LevelsAccordion.init();
            FilterRange.init();
            ShareBox2.init();
                        
            // -- images zoom -- //
                var psObj = new ImageZoom();
                document.addEventListener('click', function(e) {
                    if( e.target.classList.contains('media__image') && e.target.tagName.toLowerCase() == 'button' ) {
                        let images = document.querySelectorAll('main.EncyclopediaSingle button.media__image');
                        images.forEach( function( image, index ) {
                            if( image.getAttribute('data-url') == e.target.getAttribute('data-url') ) {
                                psObj.init( images, index );
                            }
                        });
                    }
                });
            // -- END -- //


            // -- aside levels -- //
                var levelsBox = document.querySelector('.FilterRange');
                if( levelsBox ) {
                   
                    // -- reload -- //
                        var levelsInput = levelsBox.querySelector('input[type=range]');

                        var mouseDown = false;
                        levelsInput.addEventListener('mousedown', function() {
                            mouseDown = true;
                        });
                        levelsInput.addEventListener('mouseup', function() {
                            mouseDown = false;
                            t.reloadContent( levelsBox.querySelector('.box__point--big.active').getAttribute('data-value'), levelsBox.querySelector('.box__point--big.active').getAttribute('data-label').toLowerCase() );
                        });
                        levelsInput.addEventListener('input', function() {
                            if( !mouseDown ) {
                                t.reloadContent( levelsBox.querySelector('.box__point--big.active').getAttribute('data-value'), levelsBox.querySelector('.box__point--big.active').getAttribute('data-label').toLowerCase() );
                            }
                        });
                    // -- END -- //

                }
            // -- END -- //

            // -- aside mobile -- //
                var aside  = document.querySelector('aside');
                if( aside ) {
                    var buttonOpen = document.querySelector('.section__filter');
                    var buttonClose = aside.querySelector('.content__mobile');
                    var asideContent = aside.querySelector('.aside__content');

                    buttonOpen.addEventListener('click', function() {
                        aside.classList.add('active');
                        document.body.classList.add('disableScroll');
                    });
                    buttonClose.addEventListener('click', function() {
                        aside.classList.remove('active');
                        document.body.classList.remove('disableScroll');
                    });
                    aside.addEventListener('click', function(e) {
                        if( !e.target.closest('.aside__content') ) {
                            aside.classList.remove('active');
                            document.body.classList.remove('disableScroll');
                        }
                    });

                }
            // -- END -- //

            // -- print -- //
                var printContent = document.querySelector('.right__print')
                var printBtn = printContent.querySelector('.print__button');

                var printMessage = printContent.querySelector('.print__message');
                var printError = printContent.querySelector('.print__error');

                var allImageLoaded = true;
                var checkCounter = 0;
                var checkInterval = null;
                printBtn.addEventListener('click', function() {

                    if( printBtn.classList.contains('disabled') ) {
                        return;
                    }
                    printBtn.classList.add('disabled');
                    clearInterval( checkInterval );

                    // -- message load images -- //
                        document.querySelectorAll('.section__content .image-wrapper').forEach( function( wrapper, index ) {
                            if( !wrapper.querySelector('img') || wrapper.querySelector('img').complete == false ) {
                                printMessage.classList.add('active');
                            }
                        });
                        setTimeout(() => {
                            printMessage.classList.remove('active');
                        }, 2000);
                    // -- END -- //
                

                    document.querySelectorAll('.section__content .image-wrapper .lazy-load-item:not([data-loaded=true])').forEach( function( element, index ) {
                        element.setAttribute('data-loaded','true');
                        element.insertAdjacentHTML('beforeend','<img>');                        
                    } );
                    checkInterval = setInterval( () => {
                        allImageLoaded = true;
                        if( checkCounter >= 10 ) {
                            clearInterval( checkInterval );
                            printBtn.classList.remove('disabled');
                            printError.classList.add('active');
                            setTimeout(() => {
                                printError.classList.remove('active');
                            }, 2000);
                        }
                        document.querySelectorAll('.section__content .image-wrapper img').forEach( function( img, index ) {
                            if( document.body.classList.contains('logged-in') ) {
                                console.log( img );
                                console.log( img.complete );
                            }
                            if( img.complete == false ) {
                                allImageLoaded = false;
                            }
                        });
                        if( allImageLoaded ) {
                            clearInterval( checkInterval );
                            checkInterval = null;
                            printBtn.classList.remove('disabled');
                            printMessage.classList.remove('active');
                            window.print();
                        }
                        checkCounter += 1;
                        if( document.body.classList.contains('logged-in') ) {
                            console.log( checkCounter );
                        }
                    }, 500);
                    
                });
            // -- END -- //

            // -- copy link from elastic -- //
                document.addEventListener('click', function(e) {
                    if( e.target.classList.contains('elastic__copy') ) {
                        e.preventDefault();
                        e.target.classList.add('active');
                        setTimeout(function() {
                            e.target.classList.remove('active');
                        }, 1000);

                        let element = document.createElement('input');

                        element.value = e.target.getAttribute('data-url');
                        element.setAttribute('readonly','');
                        element.style.position = 'absolute';
                        element.style.left = '-99999px';

                        document.body.appendChild( element );

                        element.select();
                        document.execCommand('copy');
                        document.body.removeChild(element);
                    }
                });
            // -- END -- //


            // -- sticky set max height -- //
                var stickyElement = document.querySelector('section.EncyclopediaSingle__section aside .aside__content');
                if( stickyElement ) {
                    t.asideMaxHeight( stickyElement );
                    window.addEventListener('resize', function() {
                        t.asideMaxHeight( stickyElement );
                    });
                }
            // -- END -- //
            
            // -- hash scroll -- //
                var hash = window.location.hash;
                if( hash && document.querySelector(hash) ) {
                    window.scrollTo({ 
                        top: document.querySelector(hash).offsetTop - document.querySelector('header').offsetHeight,
                        left: 0, 
                        behavior: 'smooth'
                    });
                }
            // -- END -- //
            
            // -- header-search -- //
                var headerSearchForm = document.querySelector('.Header--search .form__search'); 
                if( headerSearchForm ) {
                    var headerInputTimeout = null;
                    headerSearchForm.addEventListener('submit', function() {
                        HeaderSearch.searchInDom( headerSearchForm.querySelector('input').value, document.querySelectorAll('.elastic__reload .elastic__search') );
                    });
                    headerSearchForm.querySelector('input').addEventListener('input', function() {
                        clearTimeout( headerInputTimeout );
                        headerInputTimeout = setTimeout(
                            function(){
                                HeaderSearch.searchInDom( headerSearchForm.querySelector('input').value, document.querySelectorAll('.elastic__reload .elastic__search') );
                            },
                            500
                        );
                    }); 
                }
                
            // -- END -- //

            // -- content-level purple tip -- //
                var tipContent = document.querySelector('.section__aside .content__levels .levels__tip');
                if( tipContent ) {
                    var tipContentBtnLeft = tipContent.querySelector('.top__row .row__left');
                    var tipContentBtnRight = tipContent.querySelector('.top__row .row__right');
                    tipContentBtnLeft.addEventListener('click', function() {
                        tipContent.classList.remove('active');
                        Subpage.setCookie('single-encyclopedia-tip',1,14);
                        setTimeout(() => {
                            tipContent.remove();
                        }, 200);
                    });
                    tipContentBtnRight.addEventListener('click', function() {
                        tipContent.classList.remove('active');
                        setTimeout(() => {
                            tipContent.remove();
                        }, 200);
                    });
                }
            // -- END -- //
            
            t.topicHighLight();

        }
    },
    asideMaxHeight( element ) {
        element.style.maxHeight = window.innerHeight - document.querySelector('header').offsetHeight + 'px';
    },
    reloadContent( categorySlug = null, categorySlugPretty = null ) {
        var t = this;
        document.querySelector('.EncyclopediaSingle__section').classList.add('reload');

        let newUrl = document.querySelector('main').getAttribute('data-url')+document.querySelector('main').getAttribute('data-char')+'level='+categorySlug;

        window.history.pushState( { } ,document.querySelector('h1').textContent +' - '+categorySlugPretty , document.querySelector('main').getAttribute('data-url')+ categorySlugPretty+'/' );
        document.querySelector('title').textContent = document.querySelector('h1').textContent +' - '+ categorySlugPretty.charAt(0).toUpperCase() + categorySlugPretty.slice(1)+' - '+document.querySelector('main').getAttribute('data-name');

        fetch( newUrl+"&ajax", {
            method: "GET",
        })
        .then( response => response.text() )
        .then( function(response) {

            var html = document.createElement('div');
            html.innerHTML = response;

            if( document.querySelector('.content__accordion') ) {
                document.querySelector('.content__accordion').remove();
            }
            if( html.querySelector('.content__accordion') ) {
                document.querySelector('.aside__content').insertAdjacentHTML('beforeend',  html.querySelector('.content__accordion').outerHTML );
            }

            if( document.querySelector('.elastic__reload') ) {
                document.querySelector('.elastic__reload').remove();
            }
            if( html.querySelector('.elastic__reload') ) {
                document.querySelector('.content__elastic').insertAdjacentHTML('beforeend', html.querySelector('.elastic__reload').outerHTML );
            }
            LazyLoad.init();
            LevelsAccordion.removeOpen();

            t.topicHighLight();
            WysiwygTooltip.init( document.querySelector('.content__elastic') );

            if( document.querySelector('section.EncyclopediaSingle__section aside .aside__content') ) {
                t.asideMaxHeight( document.querySelector('section.EncyclopediaSingle__section aside .aside__content') );
            }

            document.querySelector('.EncyclopediaSingle__section').classList.remove('reload');
        })
        .catch( function( error ) {
            if( document.body.classList.contains('logged-in') ) {
                console.log("EncyclopediaSingle reload error");
                console.log(error);
            }
        });

    },
    topicHighLight(){
        var t = this;
        t.list = [];
        t.lastPosition = window.scrollY;
        let topics = document.querySelector('aside .LevelsAccordion.content__topics');

        if( topics && topics.querySelectorAll('[data-highlight]').length > 0 ) {
            topics.querySelectorAll('[data-highlight]').forEach( function( single, index ) {
                t.list.push([ single.getAttribute('data-highlight'), 0 ]);
            });
        }
        let options = {
            root: null,
            rootMargin: '-'+(document.querySelector('header').offsetHeight-1)+'px 0px 0px 0px',
            threshold: 1.0
        }
        t.observer = new IntersectionObserver(function( entries ){
            entries.forEach(entry => {
                let topic = topics.querySelector('[data-highlight='+entry.target.getAttribute('id')+']');
                if( topic ) {
                    
                    t.list.forEach( function( row, index ) {
                        if( row[0] == entry.target.getAttribute('id') ) {
                            t.list[index][1] = entry.intersectionRatio;
                        }
                    });
                    
                    let shouldChange = false;
                    
                    t.list.forEach( function( row, index ) {
                        if( row[1] == 1 ) {
                            shouldChange = true;
                        }
                    });

                    if( shouldChange ) {
                        let firstFind = false;
                        t.list.forEach( function( row, index ) {
                            if( topics.querySelector(['[data-highlight='+row[0]+']']) ) {
                                if( row[1] == 1 && firstFind == false ) {
                                    topics.querySelector(['[data-highlight='+row[0]+']']).classList.add('active');
                                    firstFind = true;
                                } else {
                                    topics.querySelector(['[data-highlight='+row[0]+']']).classList.remove('active');
                                }
                            }
                        }); 

                    } else if( t.lastPosition > window.scrollY ) { // if scroll up

                        t.list.forEach( function( row, index ) {
                            if( row[0] == entry.target.getAttribute('id') ) {
                                topics.querySelectorAll(['[data-highlight]']).forEach( function( topic2,index2 ) {
                                    topic.classList.remove('active');
                                });
                                if( index > 0 ) {
                                    topics.querySelector(['[data-highlight='+t.list[index-1][0]+']']).classList.add('active');
                                } else {
                                    topics.querySelector(['[data-highlight='+t.list[0][0]+']']).classList.add('active');
                                }
                            }
                        });
                        
                    }
                    // -- scroll element if is not can see -- //
                        if( topics.querySelector('.active[data-highlight]') && topics.querySelector('.active[data-highlight]').closest('.item__content').style.height != '0px' ) {

                            let topPosition = 0;
                            let scrolledElement = document.querySelector('.aside__content .content__accordion');
                            let activeElement = topics.querySelector('.active[data-highlight]');
                            let activeElementBottom = activeElement.getBoundingClientRect().top + activeElement.offsetHeight - scrolledElement.getBoundingClientRect().top;
                            let activeElementTop = activeElement.getBoundingClientRect().top - scrolledElement.getBoundingClientRect().top;
                            
                            if( activeElementBottom > parseFloat( scrolledElement.offsetHeight ) || activeElementTop < 0 ) {

                                if( activeElementBottom > parseFloat( scrolledElement.offsetHeight ) ) { // scroll down
                                    topPosition = scrolledElement.scrollTop + activeElementBottom - parseFloat( scrolledElement.offsetHeight );
                                    if( topics.querySelectorAll('[data-highlight]')[topics.querySelectorAll('[data-highlight]').length-1].classList.contains('active') ) { // scrolled to last element
                                        
                                        if( document.querySelector('.aside__content .content__resources') ) {
                                            topPosition = 0;
                                            for (let i = 0; i < scrolledElement.children.length; i++) {
                                                if( !scrolledElement.children[i].classList.contains('content__resources') ) {
                                                    topPosition += scrolledElement.children[i].offsetHeight;
                                                }
                                            }
                                            topPosition -= 100;
                                        }

                                    }
                                } else if ( activeElementTop < 0 ) { // scroll up
                                    topPosition = scrolledElement.scrollTop + activeElementTop;
                                    if( topics.querySelectorAll('[data-highlight]')[0].classList.contains('active') ) { // scrolled to first element
                                        topPosition = 0;
                                    }
                                }


                                scrolledElement.scrollTo({ 
                                    top: topPosition,
                                    left: 0, 
                                    behavior: 'smooth'
                                });

                            } else {

                                if( topics.querySelectorAll('[data-highlight]')[0].classList.contains('active') ) { // scrolled to first element
                                    topPosition = 0;
                                    scrolledElement.scrollTo({ 
                                        top: topPosition,
                                        left: 0, 
                                        behavior: 'smooth'
                                    });
                                } else if( topics.querySelectorAll('[data-highlight]')[topics.querySelectorAll('[data-highlight]').length-1].classList.contains('active') ) { // scrolled to last element
                                        
                                    if( document.querySelector('.aside__content .content__resources') ) {
                                        topPosition = 0;
                                        for (let i = 0; i < scrolledElement.children.length; i++) {
                                            if( !scrolledElement.children[i].classList.contains('content__resources') ) {
                                                topPosition += scrolledElement.children[i].offsetHeight;
                                            }
                                        }
                                        scrolledElement.scrollTo({ 
                                            top: topPosition - 100,
                                            left: 0, 
                                            behavior: 'smooth'
                                        });
                                    }

                                }
                            }
                            
                        }
                    // -- END -- //                    

                    t.lastPosition = window.scrollY;

                }
            })
        }, options);

        document.querySelectorAll('.elastic__highlight').forEach( function( element, index ) {
            t.observer.observe( element );
        });            

    },
    finalize() {}
}