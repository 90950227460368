import LazyLoad from '../../../common/scripts/lazyLoad';
import smoothscroll from 'smoothscroll-polyfill';

import FilterSelect2 from '../../Components/FilterSelect2/FilterSelect2';
import ImageLibraryTile from '../../Components/ImageLibraryTile/ImageLibraryTile';
import ShareBox from '../../Components/ShareBox/ShareBox';
    

export default {
    init() {
        var container = document.querySelector('.ImageLibrary');
        if( container ) {
            FilterSelect2.init();
            ImageLibraryTile.init();
            ShareBox.init();
            smoothscroll.polyfill();
            
            var t = this;

            t.node = container;
            t.inputs = new Array();

            // -- search on change -- //
                var searchForm = container.querySelector('.content__search');
                if( searchForm ) {
                    var searchTimeout = null;
                    searchForm.addEventListener('submit', function(e) {
                        e.preventDefault();
                        clearTimeout( searchTimeout );
                        t.startReload();
                    });
                    searchForm.querySelector('input').addEventListener('input', function() {
                        clearTimeout( searchTimeout );
                        searchTimeout = setTimeout(
                            function() {
                                t.startReload();
                            },
                            2000
                        );
                    });
                }
            // -- END -- //


            // -- sort on change -- //
                t.node.querySelector('select[name=orderby]').addEventListener('change', function() {
                    t.startReload();
                });
            // -- END -- //


            // -- display on change -- //
                t.node.querySelector('select[name=posts_per_page]').addEventListener('change', function() {
                    t.startReload();
                });
            // -- END -- //

            // -- content-topis on change -- //
                t.node.querySelectorAll('input[name=content-topic]').forEach( function( input, index ) {
                    input.addEventListener('change', function() {
                        t.startReload();
                    });
                });
            // -- END -- //

            // -- pagination on change -- //
                document.addEventListener('click', function(e) {
                    if( e.target.closest('div.Pagination') ) {
                        t.startReload( e.target.getAttribute('data-number') );
                    }
                });
            // -- END -- //
            
            var urlParams = new URLSearchParams(window.location.search);
            if( urlParams.get('image') ) {
                ImageLibraryTile.loadItem( urlParams.get('image') );
            }
            
        }
    },
    startReload( paged = 1 ) {
        let t = this;
        let newUrl = t.getNewUrl( paged );

        t.reloadContent( newUrl );
        t.setNewUrl( newUrl );
    },
    reloadContent( url ) {
        let t = this;
        t.node.querySelector('.content__reload').classList.add('reload');
        if( url.split('?')[1] ) {
            url = url+'&ajax';
        } else {
            url = url+'?ajax';
        }

        fetch( url, {
            method: "GET",
        })
        .then( response => response.text() )
        .then( function(html) {
            t.node.querySelector('.content__reload').classList.remove('reload');

            var xmlDoc = document.implementation.createHTMLDocument("example");
            xmlDoc.documentElement.innerHTML = html;
            for( let item of t.node.querySelector('.content__reload').children ) { 
                item.remove(); 
            }
            t.node.querySelector('.content__reload').innerHTML = xmlDoc.querySelector('.content__reload').innerHTML;
            window.scrollTo({ 
                top: t.node.querySelector('.content__reload').offsetTop - document.querySelector('header').offsetHeight,
                left: 0, 
                behavior: 'smooth'
            });

            LazyLoad.init();

            t.node.querySelector('.content__reload').classList.remove('reload');

        })
        .catch( function( error ) {
            console.log("ImageLibrary reload page");
            console.log(error);
        });

    },
    getNewUrl( paged = 1 ) {
        var t = this;
        let args = new Array();
        let url = window.location.origin+window.location.pathname;

        // -- orderby -- //
            if( t.node.querySelector('select[name=orderby]') && t.node.querySelector('select[name=orderby]').value ) {
                args.push( 'orderby='+t.node.querySelector('select[name=orderby]').value );
            }
        // -- END -- //

        // -- posts_per_page -- //
            if( t.node.querySelector('select[name=posts_per_page]') && t.node.querySelector('select[name=posts_per_page]').value ) {
                args.push( 'posts_per_page='+t.node.querySelector('select[name=posts_per_page]').value );
            }
        // -- END -- //

        // -- search -- //
            if( t.node.querySelector('.ImageLibraryHeader input[name=search]') && t.node.querySelector('.ImageLibraryHeader input[name=search]').value ) {
                args.push( 'search='+t.node.querySelector('.ImageLibraryHeader input[name=search]').value );
            }
        // -- END -- //

        // -- categories -- //
            if( t.node.querySelectorAll('input[name=content-topic]').length > 0 && t.node.querySelectorAll('input[name=content-topic]:checked').length > 0 ) {
                let topics = new Array();
                t.node.querySelectorAll('input[name=content-topic]:checked').forEach( function( input, index ) {
                    topics.push( input.value );
                });
                args.push( 'content-topic='+topics.join(',') );
            }
        // -- END -- //
        
        // -- page_num -- //
            if( paged > 1 ) {
                args.push('page_num='+paged);
            }
        // -- END -- //

        if( args.length > 0 ) {
            return url + "?"+ args.join('&');
        } else {
            return url;
        }

    },
    setNewUrl( url ) {
        window.history.pushState( "", "", url );
    },
    finalize() {}
}