import LanguageSelect from '../../Components/LanguageSelect/LanguageSelect';
export default {
    init() {
        var container = document.querySelector('header.Header');
        if( container ) {

            LanguageSelect.init();

            var t = this;
            var headerTimer = null;
            var navItems = container.querySelectorAll('.bottom__nav .nav__item');
            var subNav = container.querySelectorAll('.Header__sub .sub__nav');

            // -- nav item hover + subnav -- //
                navItems.forEach( function( item, index ) {

                    item.addEventListener('mouseenter', function() {
                        clearTimeout( headerTimer );
                        headerTimer = null;
                        navItems.forEach( function( item2, index2 ) {
                            if( index == index2 ) {
                                item2.classList.add('hover');
                            } else {
                                item2.classList.remove('hover');
                            }
                        });
                        subNav.forEach( function( box,index ) {
                            if( item.hasAttribute('data-id') && box.getAttribute('data-id') == item.getAttribute('data-id') ) {
                                box.classList.add('active');
                            } else {
                                box.classList.remove('active');
                            }
                        });
                    });
                    item.addEventListener('mouseleave', function() {
                        clearTimeout( headerTimer );
                        headerTimer = null;
                        headerTimer = setTimeout(
                            function() {
                                item.classList.remove('hover');
                                subNav.forEach( function( nav, index ) {
                                    nav.classList.remove('active');
                                });
                            },
                            200
                        )
                    });
                });

                subNav.forEach( function( nav, index ) {

                    nav.addEventListener('mouseenter', function() {
                        clearTimeout( headerTimer );
                        subNav.forEach( function( nav2,index2 ) {
                            if( index2 == index ) {
                                nav2.classList.add('active');
                            } else {
                                nav2.classList.remove('active');
                            }
                        });

                        navItems.forEach( function( item, index ) {
                            if( item.getAttribute('data-id') == nav.getAttribute('data-id') ) {
                                item.classList.add('hover');
                            } else {
                                item.classList.remove('hover');
                            }
                        });

                    });

                    nav.addEventListener('mouseleave', function() {
                        clearTimeout( headerTimer );
                        headerTimer = setTimeout(
                            function() {
                                nav.classList.remove('active');
                                navItems.forEach( function( item, index ) {
                                    item.classList.remove('hover');
                                });
                            },
                            200
                        );
                    });

                });
            // -- END -- //

            // -- subnav item description -- //
                subNav.forEach( function( nav, index ) {
                    nav.querySelectorAll('.nav__descriptions .content__box').forEach( function( box, index ) {
                        nav.querySelector('.items__link[data-desc-id="'+box.getAttribute('data-desc-id')+'"]').addEventListener('mouseenter', function() {
                            box.classList.add('active');
                        });
                        nav.querySelector('.items__link[data-desc-id="'+box.getAttribute('data-desc-id')+'"]').addEventListener('mouseleave', function() {
                            box.classList.remove('active');
                        });
                    });
                });
            // -- END -- //

            // -- search -- //
                var searchForm = container.querySelector('.bottom__search');
                if( searchForm ) {
                    var searchInput = searchForm.querySelector('input');
                    var searchBtn = searchForm.querySelector('button');
                    searchBtn.addEventListener('click', function() {
                        if( searchBtn.classList.contains( 'active' ) ) {
                            if( searchInput.value ) {
                                searchForm.submit();
                            } else {
                                searchBtn.classList.remove('active');
                            }
                        } else {
                            searchBtn.classList.add('active');
                        }
                    });
                    document.addEventListener('click', function( e ) {
                        if( !e.target.closest('.bottom__search') && searchBtn.classList.contains('active') ) {
                            searchBtn.classList.remove('active');
                        }
                    });
                }
            // -- END -- //

            t.setSubNavDescriptionHeight();
            t.setSubNavHeight();
            window.addEventListener('resize', function() {
                t.setSubNavDescriptionHeight();
                t.setSubNavHeight();
            });

        }
    },
    setSubNavDescriptionHeight() {
        let subNavItems = document.querySelectorAll('.Header__sub .sub__nav');
        let temp = 0;
        subNavItems.forEach( function( nav, index ) {
            temp = 0;
            nav.querySelectorAll('.content__box').forEach( function( box, index ) {
                if( temp == 0 || temp < box.offsetHeight ) {
                    temp = box.offsetHeight;
                }
            });
            if( temp != 0 ) {
                nav.querySelector('.descriptions__content').style.height = temp + 'px';
            }
        });
    },
    setSubNavHeight() {
        let subNav = document.querySelector('.Header__sub');
        if( subNav ) {
            let subNavItems = subNav.querySelectorAll('.sub__nav');
            let temp = 0;
            subNavItems.forEach( function( nav, index ) {
                if( temp < nav.offsetHeight || temp == 0 ) {
                    temp = nav.offsetHeight;
                }
            });
            subNav.style.height = temp + 'px';
        }

    },
    finalize() {}
}