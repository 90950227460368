export default {
    init() {
        var t = this;
        document.querySelectorAll('.PeopleSection').forEach( function( container, index ) {
            t.finalize( container );
        });
    },
    finalize( container ) {
        // -- person bio display/hide -- //
			container.querySelectorAll('.info__button').forEach( function( button, index ) {
				button.addEventListener('click', function() {
					button.classList.toggle('active');
				});
			});
		// -- END -- //
    }
}