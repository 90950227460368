export default {
    init() {
        var t = this;
        document.querySelectorAll('.CustomInputRange').forEach( function( container, index ) {
            t.finalize( container );
        });
    },
    finalize( container ) {
        var t = this;
        var input = container.querySelector('input[type=range]');
        var points = container.querySelectorAll('.box__point');
        var pointsBig = container.querySelectorAll('.box__point--big');
        var range = [];
        var mouseDown = false;
        pointsBig.forEach( function( item, index ) {
            range.push( parseInt(item.getAttribute('data-range')) );
        });

        input.addEventListener('mousemove', function(e) {
            let value = (e.offsetX / e.target.clientWidth) *  parseInt(e.target.getAttribute('max'),10);

            if( !mouseDown ) {
                pointsBig.forEach( function( point,index ) {
                    if( parseFloat(value.toFixed(0)) == parseInt( point.getAttribute('data-range') ) ) {
                        point.classList.add('display');
                    } else {
                        point.classList.remove('display');
                    }
                });
            } else {
                pointsBig.forEach( function( point,index ) {
                    point.classList.remove('display');
                });
            }
        });
        input.addEventListener('mouseleave', function() {
            pointsBig.forEach( function( point,index ) {
                point.classList.remove('display');
            });
        });
        input.addEventListener('mousedown', function() {
            mouseDown = true;
        });
        input.addEventListener('mouseup', function() {
            mouseDown = false;
            t.roundValue( input, input.value, range );
            t.paintPoints( points, input.value );
        });
        input.addEventListener('input', function() {
            if( mouseDown == false ) {
                t.roundValue( input, input.value, range );
            }
            t.paintPoints( points, input.value );    
        });
        pointsBig.forEach( function( point,index ) {
            point.addEventListener('click', function() {
                input.value = parseInt( point.getAttribute('data-range') );
                t.roundValue( input, input.value, range );
                t.paintPoints( points, input.value );
            });
        });

    },
    paintPoints( points, value ) {
        points.forEach( function( point,index ) {   
        //     if( index <= value ) {
        //         point.classList.add('fill');
        //     } else {
        //         point.classList.remove('fill');
        //     }
            
            if( point.classList.contains('box__point--big') ) {
                if( index == value ) { 
                    point.classList.add('active');
                } else {
                    point.classList.remove('active');
                }    
            }
        });
    },
    roundValue( input, value, range = [] ) {
        let tempMin = null;
        let tempValue = null;
        range.forEach( function( checkValue, index ) {            
            if( tempMin == null || Math.abs(checkValue - value) <= tempMin ) {
                tempMin = Math.abs(checkValue - value);
                tempValue = checkValue;
            }

        });
        input.value = tempValue;
    }
}