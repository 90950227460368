export default {

	init() {

		var container = document.querySelector('.DDI');

		if( container ) {

			var timeout;

			document.addEventListener('click', e => {

				if( e.target.classList.contains('DDI__button') ) {

					if( e.target.classList.contains('clicked') ) {

						e.target.classList.remove('clicked');

						if( e.target.closest('.DDI').querySelector('.DDI__description') ) {

							e.target.closest('.DDI').querySelector('.DDI__description').classList.add('shortText');

						}

						e.target.closest('.DDI').querySelectorAll('.DDI__file').forEach( function( file,index ) {

							if( index >= 1 ) { file.classList.add('hide'); }

						});

					} else {

						e.target.classList.add('clicked');

						if( e.target.closest('.DDI').querySelector('.DDI__description') ) {

							e.target.closest('.DDI').querySelector('.DDI__description').classList.remove('shortText');
							
						}

						e.target.closest('.DDI').querySelectorAll('.DDI__file.hide').forEach( function( file,index ) {

							file.classList.remove('hide');

						});

					}

				}
				if( e.target.classList.contains('DDI__icon--share') ) {
					clearTimeout( timeout );
					timeout = setTimeout( 
						function() {
							e.target.classList.remove('active');
						},
						1200
					);
					e.target.classList.add('active');

					let element = document.createElement('input');
					element.value = e.target.getAttribute('data-url').replaceAll(' ','%20');
					element.setAttribute('readonly','');
					element.style.position = 'absolute';
					element.style.left = '-99999px';

					document.body.appendChild( element );

					element.select();
					document.execCommand('copy');
					document.body.removeChild(element);

				}

			});

		}

	},
	finalize() {

	}

}