import LazyLoad from '../../../common/scripts/lazyLoad';
import ImageLibraryItem from '../../Components/ImageLibraryItem/ImageLibraryItem';
import ImageLibraryDownloadList from '../../Components/ImageLibraryDownloadList/ImageLibraryDownloadList';
export default {
    init() {
        var t = this;
        ImageLibraryItem.init();
        ImageLibraryDownloadList.init();

        document.addEventListener('click', function(e) {
            if( !e.target.classList.contains('ImageLibraryTile__download') && e.target.closest('.ImageLibraryTile') ) {
                t.loadItem( e.target.closest('.ImageLibraryTile').getAttribute('data-id') );
            }
        });
    },
    loadItem( resourceID = null ) {
        var t = this;
        if( resourceID ) {
            document.body.classList.add('disableScroll');
            if( !document.querySelector('.ImageLibraryItem--mask') ) {
                t.renderMask();
            } else {
                document.querySelector('.ImageLibraryItem--mask .row').innerHTML = '';
            }
            
            fetch( window.location.origin+'/wp-admin/admin-ajax.php?action=GetItemLibraryItem&ID='+resourceID, {
                method: "GET",
            })
            .then( response => response.text() )
            .then( function(response) {
                if( document.querySelector('.ImageLibraryItem--mask') ) {
                    if( response ) {
                        document.querySelector('.ImageLibraryItem--mask .ImageLibraryItem__layout .row').insertAdjacentHTML('beforeend', response);
                        LazyLoad.init();
                        
                    } else {
                        document.querySelector('.ImageLibraryItem--mask.active').classList.remove('acrive');
                        setTimeout(() => {
                            document.querySelector('.ImageLibraryItem--mask').remove();
                        }, 200);
                    }
                }
            })
            .catch( function( error ) {
                console.log("ImageLibraryItem reload error");
                console.log(error);
            });
        }
    },
    renderMask() {
        document.body.insertAdjacentHTML('beforeend', '<div class="ImageLibraryItem--mask"><div class="ImageLibraryItem__layout"><div class="row"></div></div></div>');
        setTimeout(() => {
            document.querySelector('.ImageLibraryItem--mask').classList.add('active');
        }, 200);
            
    },
    finalize() {}
}