export default {
	init() {
		var filters = document.querySelectorAll('.FilterCheckboxsList');
		var filtersTimeout = null;
		if( filters.length > 0 ) {

			filters.forEach( function( single, index ) {

				// single.addEventListener('click', function( e ) {
				// 	console.log( e.target );
				// });

				if( !single.classList.contains('collapsed') ) {
					single.querySelector('.FilterCheckboxsList__items').style.height = single.querySelector('.FilterCheckboxsList__items--absolute').offsetHeight + 'px';
					single.querySelector('.FilterCheckboxsList__items--absolute').style.position = 'absolute';
				}

				single.querySelector('button.FilterLabel').addEventListener('click', function( e ) {
					if( e.target.classList.contains('tooltip__button') || e.target.classList.contains('FilterLabel__select') ) {
						return;
					}

					if( e.target.classList.contains('clicked') ) {
						single.querySelector('.FilterCheckboxsList__items').style.height = '0px';
						e.target.classList.remove('clicked');
					} else {
						single.querySelector('.FilterCheckboxsList__items').style.height = single.querySelector('.FilterCheckboxsList__items--absolute').offsetHeight + 'px';
						e.target.classList.add('clicked');
					}

				});

				if( single.querySelector('.FilterLabel__select') ) {
					single.querySelector('.FilterLabel__select').addEventListener('click', function() {
						if( single.querySelector('.FilterLabel__select').classList.contains('clicked') ) {
							single.querySelector('.FilterLabel__select').classList.remove('clicked');
							single.querySelectorAll('input[type="checkbox"][data-level="0"]').forEach( function( input, index2 ) {
								input.closest('.items__single').classList.remove('active');
								input.checked = false;
							});
						} else {
							single.querySelector('.FilterLabel__select').classList.add('clicked');
							single.querySelectorAll('input[type="checkbox"][data-level="0"]').forEach( function( input, index2 ) {
								input.closest('.items__single').classList.add('active');
								input.checked = true;
							});
						}
						single.querySelector('.FilterCheckboxsList__items').style.height = single.querySelector('.FilterCheckboxsList__items--absolute').offsetHeight + 'px';
					});
				}

				single.querySelectorAll('input[type="checkbox"]').forEach( function( checkbox, index2 ) {

					checkbox.addEventListener('change', function() {

						if( checkbox.closest('.FilterCheckboxsList__items--all-selected') ) {
							checkbox.closest('.FilterCheckboxsList__items--all-selected').classList.remove('FilterCheckboxsList__items--all-selected');

							single.querySelectorAll('input[type="checkbox"][data-level="0"]').forEach( function( checkbox3, index3 ) {
								checkbox3.checked = false;
							});
							checkbox.checked = true;
						}

						if( checkbox.closest('.items__sublist--all-selected') ) {
							checkbox.closest('.items__sublist--all-selected').classList.remove('items__sublist--all-selected');
							single.querySelectorAll('input[type="checkbox"][data-level="1"]').forEach( function( checkbox3, index3 ) {
								checkbox3.checked = false;
							});
							checkbox.checked = true;
						}

						if( single.querySelectorAll('input[type="checkbox"]:checked').length == single.querySelectorAll('input[type="checkbox"]').length ) {
							single.querySelector('.FilterLabel__select').classList.add('clicked');
						} else {
							single.querySelector('.FilterLabel__select').classList.remove('clicked');
						}

						if( checkbox.checked ) {
							checkbox.closest('.items__single').classList.add('active');
						} else {
							checkbox.closest('.items__single').classList.remove('active');
						}
						// if( !single.classList.contains('collapsed') ) {
							single.querySelector('.FilterCheckboxsList__items').style.height = single.querySelector('.FilterCheckboxsList__items--absolute').offsetHeight + 'px';
						// }

					});

				});

			});

			window.addEventListener('resize', function() {
				filters.forEach( function( single, index ) {
					if( !single.classList.contains('collapsed') ) {

						single.querySelector('.FilterCheckboxsList__items').style.height = single.querySelector('.FilterCheckboxsList__items--absolute').offsetHeight + 'px';
					}
				});

				clearTimeout(filtersTimeout);
				filtersTimeout = setTimeout(
					function() {
						filters.forEach( function( single, index ) {
							if( !single.classList.contains('collapsed') ) {

								single.querySelector('.FilterCheckboxsList__items').style.height = single.querySelector('.FilterCheckboxsList__items--absolute').offsetHeight + 'px';
							}
						});
					},
					200
				);

			});

		}
	},
	finalize() {

	}
}