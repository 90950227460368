import FilterCheckboxsList from '../../Components/FilterCheckboxsList/FilterCheckboxsList.js';
import FilterSearchSelect from '../../Components/FilterSearchSelect/FilterSearchSelect.js';
import ResourcesItem from '../../Components/ResourcesItem/ResourcesItem.js';
import FilterSelect from '../../Components/FilterSelect/FilterSelect.js';
import ShareButton from '../../Components/ShareButton/ShareButton';
import FaqItem from '../../Components/FaqItem/FaqItem.js';
import Subpage from '../../Layout/Subpage';
import AlgoliaResults from '../../Components/AlgoliaResults/AlgoliaResults';
import ImageLibraryHeader from '../../Sections/ImageLibraryHeader/ImageLibraryHeader';

import LazyLoad from '../../../common/scripts/lazyLoad';
import $ from 'jquery';

export default {
	init() {
		var container = document.querySelector('main.ResourcesNew');
		if( container ) {
			var t = this;

			this.controller = new AbortController();
			this.signal = this.controller.signal;

			this.controllerForNumbers = new AbortController();
    		this.signalForNumbers = this.controllerForNumbers.signal;

    		this.node = container;

			FilterCheckboxsList.init();
			FilterSearchSelect.init();
			ResourcesItem.init();
			FilterSelect.init();
			ShareButton.init();
			FaqItem.init();
			ImageLibraryHeader.init();

			t.loadMoreItems();
			t.orderbyValueChange();
			t.asideFiltersChange();
			t.resetAllFilters();
			t.mobileTooltip();
			t.appliedFiltersButtons();
			t.mobileFilters();

			t.updateNumbers();


			if( 
				(container.hasAttribute('data-algolia-hint') && container.getAttribute('data-algolia-hint') == '1' ) ||
				!container.hasAttribute('data-algolia-hint')
			) {
				t.algolia();
			}
		}
	},
	mobileFilters() {
		var t = this;
		var filters = t.node.querySelector('.ResourcesNew__row .row__filters');
		if( filters ) {

			filters.addEventListener('click', function() {
				filters.classList.remove('active');
				document.body.classList.remove('disableScroll');
			});
			filters.querySelector('.filters__close').addEventListener('click', function() {
				filters.classList.remove('active');
				document.body.classList.remove('disableScroll');
			});
			filters.querySelector('.filters__content').addEventListener('click', function(e){
				e.stopPropagation();
			});

			document.addEventListener('click', function(e) {
				if( e.target.classList.contains('mobileFilters') ) {
					if( filters.classList.contains('active') ) {
						filters.classList.remove('active');
						document.body.classList.remove('disableScroll');
					} else {
						filters.classList.add('active');
						document.body.classList.add('disableScroll');
					}
				}
			})

			window.addEventListener('resize', function() {

				if( window.innerWidth > 1120 && document.body.classList.contains('disableScroll') ) {
					document.body.classList.remove('disableScroll');
				}
				if( filters.classList.contains('active') && window.innerWidth <= 1120 && !document.body.classList.contains('disableScroll') ) {
					document.body.classList.add('disableScroll');
				}

			});	
		}
	},
	appliedFiltersButtons() {
		var t = this;
		document.addEventListener('click', function(e) {
			if( e.target.classList.contains('layout__button') ) {
				t.node.querySelector('input[type="checkbox"][value="'+e.target.getAttribute('data-slug')+'"][name="'+e.target.getAttribute('data-tax')+'"]').click();
				e.target.remove();
			}
			if( e.target.classList.contains('refresh__filters--collapsed') ) {

				if( e.target.classList.contains('clicked') ) {
					e.target.classList.remove('clicked');
					t.node.querySelector('.refresh__filters .filters__layout').style.height = '0px';
				} else {
					e.target.classList.add('clicked');
					t.node.querySelector('.refresh__filters .filters__layout').style.height = t.node.querySelector('.refresh__filters .filters__layout--absolute').offsetHeight + 'px';
				}
			}
		});

		if( t.node.querySelector('.refresh__filters .filters__layout') && t.node.querySelector('.refresh__filters .filters__layout--absolute') ) {
			t.node.querySelector('.refresh__filters .filters__layout').style.height = t.node.querySelector('.refresh__filters .filters__layout--absolute').offsetHeight + 'px';	
			t.node.querySelector('.refresh__filters .filters__layout--absolute').style.position = 'absolute';
		}		
	},
	mobileTooltip() {
		var t = this;
		if( Subpage.isTouchDevice() ) {

			var buttons = t.node.querySelectorAll('.tooltip__button');
			var mask = t.node.querySelector('.ResourcesNew__tooltip');
			buttons.forEach( function( button, index ) {

				button.addEventListener('click', function() {
					mask.classList.add('active');
					mask.querySelector('.tooltip__content[data-name="'+button.getAttribute('data-name')+'"]').classList.add('active');
				});

			});

			mask.querySelector('.tooltip__close').addEventListener('click', function() {
				mask.classList.remove('active');
				mask.querySelectorAll('.tooltip__content').forEach( function( single,index ) {
					single.classList.remove('active');
				});
			});

		}
	},
	orderbyValueChange() {
		var t = this;
		var orderby = this.node.querySelectorAll('input[type="radio"][name="orderby"]');
		orderby.forEach( function( radio, index ) {
			radio.addEventListener('change', function(){
				t.refreshContent();
			});
		});
	},
	asideFiltersChange() {
		var t = this;
		var filtersTimeout;
		var filters = t.node.querySelectorAll('aside.row__filters input[type="checkbox"]');
		filters.forEach( function( checkbox, index ) {
			checkbox.addEventListener('change', function() {
				clearTimeout(filtersTimeout);
				filtersTimeout = setTimeout(
					function() {
						t.refreshContent();
					},
					500
				);

			});
		});
		var selectAll = t.node.querySelectorAll('.FilterLabel__select').forEach( function( selectButton, index ) {
			selectButton.addEventListener('click', function() {
				t.refreshContent();
			});
		});
	},
	generateAjaxURL( paged = 1 ) {
		var t = this;
		let basic = window.location.origin + window.location.pathname;

		let filters = t.node.querySelectorAll('aside.row__filters input[type="checkbox"]:checked');
		let orderby = t.node.querySelector('input[type="radio"][name="orderby"]:checked');
		let search = t.node.querySelector('input[name="search"]');
		let hidden = t.node.querySelectorAll('.filters__content input.content__hidden');

		let params = [];

		let tempName = null;
		let tempParams = [];

		params.push('ajax=true');
		params.push('pagenumber='+paged);

		if( search.value ) {
			params.push('search='+search.value.replaceAll(' ','%20'));
		}

		if( orderby ) { 
			params.push('orderby='+orderby.value);
		}

		filters.forEach( function( single, index ) {
			if( tempName === null ) {
				tempName = single.getAttribute('name');
			}

			if( tempName != single.getAttribute('name') ) {
				params.push(tempName+'='+tempParams.join(','));
				tempName = single.getAttribute('name');
				tempParams = [];
			} 
			tempParams.push(single.value);
			
			if( index == filters.length-1 ) {
				params.push(tempName+'='+tempParams.join(','));
			}

		});
		hidden.forEach( (input, index) => {
			params.push( input.getAttribute('name') + '='+input.value );
		});

		console.log( params );
		if( params.length > 0 ) {
			basic += '?'+params.join('&')
		}
		return basic;
	},
	loadMoreItems() {
		var t = this;
		document.addEventListener('click', function( e ) {

			if( e.target.classList.contains('Button--more') ) {

				let paged = e.target.closest('.refresh__item--more').getAttribute('data-paged');
				e.target.closest('.refresh__item--more').remove();

				fetch( t.generateAjaxURL( paged ), {
	                method: 'get',
	            }).then(res => res.text()).then(response => {

	            	var html = document.createElement('div');
	            	html.innerHTML = response;
	            	html = html.querySelector('.refresh__items--resources').innerHTML;

				    t.node.querySelector('.results__refresh .refresh__items--resources').insertAdjacentHTML('beforeend', html);
				    LazyLoad.init();

				}).catch(function(err) {
	                //console.error(` Err: ${err}`);
	            });

			}
		});
	},
	refreshContent() {
		var t = this;
		// t.removeFaqContentOnRefresh();
		t.updateNumbers();

		this.controller.abort();

		this.controller = new AbortController();
		this.signal = this.controller.signal;

        fetch( t.generateAjaxURL(), {
            method: 'get',
            signal: this.signal,
        }).then(res => res.text()).then(response => {

        	t.node.querySelector('.results__refresh').innerHTML = response;
		    LazyLoad.init();

		    if( t.node.querySelector('.refresh__items--resources .refresh__filters') ) {
		    	t.node.querySelector('.refresh__filters .filters__layout').style.height = t.node.querySelector('.refresh__filters .filters__layout--absolute').offsetHeight + 'px';
		    	t.node.querySelector('.refresh__filters .filters__layout--absolute').style.position = 'absolute';
		    }

		}).catch(function(err) {
            //console.error(` Err: ${err}`);
        });
	},
	updateNumbers() {
		var t = this;
		var itemsToRecalc = t.node.querySelectorAll('.items__single--recalc');
		var filters = t.node.querySelectorAll('aside.row__filters input[type="checkbox"]:checked');
		let hidden = t.node.querySelectorAll('.filters__content input.content__hidden');

		let params = {};

		itemsToRecalc.forEach( function( item, index ) {

			let param = {};

			let input = item.querySelector('input[type="checkbox"]');
			let excludeFilter = input.getAttribute('name');
			let excludeValue = input.value;

			param[excludeFilter] = excludeValue;

			filters.forEach( function( checkbox ,index ) {

				if( checkbox.getAttribute('name') != excludeFilter ) {

					if( param[checkbox.getAttribute('name')] ) {
						param[checkbox.getAttribute('name')] += ',';
						param[checkbox.getAttribute('name')] += checkbox.value;
					} else {
						param[checkbox.getAttribute('name')] = checkbox.value;	
					}
					
				}

			});
			hidden.forEach( (input, index) => {
				param[ input.getAttribute('name') ] = input.value;
			});

			params[excludeValue] = param;

		});

		t.controllerForNumbers.abort();
		t.controllerForNumbers = new AbortController();
		t.signalForNumbers = t.controllerForNumbers.signal;

		fetch(document.querySelector('footer').getAttribute('data-endpoint') + '?action=countPosts', {
	        method: "post",
	        signal: t.signalForNumbers,
	        headers: {
	        	'Content-Type': 'application/json'
		    },
	        body: JSON.stringify(params)
	    })
	    .then(res => res.text()).then(response => {

	    	var obj = JSON.parse(response);
	    	for( const key in obj ) {
				t.node.querySelectorAll('input[value="'+key+'"]').forEach( ( input, index ) => {
					input.closest('.items__single--recalc').querySelector('.single__content').setAttribute('data-number', obj[key] );
				});
	    		// t.node.querySelector('input[value="'+key+'"]').closest('.items__single--recalc').querySelector('.single__content').setAttribute('data-number', obj[key] );
	    	}

		}).catch(function(err) {
            //console.error(` Err: ${err}`);
        });
	},
	removeFaqContentOnRefresh() {
		var t = this;
		if( t.node.querySelectorAll('.refresh__typeName').length > 0 ) {
			t.node.querySelectorAll('.refresh__typeName').forEach( function( heading, index ) {
				heading.remove();
			});
		}
		if( t.node.querySelector('.refresh__items--faq') ) {
			t.node.querySelector('.refresh__items--faq').remove();
		}
	},
	resetAllFilters() {
		var t = this;

		if( document.querySelector('.resetAllFilters') ) {
			document.querySelector('.resetAllFilters').addEventListener('click', function() {

				t.node.querySelectorAll('.FilterLabel__select').forEach( function( selectButton, index ) {
					if( selectButton.classList.contains('clicked') ) {
						selectButton.classList.remove('clicked');
					}
				});	

				t.node.querySelector('.filters__layout').style.height = '0px';
				t.node.querySelectorAll('.layout__button').forEach( function( button, index2 ) {
					button.remove();
				});

				t.node.querySelectorAll('aside.row__filters input[type="checkbox"]').forEach( function( checkbox, index ) {
					checkbox.checked = false;					
				});

				t.refreshContent();
			});
		}

		document.addEventListener('click', function(e) {

			if( e.target.classList.contains('resetAllFilters') ) {

				t.node.querySelectorAll('.FilterLabel__select').forEach( function( selectButton, index ) {
					if( selectButton.classList.contains('clicked') ) {
						selectButton.classList.remove('clicked');
					}
				});	

				t.node.querySelector('.filters__layout').style.height = '0px';
				t.node.querySelectorAll('.layout__button').forEach( function( button, index2 ) {
					button.remove();
				});

				t.node.querySelectorAll('aside.row__filters input[type="checkbox"]').forEach( function( checkbox, index ) {
					checkbox.checked = false;					
				});

				t.refreshContent();
			}
		});
	},
	algolia() {
		var obj = new AlgoliaResults({
			'listener' : document.querySelector('.ImageLibraryHeader input[name="search"]'),
			'elementToAppend' : document.querySelector('.ImageLibraryHeader form.content__search'),
			'indexName' : 'cpt_resources',
		});
	}
}