export default {
    init() {
        let t = this;
        document.querySelectorAll('.FixedTopLinkBatch').forEach( ( container, index ) => {
            t.finalize( container );
        });
    },
    finalize( container ) {
        if( document.body.classList.contains('InteractiveDataDashboard') ) {

            window.addEventListener('scroll', () => {
                if( document.querySelector('.IDDItems').offsetTop < window.scrollY ) {
                    container.classList.add('FixedTopLinkBatch--hidden');
                } else {
                    container.classList.remove('FixedTopLinkBatch--hidden');
                }
            });

        }
    }
}