export default {
	init() {
		document.addEventListener('click', function(e) {
			document.querySelectorAll('.FilterSelect').forEach( function( single, index ) {

				if( 
					!e.target.closest('.FilterSelect') || 
					( e.target.closest('.FilterSelect') && e.target.closest('.FilterSelect').getAttribute('data-id') != single.getAttribute('data-id') ) ||
					( e.target.closest('.FilterSelect') && e.target.closest('.FilterSelect').getAttribute('data-id') == single.getAttribute('data-id') && !e.target.classList.contains('content__button') )
				) {
					single.querySelector('.content__button').classList.remove('active');
				}

			});
			if( e.target.closest('.FilterSelect') ) {
				if( e.target.classList.contains('content__button') ) {
					e.target.classList.toggle('active');
				}
			}
		});
		document.addEventListener('change', function(e) {
			if( e.target.getAttribute('type') == 'radio' && e.target.closest('.FilterSelect') ) {
				let container = e.target.closest('.FilterSelect');
				container.querySelector('.content__button span').textContent = e.target.getAttribute('data-label');
				container.querySelector('.content__button').classList.remove('active');
			}
		});
	},
	finalize() {}
}