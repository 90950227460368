import LazyLoad from '../../../common/scripts/lazyLoad';
import $ from 'jquery';
import ResourcesItem from '../../Components/ResourcesItem/ResourcesItem.js';

export default {

	init() {
		ResourcesItem.init();

		let controller = new AbortController();
    	let signal = controller.signal;

    	let controllerForNumbers = new AbortController();
    	let signalForNumbers = controllerForNumbers.signal;

    	var filtersButtons = document.querySelectorAll('.filter__button');


		// -- filter -- //

			filtersButtons.forEach( function( button,index ) {

				button.addEventListener('click', function() {

					if( button.parentNode.classList.contains('allActive') ) {

						button.parentNode.classList.remove('allActive');

					}

					if( button.classList.contains('active') ) {

						button.classList.remove('active');

					} else {
			
						button.classList.add('active');

					}

					refreshContent();
					updateNumbers();

				});

			});

		// -- END -- //



		// -- Select order -- //

			$(document).on('click', '.order__select', function(e) {

				e.stopPropagation();

				if( $(this).hasClass('active') ) {

					$(this).removeClass('active');

				} else {

					$(this).addClass('active');

				}

			});

			$(document).on('click', '.select__button', function(e) {

				$('.select__value span').text( $(this).text() );

				$('.select__button').removeClass('clicked');
				$(this).addClass('clicked');

				refreshContent();

			});

			$(document).on('click', function() {

				if( $('.order__select').hasClass('active') ) {

					$('.order__select').removeClass('active');

				}

			});

		// -- END -- //


		// -- search -- //

			var form = document.querySelector('.heading__search');
			if( form ) {

				var formInput = form.querySelector('input[name="search"]');
				form.addEventListener('submit', function(e) {
					e.preventDefault();
					refreshContent();
					updateNumbers();

				});

			}

		// -- END -- //


		$(document).on('click', '.Resources__item--more button', function(e){

			let showMoreButton = $(this).parent();
			showMoreButton.remove();

            fetch(generateAjaxURL(showMoreButton.data("paged")), {
                method: 'get',
            }).then(res => res.text()).then(response => {
			    $('.Resources__items-list').append(response);
			    LazyLoad.init();
			}).catch(function(err) {
                //console.error(` Err: ${err}`);
            });
		});

		function updateNumbers() {

			let counters = {};

			filtersButtons.forEach( function( button, index ) {

				if( button.getAttribute('data-filter') == 'content-topic' ) {

					let excludeFilter = button.getAttribute('data-filter');
					let excludeFilterValue = button.getAttribute('data-slug');

					let params = {};
					params[excludeFilter] = excludeFilterValue;

					$(".filter__button[data-filter!='"+excludeFilter+"'].active").each( function( index1, button1 ) {

						if(params[$(this).data("filter")]) {

							params[$(this).data("filter")] += ',';
							params[$(this).data("filter")] += $(this).data("slug");

						} else {

							params[$(this).data("filter")] = $(this).data("slug");

						}

					});

					if( document.querySelector('input[name="search"]') ) {
						params['search'] = document.querySelector('input[name="search"]').value;
					}

					counters[excludeFilterValue] = params;

				}

			});

			controllerForNumbers.abort();

			controllerForNumbers = new AbortController();
			signalForNumbers = controllerForNumbers.signal;

			
			fetch(document.querySelector('footer').getAttribute('data-endpoint') + '?action=countPosts', {
		        method: "post",
		        signal: signalForNumbers,
		        headers: {
		        	'Content-Type': 'application/json'
			    },
		        body: JSON.stringify(counters)
		    })
		    .then(res => res.text()).then(response => {

		    	var obj = JSON.parse(response);
		    	
			    $('.filter__topic button').each(function() {

			    	$(this).find('span').text( obj[$(this).data("slug")] );

			    });

			}).catch(function(err) {
                //console.error(` Err: ${err}`);
            });
            

		}

		function generateAjaxURL(paged = 1) {

			var params = [];			
			var verifiedResource = [];
			var contentTopics = [];
			var contentLanguages = [];

			var ajaxURL = window.location.origin + window.location.pathname;

			filtersButtons.forEach( function( button,index ) {

				if( button.getAttribute('data-filter') == 'content-lang' ) {

					if( button.classList.contains('active') || button.parentNode.classList.contains('allActive') ) {

						contentLanguages.push( button.getAttribute('data-slug') );

					}

				} else if( button.getAttribute('data-filter') == 'content-topic' ) {

					if( button.classList.contains('active') || button.parentNode.classList.contains('allActive') ) {

						contentTopics.push( button.getAttribute('data-slug') );

					}

				} else if( button.getAttribute('data-filter') == 'verified_resource' ) {

					if( button.classList.contains('active') ) {

						verifiedResource.push( button.getAttribute('data-slug') );

					}

				}

			});

			if( contentLanguages.length > 0) {

				params.push('content-lang='+ contentLanguages.join(',') );

			}

			if( contentTopics.length > 0) {

				params.push('content-topic='+ contentTopics.join(',') );

			}

			if( verifiedResource.length > 0) {

				params.push('verified_resource='+ verifiedResource.join(',') );

			}

			if( $('.select__button.clicked') ) {

				params.push('orderby=' + $('.select__button.clicked').attr('data-value') );

			}

			if( form && formInput.value ) {
				params.push('search='+ formInput.value );
			}

			if( paged > 1) {
				params.push('pagenumber='+ paged );
			}

			params.push('ajax=true' );

			if( params.length > 0 ) {

				ajaxURL = ajaxURL+'?'+params.join('&');

			}

			return ajaxURL;

		}

		function refreshContent() {

			controller.abort();

			controller = new AbortController();
			signal = controller.signal;

            fetch(generateAjaxURL(), {
                method: 'get',
                signal: signal,
            }).then(res => res.text()).then(response => {

			    $('.Resources__items-list').empty().append(response);
			    LazyLoad.init();

			    if( form && formInput.value ) {
			    	//ga('send', 'pageview', window.location.pathname+'?search='+formInput.value+'&sc=covid-resources');
			    	gtag('event', 'page_view', {page_location: window.location.pathname+'?search='+formInput.value+'&sc=covid-resources'});
				}	

			}).catch(function(err) {
				if( document.body.classList.contains('logged-in') ) {
                	console.error(` Err: ${err}`);
				}
            });

		}
		updateNumbers();
		

	},
	finalize() {

	}

}