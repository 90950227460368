import $ from 'jquery';
export default {
    init() {
		
        $('body').on('click', '.ShareBox2__button', function(e) {
			e.preventDefault();
			this.classList.toggle('active');
		});

		$('body').on('click', '.ShareBox2 .link__copy', function(e) {
			e.preventDefault();
			e.stopPropagation();
			let t = this;
            this.classList.add('active');
            setTimeout(function() {
                t.classList.remove('active');
            }, 1000);

			let element = document.createElement('input');

			element.value = this.getAttribute('href');
			element.setAttribute('readonly','');
			element.style.position = 'absolute';
			element.style.left = '-99999px';

			document.body.appendChild( element );

			element.select();
			document.execCommand('copy');
			document.body.removeChild(element);
		});

		$('body').on('click', '.ShareBox2', function(e) {
			e.stopPropagation();
		});

		document.addEventListener('click', function() {
			$('.ShareBox2__button').removeClass('active');
		});

		$('.ShareBox2 .ShareBox2__list a').on('click', function(e) {

			if( $(this).closest('.ShareBox2').attr('data-faq-id') ) {

				var endpointUrl = document.querySelector('Footer').getAttribute('data-endpoint');
				let request = null;
				request = new XMLHttpRequest();
				request.open( "POST", endpointUrl , true );
				// request.onreadystatechange = function() {
				//     console.log( request );
				// }
				request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
				request.send( 'action=resourcesItemPopularyCounter&resourcesID='+ $(this).closest('.ShareBox2').attr('data-faq-id') ) ;
				
			}

		});

    },
    finalize(){}
}