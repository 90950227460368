import algoliasearch from 'algoliasearch';
import $ from 'jquery';
class AlgoliaResults {

	constructor( args = {} ) {
		// args => {}
			// listener -> dom element ( input type text ); - req
			// elementToAppend -> dom element - req
			// indexName -> text - req
			// target -> results link target
			// icon -> image url
		var t = this;

		if( !args.listener ) {
			return;
		}
		if( !args.elementToAppend ) {
			return;
		}
		if( !args.indexName ) {
			return;
		}
		this.args = args;
		this.input = t.args.listener;
		this.input.setAttribute("autocomplete","off");
		this.elementToAppend = t.args.elementToAppend;

		this.indexAl = null;
		this.list = null;
			
		// -- get keys -- //

			t.elementToAppend.insertAdjacentHTML('beforeend', '<ul class="AlgoliaResults"></ul>' );
			t.list = t.elementToAppend.querySelector('ul.AlgoliaResults');

			try {
				var data = new FormData();
				data.append('action','get_algolia_keys');
				fetch( window.location.origin+'/wp-admin/admin-ajax.php' , {
					method : 'POST',
					body : data,
				})
				.then( function( response ) { return response.json() } )
				.then( function( data ) { 
					if( data.searchKey && data.appID ) {
						t.indexAl = algoliasearch( data.appID, data.searchKey ).initIndex( args.indexName );
						t.init();
					}
				});
			} catch( error ) {
				console.log( error );
			}
		// -- END -- //

	}
	init() {
		var t = this;
		var inputTimeout = null;
		
		t.input.addEventListener('input', function() {
			t.list.classList.remove('active');	
			clearTimeout( inputTimeout );
			inputTimeout = setTimeout( 
				function() {
					t.list.querySelectorAll('li').forEach( function( item,index ) {
						item.remove();
					});
					if( t.input.value ) {
						t.indexAl.search( t.input.value, {
							hitsPerPage: 6,
						}).then(({ hits }) => {
							if( hits.length > 0 ) {
								t.renderResults( hits );
							}
						});
					}
				},
				300 
			);
		});

		document.addEventListener('click', function() {
			if( t.list.classList.contains('active') ) {
				t.list.classList.remove('active');
				t.list.querySelectorAll('li').forEach( function( item,index ) {
					item.remove();
				});
			}
		});
		t.list.addEventListener('click', function(e) {
			e.stopPropagation();
		});	
	}
	renderResults( results ) {
		var t = this;
		
		results.forEach( function( element, index ) {
			
			if( index < 6 ) {
				// -- a - left -- //
					var singleLeft = null;
					if( element.content_type_icon ) {
						singleLeft = document.createElement('div');
						singleLeft.classList.add('item__left');
						singleLeft.insertAdjacentHTML('beforeend','<img src="'+element.content_type_icon+'">');
					} else if( t.args.icon ) {
						singleLeft = document.createElement('div');
						singleLeft.classList.add('item__left');
						singleLeft.insertAdjacentHTML('beforeend','<img src="'+t.args.icon+'">');
					}
				// -- END -- //

				
				// -- a - center - top -- //
					var singleCenterTop = document.createElement('div');
					singleCenterTop.classList.add('center__top');
					if( element.verified ) {
						singleCenterTop.insertAdjacentHTML( 'beforeend', '<img src="'+window.location.origin+'/wp-content/themes/open-critical-care/assets/svg/verify.svg" class="top__icon">' );
					}
					singleCenterTop.insertAdjacentHTML('beforeend', '<p class="top__title">'+element.title+'</p>' );
					if( element.author_name ) {
						singleCenterTop.insertAdjacentHTML('beforeend', '<p class="top__author">By&nbsp<span>'+element.author_name+'</span></p>' );
					}
				// -- END -- //


				// -- a - center -- //	
					var singleCenter = document.createElement('div');
					singleCenter.classList.add('item__center');
					singleCenter.insertAdjacentHTML('beforeend',singleCenterTop.outerHTML);
					singleCenterTop.remove();
					if( element.description ) {
						singleCenter.insertAdjacentHTML('beforeend','<p class="center__description">'+element.description+'</p>');
					}
				// -- END -- //
				

				// -- a - right -- //
					var singleRight = null;
					if( element.content_leve_name ) {
						singleRight = document.createElement('div');
						singleRight.classList.add('item__right');
						singleRight.insertAdjacentHTML('beforeend','<p>'+element.content_leve_name+'</p>');
					}
				// -- END -- //
				

				// -- a -- //
					var single = document.createElement('a');
					single.classList.add('single__item');
					single.setAttribute('href', element.url );
					single.setAttribute('title', element.title );
					if( t.args.target == '_blank' ) {
						single.setAttribute('target','_blank');
					}

					if( singleLeft ) {
						single.insertAdjacentHTML('beforeend', singleLeft.outerHTML );
						singleLeft.remove();
					}
					single.insertAdjacentHTML('beforeend', singleCenter.outerHTML );
					singleCenter.remove();
					if( singleRight ) {
						single.insertAdjacentHTML('beforeend', singleRight.outerHTML );
						singleRight.remove();
					}
				// -- END -- //


				// -- li -- //
					var item = document.createElement('li');
					item.classList.add('AlgoliaResults__single');
					item.insertAdjacentHTML('beforeend', single.outerHTML);
					single.remove();
				// -- END -- //
				t.list.insertAdjacentHTML('beforeend', item.outerHTML);
			}
		});
		t.list.classList.add('active');
	}

}
export default AlgoliaResults;