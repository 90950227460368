import PhotoSwipe from 'photoswipe';
class ImageZoom {
    init( images = [], index = null ) {

        if( images && index != null ) {
            let options = Array(); // photoSwipe options
                options.index = parseInt(index, 10);
                options.dataSource = [];
                options.showHideAnimationType = 'zoom';

            let items = [];
            let psObj; // photoSwipe object

            images.forEach( function( image,number ) {
                options.dataSource.push({
                    src : image.getAttribute('data-url'),
                    width : image.getAttribute('data-width'),
                    height : image.getAttribute('data-height'),
                });
            });
            psObj = new PhotoSwipe( options );
            psObj.init();

        }
    }
}
export default ImageZoom;