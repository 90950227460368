export default {
    init() {
        let t = this;
        if( document.querySelector('.FilterList') ) {

            document.querySelectorAll('.FilterList').forEach( ( element, index ) => {
                element.querySelector('.FilterList__button').addEventListener('click', () => {

                    document.querySelectorAll('.FilterList').forEach( ( element2, index2 ) => {
                        if( index != index2 ) {
                            element2.classList.remove('active');
                            element2.querySelector('.FilterList__wrapper').style.height = '0px';
                        }
                    });

                    if( element.classList.contains('active') ) {
                        element.classList.remove('active');
                        element.querySelector('.FilterList__wrapper').style.height = '0px';
                    } else {
                        element.classList.add('active');
                        element.querySelector('.FilterList__wrapper').style.height = element.querySelector('.wrapper__content').offsetHeight + 'px';
                    }
                });

                let values = [];
                element.querySelectorAll('input').forEach( ( input, index ) => {

                    input.addEventListener('change', function() {

                        values = [];
                        element.querySelectorAll('input:checked').forEach( ( input2, index2 ) => {
                            values.push( input2.getAttribute('data-label') );
                        });
                        if( values ) {
                            element.querySelector('.FilterList__button span').textContent = values.join(', ');
                        } else {
                            element.querySelector('.FilterList__button span').textContent = null;
                        }

                        if( input.getAttribute('type') == 'radio' ) {
                            element.classList.remove('active');
                            element.querySelector('.FilterList__wrapper').style.height = '0px';
                        }

                    });

                }); 
                if( element.querySelector('.content__search') ) {
                    let form = element.querySelector('.content__search');
                    let input = form.querySelector('input[name="filter_list_search"]');
                    let timeInput = null;
                    form.addEventListener('submit', ( event ) => {
                        event.preventDefault();
                        clearTimeout( timeInput );
                        t.search( element, input.value );
                        element.querySelector('.FilterList__wrapper').style.height = element.querySelector('.wrapper__content').offsetHeight + 'px';
                    });
                    input.addEventListener('input', () => {
                        clearTimeout( timeInput );
                        timeInput = setTimeout( () => {
                            t.search( element, input.value );
                            element.querySelector('.FilterList__wrapper').style.height = element.querySelector('.wrapper__content').offsetHeight + 'px';
                        }, 200 );
                    });
                }

            });
            window.addEventListener('resize',() => {
                document.querySelectorAll('.FilterList.active').forEach( ( element, index ) => {
                    element.querySelector('.FilterList__wrapper').style.height = element.querySelector('.wrapper__content').offsetHeight + 'px';
                });
            });
            document.addEventListener('click', ( event ) => {
                if( !event.target.closest('.FilterList') ) {
                    document.querySelectorAll('.FilterList.active').forEach( ( element, index ) => {
                        element.classList.remove('active');
                        element.querySelector('.FilterList__wrapper').style.height = '0px';
                    });
                }
            });

        }

    },
    search( container, search = null ) {
        let inputs = container.querySelectorAll('.content__list .list__item input');
        if( container ) {
            if( search ) {
                inputs.forEach( ( input, index ) => {
                    if( input.getAttribute('data-label').toLowerCase().indexOf( search.toLowerCase() ) >= 0 ) {
                        input.closest('.list__item').classList.remove('deactive');
                    } else {
                        input.closest('.list__item').classList.add('deactive');
                    }
                });
            } else {
                inputs.forEach( ( input, index ) => {
                    input.closest('.list__item').classList.remove('deactive');
                });
            }
        }
    },
    finalize() {}
}