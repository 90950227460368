export default {
    init() {

        var t = this;
        document.querySelectorAll('.FilterSelect3').forEach( function( container, index ) {
            t.finalize( container );
        });
        if( document.querySelectorAll('.FilterSelect3').length > 0 ) {
            window.addEventListener('click', function() {
                document.querySelectorAll('.FilterSelect3').forEach( function( single, index ) {
                    single.querySelector('.FilterSelect3__button').classList.remove('active');
                });				
            });
        }

    },
    finalize( container ){

        container.addEventListener('click', function(e) {
            e.stopPropagation();
        });
        container.querySelector('.FilterSelect3__button').addEventListener('click', function() {
            container.querySelector('.FilterSelect3__button').classList.toggle('active');
        });

        container.querySelectorAll('input[type="radio"]').forEach( function( radio, index2 ) {
            radio.addEventListener('change', function() {
                container.querySelector('.FilterSelect3__button span').textContent = radio.getAttribute('data-label');
                container.querySelector('.FilterSelect3__button').classList.remove('active');
            });
        });

    }
}