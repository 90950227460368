import FileCarousel from '../../Sections/FileCarousel/FileCarousel';
import SharingToolkitPopup from '../../Components/SharingToolkitPopup/SharingToolkitPopup';
import ToolTip from '../../Components/ToolTip/ToolTip';
import smoothscroll from 'smoothscroll-polyfill';
export default {
    init() {
        var container = document.querySelector('main.GuidelinesDashboard');
        if( container ) {

            var t = this;
            t.node = container;

            FileCarousel.init();
            SharingToolkitPopup.init();
            ToolTip.init();
            smoothscroll.polyfill();

            t.previewScale();
            window.addEventListener('resize', function() {
                t.previewScale();
            });

            // -- scroll to settings -- //
                var scrollBtn = t.node.querySelector('.content__button--scroll');
                if( scrollBtn ) {
                    scrollBtn.addEventListener('click', function() {
                        window.scrollTo({
                            top: container.querySelector('.GuidelinesDashboard__dashboard').offsetTop - document.querySelector('header').offsetHeight ,
                            left: 0,
                            behavior: 'smooth'
                        });
                    });
                }
            // -- END -- //


            let timer = null; // timer for reload iframe url after change param
            // -- display step by step guide -- //
                let guideBtn = container.querySelector('button.content__guide');
                let guideBox = container.querySelector('.content__details');
                if( guideBtn ) {

                    guideBtn.addEventListener('click', function() {
                        if( guideBtn.classList.contains('active') ) {
                            guideBtn.classList.remove('active');
                            guideBox.style.height = '0px';
                        } else {
                            guideBtn.classList.add('active');
                            guideBox.style.height = guideBox.querySelector('.details__wrapper').offsetHeight + 'px';
                        }
                    });

                    window.addEventListener('resize', function() {
                        if( guideBtn.classList.contains('active') ) {
                            guideBox.style.height = guideBox.querySelector('.details__wrapper').offsetHeight + 'px';
                        }
                    });

                }
            // -- END -- //
                

            // -- open/close tab settings -- //
                var groups = container.querySelectorAll('.settings__group');
                if( groups ) {

                    container.querySelectorAll('.group__button.active').forEach( function( button , index ) {
                        button.closest('.settings__group').querySelector('.group__fields').style.height = button.closest('.settings__group').querySelector('.fields__wrapper').offsetHeight + 'px';
                    });

                    groups.forEach( function( group, index ) {
                        group.querySelector('.group__button').addEventListener('click', function() {
                            if( group.querySelector('.group__button').classList.contains('active') ) {
                                group.querySelector('.group__button').classList.remove('active');
                                group.querySelector('.group__fields').style.height = '0px';
                            } else {
                                group.querySelector('.group__button').classList.add('active');
                                group.querySelector('.group__fields').style.height = group.querySelector('.fields__wrapper').offsetHeight + 'px';
                            }
                        });
                    });

                    window.addEventListener('resize', function() {
                        container.querySelectorAll('.group__button.active').forEach( function( button , index ) {
                            button.closest('.settings__group').querySelector('.group__fields').style.height = button.closest('.settings__group').querySelector('.fields__wrapper').offsetHeight + 'px';
                        });
                    });
                }
                
            // -- END -- //


            // -- input color change -- //
                container.querySelectorAll('input[type="color"]').forEach( function( input, index ) {
                    input.addEventListener('input', function() {
                        clearTimeout( timer );
                        timer = setTimeout(
                            function() {
                                document.getElementById( input.getAttribute('data-id') ).value = input.value;
                                t.setFrameSrc();
                            },
                            500
                        );
                    });
                });
                container.querySelectorAll('.wrapper__field--color input[type="text"]').forEach( function( input, index ) {
                    input.addEventListener('input', function() {
                        clearTimeout( timer );
                        timer = setTimeout(
                            function() {
                                container.querySelector('[data-id="'+input.getAttribute('id')+'"]').value = input.value;
                                t.setFrameSrc();
                            },
                            500
                        );
                    });
                });
            // -- END -- //

            // -- text field change -- //
                t.node.querySelectorAll('.wrapper__field input[type="text"]').forEach( function( input , index ) {
                    input.addEventListener('input', function() {
                        clearTimeout( timer );
                        timer = setTimeout(
                            function() { t.setFrameSrc() },
                            500
                        );
                    });
                });
            // -- END -- //

            // -- radio button change -- //
                t.node.querySelectorAll('.wrapper__field input[type="radio"]').forEach( function( input , index ) {
                    input.addEventListener('change', function() {
                        clearTimeout( timer );
                        t.setFrameSrc();
                    });
                });
            // -- END -- //

            // -- frame on load -- // 
                var previewFrame = t.node.querySelector('.row__preview iframe');
                previewFrame.addEventListener('load',function() {
                    previewFrame.closest('.row__preview').classList.remove('reload');
                });
            // -- END -- //

            // -- copy frame -- //
                var popupBtn = t.node.querySelector('.summary__frame');
                var popupBox = t.node.querySelector('.SharingToolkitPopup');
                if( popupBox ) {
                    popupBtn.addEventListener('click', function() {

                        let iframeID = 'iframe-'+Date.now();
                        let newContent = '<iframe id="'+iframeID+'" src="'+previewFrame.getAttribute('src')+'" title="'+previewFrame.getAttribute('data-title')+'" style="width: 100%;"></iframe>';
                        if( t.node.querySelector('input[name="auto-height"]:checked') && t.node.querySelector('input[name="auto-height"]:checked').value == '1' ) {
                            newContent += "<script type='text/javascript'> window.addEventListener( 'message', function(event) { if( event.origin == '"+window.location.origin+"' ) { document.querySelector('#"+iframeID+"').style.height = event.data + 'px'; } } ); </script>";
                        }

                        // -- copy -- //
                            let element = document.createElement('input');
                            element.setAttribute('readonly','');
                            element.style.position = 'absolute';
                            element.style.left = '-99999px';
                            element.value = newContent;

                            document.body.appendChild( element );
                            element.select();
                            document.execCommand('copy');
                            document.body.removeChild(element);
                        // -- END -- //

                        popupBox.querySelector('textarea').value = newContent;
                        popupBox.classList.add('active');

                    });
                }
            // -- END -- //

        }
        t.setFrameSrc();
    },
    finalize() {},
    previewScale() {
        let iframesBoxs = document.querySelectorAll('main.GuidelinesDashboard .row__preview');
        iframesBoxs.forEach( function( box, index ) {
            let scale = ( (box.offsetWidth*100) / box.querySelector('iframe').offsetWidth) / 100;
            box.querySelector('iframe').style.transform = 'scale(' + scale + ')';
            box.querySelector('iframe').style.height = (box.offsetHeight*100) / (box.offsetHeight * scale) +'%';
        });
    },
    getFrameURL() {
        let t = this;
        let args = {};
        let params = [];


        t.node.querySelectorAll('.wrapper__field').forEach( function( box, index ) {
            if( box.classList.contains('wrapper__field--text') ) {

                if( box.querySelector('input[type=text]').getAttribute('name') == 'sheet-url' ) {
                    // https://docs.google.com/spreadsheets/d/10ZN6untKilEQxfop0bIvwPpQZMBbdXVZqAZb7wl2pLk/edit#gid=0

                    let value = box.querySelector('input[type="text"]').value;
                    value = value.replace('https://','');
                    value = value.replace('http://','');
                    value = value.replace('docs.google.com/spreadsheets/d/','');
                    value = value.split('/')[0];

                    args[ 'sheet-id' ] = value;

                } else {
                    args[ box.querySelector('input[type="text"]').getAttribute('name') ] = box.querySelector('input[type="text"]').value;
                }

            } else if( box.classList.contains('wrapper__field--radio') ) {
                args[ box.querySelector('input[type="radio"]:checked').getAttribute('name') ] = box.querySelector('input[type="radio"]:checked').value;
            } else if( box.classList.contains('wrapper__field--color') ) {
                args[ box.querySelector('input[type="text"]').getAttribute('name') ] = box.querySelector('input[type="text"]').value.replace('#','');
            }
        });

        if( !args['sheet-id'] ) {
            args['sheet-id'] = t.node.querySelector('.content__button--copy').getAttribute('data-sheet-id');
        }

        Object.keys( args ).forEach( function( key, index ) {
            params.push( key+'='+args[key] );
        });

        return t.node.querySelector('.row__preview iframe').getAttribute('data-basic')+'?'+params.join('&');

    },
    setFrameSrc() {
        let t = this;
        let wrapper = t.node.querySelector('.row__preview');
        let iframe = wrapper.querySelector('iframe');

        wrapper.classList.add('reload');

        iframe.setAttribute( 'src' , t.getFrameURL() );
    }
}
