import LazyLoad from '../../../common/scripts/lazyLoad';
import DataDashboardItem from '../../Components/DataDashboardItem/DataDashboardItem';
import ToolTip from '../../Components/ToolTip/ToolTip';
import InfoModal from '../../Components/InfoModal/InfoModal';
import Subpage from '../../Layout/Subpage';
import $ from 'jquery';

export default { 

	init() {
		InfoModal.init( 'info_modal_covid_dashboard' );

		function mobileColsShow() {

			if( $('.DDempty').hasClass('active') ) {

				$('.DDItems__col').addClass('hide');
				
			} else if( $('.DDItems__col:not(.disable)').length == 1 ) {

				$('.DDItems__col:not(.disable)').removeClass('hide');

			} else {

				$('.DDItems__col').each( function( index, col ) {

					if( $(col).find('.DDI:not(.inactive)').length == 0 ) {
						$(col).addClass('hide');
					} else {
						$(col).removeClass('hide');
					}

				});

			}
			
		}

		var request;
		var response;
		function refreshContent(){

			document.querySelector('.DDItems').classList.add('reload');

			let btnFilter = document.querySelectorAll('button[data-ddfilter]');
			let btnLevel = document.querySelectorAll('button[data-ddlevel]');
			let btnAuth = document.querySelectorAll('button[data-ddauthorities]');
			let ddFilter = [];
			let ddLevel = [];
			let ddAuth = [];
			document.querySelectorAll('button[data-ddfilter].active').forEach( function( button, index ) {
				ddFilter.push( button.getAttribute('data-ddfilter') );
			});
			document.querySelectorAll('button[data-ddlevel].active').forEach( function( button, index ) {
				ddLevel.push( button.getAttribute('data-ddlevel') );
			});
			document.querySelectorAll('button[data-ddauthorities].active').forEach( function( button, index ) {
				ddAuth.push( button.getAttribute('data-ddauthorities') );
			});

			// -- set new url -- //
				let newUrl = window.location.origin + window.location.pathname;
				let newUrlArgs = [];
				if( ddFilter && ddFilter.length != btnFilter.length ) {
					newUrlArgs.push( 'filter='+ddFilter.join(',') );
				}
				if( ddLevel && ddLevel.length != btnLevel.length ) {
					newUrlArgs.push( 'level='+ddLevel.join(',') );
				}
				if( ddAuth && ddAuth.length != btnAuth.length ) {
					newUrlArgs.push( 'authorities='+ddAuth.join(',') );
				}
				if( document.querySelector('.DD__header input[name="search"]').value ) {
					newUrlArgs.push( 'search='+document.querySelector('.DD__header input[name="search"]').value );
				}
				if( newUrlArgs ) {
					newUrl = newUrl + '?'+ newUrlArgs.join('&');
				}
				history.pushState({},"", newUrl);
			// -- END -- //

			// -- Request -- //

				request = new XMLHttpRequest();
				request.onreadystatechange = function() {

					if( request.readyState == 4 && request.status == 200 ) {

						if( $('.DD__header input[name="search"]') && $('.DD__header input[name="search"]').val() ) {
							gtag('event', 'page_view', {page_location: window.location.pathname+'?search='+$('.DD__header input[name="search"]').val()+'&sc=data-dashboard'});
						}

					}

					if( request.responseText && request.readyState == 4 && request.status == 200 ) {

						response = JSON.parse(request.responseText);
						
						$('.DDI').addClass('inactive');
						response.forEach( function( ddid, index ) {
							if( document.querySelector('.DDI[data-id="'+ddid+'"]') ) {
								document.querySelector('.DDI[data-id="'+ddid+'"]').classList.remove('inactive');
							}
						});

						let minValue = 1;
						if( ddAuth.length >= 2 ) {
							minValue = 2;
						}

						document.querySelectorAll('.DDI').forEach( function( item, index ) {

							// -- select/deselect pills --//
								item.querySelectorAll('.DDI .DDI__grid button.grid__single').forEach( function( pill, index ) {
									if( ddAuth.includes( pill.getAttribute('data-name') ) || ddAuth.length == 0 ) {
										pill.classList.remove('grid__single--inactive');
									} else {
										pill.classList.add('grid__single--inactive');
									}
								});
							// -- END -- //

							// -- change column -- //
								let greenActivePills = item.querySelectorAll('.grid__single--green:not(.grid__single--inactive)').length;
								let redActivePills = item.querySelectorAll('.grid__single--red:not(.grid__single--inactive)').length;
								
								if( greenActivePills >= minValue && redActivePills == 0 ) {
									document.querySelector('.DDItems__columns--tab .DDItems__col:nth-of-type(1) .DDItems__col-box').insertAdjacentHTML( 'beforeend', item.outerHTML );
									item.remove();
								} else if( greenActivePills == 0 && redActivePills >= minValue ) {
									document.querySelector('.DDItems__columns--tab .DDItems__col:nth-of-type(3) .DDItems__col-box').insertAdjacentHTML( 'beforeend', item.outerHTML );
									item.remove();
								} else {
									document.querySelector('.DDItems__columns--tab .DDItems__col:nth-of-type(2) .DDItems__col-box').insertAdjacentHTML( 'beforeend', item.outerHTML );
									item.remove();
								}
							// -- END -- //

						});

						if($('.DDI:not(.inactive)').length > 0) {
							$('.DDempty').removeClass('active');
							$('.DDItems').removeClass('empty');
						}
						else {
							$('.DDempty').addClass('active');
							$('.DDItems').addClass('empty');
						}

						mobileColsShow();

					}

					if( request.readyState == 4 ) {
						document.querySelector('.DDItems').classList.remove('reload');
					}

				}

				// Set up our request
				request.open('POST', document.querySelector('footer').getAttribute('data-endpoint') );

				// Add the required HTTP header for form data POST requests
				request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

				// Finally, send our data.
				let args = [];
				args.push( 'action=covidDatadashboard' );
				if( ddFilter && ddFilter.length != btnFilter.length ) {
					args.push( 'filter='+ddFilter.join(',') );
				}
				if( ddLevel && ddLevel.length != btnLevel.length ) {
					args.push( 'level='+ddLevel.join(',') );
				}
				if( ddAuth && ddAuth.length != btnAuth.length ) {
					args.push( 'authorities='+ddAuth.join(',') );
				}
				args.push( 'search='+document.querySelector('.DD__header input[name="search"]').value );
				request.send( args.join('&') );				

			// -- END -- //

		}

		if( window.location.search ) {

			window.location.search.replace('?','').split('&').forEach( function( row,index ) {
				if( row.split('=')[0] == 'search' ) {
					refreshContent();
				}
			});

		}

		function openMobileTooltip( element ) { // element => clicked item 

			if( Subpage.isTouchDevice() || window.innerWidth <= 1180 ) {

				$(".TooltipMobile .row").removeClass('active');
				$(".TooltipMobile .row[data-tooltip='" + element.data("tooltip") +"']").addClass('active');
				$('.TooltipMobile').addClass('active');
				$('body').addClass('disableScroll');

			}

		}

		function scrollToResults() {

			let value = $('.DDItems__columns--tab').offset().top - $('header').innerHeight();
			if( window.innerWidth <= 1180  ) {
				$('html ,body').animate({'scrollTop': value - $('.DDItems__switch').innerHeight() }, 'smooth');
			} else {
				$('html ,body').animate({'scrollTop': value }, 'smooth');
			}
			
		}

		DataDashboardItem.init();
		ToolTip.init();

		var searchTimeout;
		$('.DD__header input[name="search"]').on( 'input', function() {

			clearTimeout( searchTimeout );
			searchTimeout = null;

			searchTimeout = setTimeout(
				refreshContent,
				500
			);
		});
		$('.DD__header input[name="search"]').on('keyup', function(e) {

			if (event.keyCode === 13 ) {
				
				scrollToResults();
				
			}

		});
		$('.DD__header .header__content button').on( 'click', scrollToResults);



		$('main.datadashboard .header__filters .content__grid .grid__item .item__content').on('click', function() {
			if($(this).closest('.content__grid').hasClass('allActive')) {
				$(this).closest('.content__grid').removeClass('allActive');
				$(this).closest('.content__grid').find('button').removeClass('active');
			}
			$(this).toggleClass('active');
			refreshContent();
		});
		$('main.datadashboard .filters__content .content__more').on('click', function() {
			if( $(this).hasClass('active') ) {
				$(this).closest('.filters__content').addClass('filters__content--hide');
				$(this).removeClass('active');
			} else {
				$(this).closest('.filters__content').removeClass('filters__content--hide');
				$(this).addClass('active');
			}
		});



		$(".DD__heading-info").click(function() {

			openMobileTooltip( $(this) );

		});

		$(".TooltipMobile__close").click(function() {
			$('.TooltipMobile').removeClass('active');
			$('body').removeClass('disableScroll');
		});


		// -- ToolTip - component - //
			$('.ToolTip').on( 'click', function() {
				if( Subpage.isTouchDevice() ) {
					$(this).removeClass('active');
					if( $(this)[0].hasAttribute('data-tooltip') ) {
						openMobileTooltip( $(this) );
					} else if( $(this)[0].hasAttribute('data-title' ) ) {
						$(".TooltipMobile .row[data-tooltip='single_level'] .TooltipMobile__heading").text( $(this).data('title') );
						$(".TooltipMobile .row[data-tooltip='single_level'] .TooltipMobile__description").text( $(this).find('.content__text').text() );

						$(this).data('tooltip','single_level');
						openMobileTooltip( $(this) );
						$(this).data('tooltip','');
					}
				}
			} );
		// -- END -- //


		// -- legend -- //

			$('.DD__legend .legend__row .row__item').click( function(e) {

				if( $(this).find('.item__tooltip').length != 0 ) {

					$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] a").remove();

					$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__heading").text( $(this).find('.item__content').text() );

					if( $(this).find('.tooltip__content p').length != 0 ) {

						$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__description").removeClass('hide');
						$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__description").text( $(this).find('.tooltip__content p').text() );

					} else {

						$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__description").addClass('hide');

					}

					if( $(this).find('.tooltip__content a').length == 1 ) {

						 $(this).find('.tooltip__content a').clone().appendTo( $(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] ")[0] );

					}

					openMobileTooltip( $(this) );

				}				

			});

		// -- END -- //


		// -- organization -- //

			$('[data-tooltip="organization"]').click( function() {

				if( $(this).find('.tooltiptext').length != 0 ) {

					$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] a").remove();

					$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__heading").text( $(this).find('span').text() );
					$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__description").text( $(this).find('.tooltiptext__content p.content__text').text() );

					if( $(this).find('.tooltiptext__content p.content__date').length != 0 ) {

						$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__date").removeClass('hide');
						$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__date").text( $(this).find('.tooltiptext__content p.content__date').text() );

					} else {

						$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__date").text('');
						$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__date").addClass('hide');

					}

					if( $(this).find('.tooltiptext__content a').length == 1 ) {

						 $(this).find('.tooltiptext__content a').clone().appendTo( $(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] ")[0] );

					}

					$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__heading").removeClass('TooltipMobile__heading--green');
					$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__heading").removeClass('TooltipMobile__heading--blue');
					$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__heading").removeClass('TooltipMobile__heading--red');
					$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__heading").removeClass('TooltipMobile__heading--redborder');

					if( $(this).hasClass('grid__single--green') ) {

						$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__heading").addClass('TooltipMobile__heading--green');

					} else if( $(this).hasClass('grid__single--red') ) {

						$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__heading").addClass('TooltipMobile__heading--red');

					} else if( $(this).hasClass('grid__single--blue') ) {

						$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__heading").addClass('TooltipMobile__heading--blue');

					} else if( $(this).hasClass('grid__single--redborder') ) {

						$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__heading").addClass('TooltipMobile__heading--redborder');

					}

					openMobileTooltip( $(this) );

				}

			});

		// -- END - //


		// -- single_level -- //

			$('[data-tooltip="single_level"]').click( function() {

				$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] a").remove();

				$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__heading").text( $(this).find('span').text() );
				$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__description").text( $(this).find('.tooltiptext__content p').text() );

				if( $(this).find('.tooltiptext__content a').length == 1 ) {

					 $(this).find('.tooltiptext__content a').clone().appendTo( $(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] ")[0] );

				}

				openMobileTooltip( $(this) );

			});

		// -- END -- //


		// -- mobile cols tooltip -- //

			$('[data-tooltip="cols"]').click( function() { 
				openMobileTooltip( $(this) );
			});

		// -- END -- //


		// -- cols mobile switch -- //

			$('.DDItems__switch button.switch__button').click( function(e) {

				if( $(this).hasClass('active')  ) {

					if( $('.DDItems__switch button.switch__button.active').length > 1 )  {

						$(this).removeClass('active');
						$( $('.DDItems__columns--tab .DDItems__col')[$(this).index()] ).addClass('disable'); 
						mobileColsShow();

					}

				} else {

					$(this).addClass('active');
					$( $('.DDItems__columns--tab .DDItems__col')[$(this).index()] ).removeClass('disable'); 

					mobileColsShow();

				}
				
			});	

		// -- END -- //	
		
		
		// -- heading bar -- //
			var bar = $('.datadashboard .DD__header .header__bar');
			if( bar ) {
				bar.find('.bar__close').on('click', function() {
					bar.remove();
				});
			}
		// -- EMD -- //

	},
	finalize() {

	}

}