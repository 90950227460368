import smoothscroll from 'smoothscroll-polyfill';
export default {
    init() {
        var t = this;
        document.querySelectorAll('.CarouselNoShading').forEach( ( container, index ) => {
            t.carouselDesktop( container );
            t.carouselMobile( container );
        });
    },
    finalize() {},
    carouselDesktop( container , gIndex ){
        let t = this;
        if( container ) {
            var interval = null;
            container.querySelectorAll('.CarouselNoShading__desktop .control__button').forEach( function( btn, index ) {
                btn.addEventListener('click', function() {
                    t.carouselDesktopLoop( container, parseInt( btn.getAttribute('data-index') ) );
                    clearInterval( interval );
                });
            });

            container.querySelector('.CarouselNoShading__desktop').addEventListener('mouseenter', function() {
                clearInterval( interval );
                interval = null;
            });
            container.querySelector('.CarouselNoShading__desktop').addEventListener('mouseleave', function() {
                clearInterval( interval );
                interval = setInterval(
                    () => {
                        t.carouselDesktopLoop( container );
                    }, 
                    (container.getAttribute('data-interval') * 1000) 
                );
            });

            interval = setInterval(
                () => {
                    t.carouselDesktopLoop( container );
                }, 
                (container.getAttribute('data-interval') * 1000) 
            );

            t.carouselDesktopMinHeight( container );
            window.addEventListener('resize', function() {
                t.carouselDesktopMinHeight( container );
            });

        }
    },
    carouselDesktopLoop( container, cIndex = null ) {

        if( cIndex == null ) {
            cIndex = parseInt( container.querySelector('.CarouselNoShading__desktop .control__button.active').getAttribute('data-index') ) + 1;
        }
        if( cIndex >= container.querySelectorAll('.CarouselNoShading__desktop .carousel__single').length ) {
            cIndex = 0;
        }
        container.querySelectorAll('.CarouselNoShading__desktop .carousel__single').forEach( function( slide, index ) {
            if( cIndex != index ) {
                slide.classList.remove('active');
            } else {
                slide.classList.add('active');
            }
        });
        container.querySelectorAll('.CarouselNoShading__desktop .control__button').forEach( function( btn, index ) {
            if( cIndex != index ) {
                btn.classList.remove('active');
            } else {
                btn.classList.add('active');
            }
        });
        
    },
    carouselDesktopMinHeight( container ) {
        let tempHeight = 0;
        container.querySelector('.desktop__carousel').style.minHeight = 'unset';
        container.querySelectorAll('.desktop__carousel .carousel__single').forEach( ( slide, index ) => {
            if( slide.offsetHeight > tempHeight || tempHeight == 0 ) {
                tempHeight = slide.offsetHeight;
            }
        });
        container.querySelector('.desktop__carousel').style.minHeight = tempHeight + 'px';
    },
    carouselMobile( container ){
        smoothscroll.polyfill();

        let carousel = container.querySelector('.CarouselNoShading__mobile .mobile__carousel');
        let cBtn = container.querySelectorAll('.CarouselNoShading__mobile .control__button');

        carousel.addEventListener('scroll', function() {

            cBtn.forEach( function( btn, index ) {
                if( index != Math.round( carousel.scrollLeft / carousel.offsetWidth ) ) {
                    btn.classList.remove('active');
                } else {
                    btn.classList.add('active');
                }
            });

        });
        cBtn.forEach( function( btn, index ) {
            btn.addEventListener('click', function() {

                cBtn.forEach( function( btn2, index2 ) {
                    btn2.classList.remove('active');
                });
                btn.classList.add('active');
                
                carousel.scrollTo({ 
                    left: carousel.offsetWidth * parseInt( btn.getAttribute('data-index') ), 
                    behavior: 'smooth'
                });

            });
        });
    }
}