export default {
	init() {
        var t = this;
        var containers = document.querySelectorAll('.FilterSelect2');
		if( containers.length > 0 ) {
            containers.forEach( function( box,index ) {
                t.finalize( box );
            })
		}
	},
	finalize( container ) {
        var t = this;
        var select = container.querySelector('select');
        select.addEventListener('change', function() {
            t.setWidth( select, t.calcWidth( select ) );
        });
        window.addEventListener('resize', function() {
            t.setWidth( select, t.calcWidth( select ) );
        });
        t.setWidth( select, t.calcWidth( select ) );
    },
    calcWidth( select ) {

        var span = document.createElement('span');
        span.textContent = select.options[select.selectedIndex].text;
        select.closest('.FilterSelect2').appendChild( span );

        let currentWidth = 0;
        currentWidth += parseFloat( window.getComputedStyle(select, null).getPropertyValue('padding-left') );
        currentWidth += parseFloat( window.getComputedStyle(select, null).getPropertyValue('padding-right') );
        currentWidth += span.offsetWidth;
        span.remove();
        return currentWidth + 3;
    },
    setWidth( element, width ) {
        element.style.width = width + 'px';
        // element.style.minWidth = width + 'px';
    }
}