export default {
    init() {
        var hamburger = document.querySelector('#hamburger');
        var mobileMenu = document.querySelector('.MobileMenu');
        
        if( hamburger && mobileMenu ) {

            var mobileMenuContent = mobileMenu.querySelector('.MobileMenu__content');

            hamburger.addEventListener('click', function() {
                mobileMenu.classList.add('active');
                document.body.classList.add('disableScroll');
            });

            mobileMenu.addEventListener('click', function() {
                mobileMenu.classList.remove('active');
                document.body.classList.remove('disableScroll');
            });

            mobileMenu.querySelector('button.row__close').addEventListener('click', function() {
                mobileMenu.classList.remove('active');
                document.body.classList.remove('disableScroll');
            });
            mobileMenuContent.addEventListener('click', function(e) {
                e.stopPropagation();
            });
        }
    }
}