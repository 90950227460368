export default {
    init() {
        var container = document.querySelector('.FeedbackForm');
        var body = document.body;
        var t = this;

        if( container ) {

            t.siteKey = container.getAttribute('data-site-key');
            container.removeAttribute('data-site-key');

            var buttonOpen = container.querySelector('.FeedbackForm__button');
            var content = container.querySelector('.FeedbackForm__content')
            var form = container.querySelector('.content__form');

            var requestData = new FormData();
            var formSubmit = false;
            var buttonSubmit = form.querySelector('.form__submit');

            buttonOpen.addEventListener('click', function(e) {
                e.stopPropagation();
                container.classList.add('active');  
                body.classList.add('disableScroll');
            });
            form.addEventListener('click', function(e) {
                e.stopPropagation();

                if( !e.target.closest('.single__select') ) {
                    container.querySelectorAll('.fields__single[data-type="select"]').forEach( function( select,index ) {
                        select.querySelector('.select__button').classList.remove('active');
                    });
                }
            });
            form.querySelector('.header__close').addEventListener('click', function() {
                container.classList.remove('active');
                body.classList.remove('disableScroll');
            });
            container.addEventListener('click', function(e) {
                container.classList.remove('active');
                body.classList.remove('disableScroll');
                if( !e.target.closest('.single__select') ) {
                    container.querySelectorAll('.fields__single[data-type="select"]').forEach( function( select,index ) {
                        select.querySelector('.select__button').classList.remove('active');
                    });
                }
            });

            // -- type select -- //
                container.querySelectorAll('.fields__single[data-type="select"]').forEach( function( select,index ) {

                    select.querySelector('.select__button input').addEventListener('focus', function() {
                        select.querySelector('.select__button input').blur();
                        return false;
                    });

                    select.querySelector('.select__button').addEventListener('click', function() {
                        if( select.querySelector('.select__button').classList.contains('active') ) {
                            select.querySelector('.select__button').classList.remove('active');
                        } else {
                            select.querySelector('.select__button').classList.add('active');
                        }
                    }); 
                    select.querySelectorAll('.select__content input').forEach( function( input,index ) {
                        input.addEventListener('change', function() {
                            select.querySelector('.select__button').classList.remove('select__button--empty');
                            select.querySelector('.select__button input').value = input.value;
                            select.querySelector('.select__button').classList.remove('active');
                        });
                    });

                });
            // -- END -- //

            // -- type radio -- //
                container.querySelectorAll('.fields__single[data-type="radio"]').forEach( function( box,index0 ) {
                    box.querySelectorAll('input[type=radio]').forEach( function( input, index1 ) {
                        input.addEventListener('change', function() {

                            box.querySelectorAll('input[type=radio]').forEach( function( input2, index2 ) {

                                if( index1 == index2 ) {
                                    if( input2.checked ) {

                                        if( input2.closest('li').querySelector('input[type=text]') ) {
                                            input2.closest('li').querySelector('input[type=text]').removeAttribute('disabled');
                                        }
        
                                    } else {
                                        if( input2.closest('li').querySelector('input[type=text]') ) {
                                            input2.closest('li').querySelector('input[type=text]').setAttribute('disabled','true');
                                        }
                                    }
                                } else {
                                    if( input2.closest('li').querySelector('input[type=text]') ) {
                                        input2.closest('li').querySelector('input[type=text]').setAttribute('disabled','true');
                                    }
                                }

                            });                           

                        });
                    });
                });
            // -- END -- //

            // -- form submit -- //
                form.addEventListener('submit', function(e) {
                    e.preventDefault();

                    grecaptcha.ready(function() {
                        grecaptcha.execute( t.siteKey , {action: 'submit'}).then(function(token) {

                            if( formSubmit || buttonSubmit.classList.contains('disable') ) {
                                return false;
                            }
                            t.callback();
                            buttonSubmit.classList.add('disable');
    
    
                            requestData = new FormData();
                            requestData.append( 'action' , 'sendMailFeedback' );
                            requestData.append( 'security', token );
    
                            container.querySelectorAll('.fields__single').forEach( function( box,index0 ) {
    
                                let value = null;
                                switch( box.getAttribute('data-type') ) {
                                    case 'email': value = box.querySelector('input').value; break;
                                    case 'select': 
                                        if( box.querySelector('.select__content input:checked') ) {
                                            value = box.querySelector('.select__content input:checked').value; 
                                        }
                                    break;
                                    case 'radio': 
                                        if( box.querySelector('.single__radio input:checked').closest('li').querySelector('input[type=text]') ) {
                                            value = box.querySelector('.single__radio input:checked').closest('li').querySelector('input[type=text]').value + ' ('+box.querySelector('.single__radio input:checked').value+')'
                                        } else if( box.querySelector('.single__radio input:checked') ) {
                                            value = box.querySelector('.single__radio input:checked').value; 
                                        }
                                    break;
                                    case 'textarea': value = box.querySelector('textarea').value; break;
                                    case 'scale' : 
                                        if( box.querySelector('.single__scale input:checked') ) { 
                                            value = box.querySelector('.single__scale input:checked').value;
                                        }
                                    break;
                                    case 'button' :
                                        if( box.querySelector('.single__button input:checked') ) { 
                                            value = box.querySelector('input:checked').value; 
                                        }
                                    break;
                                }
                                // requestData.append( 'label_'+index0, box.querySelector('.single__label').textContent );
                                requestData.append( 'answer_'+index0, value );
                            });
    
                            // -- send request -- //
                                fetch( window.location.origin+'/wp-admin/admin-ajax.php', {
                                    method: 'POST',
                                    credentials: 'same-origin',
                                    body: requestData,
                                })
                                .then( response => response.json() )
                                .then( data => {
                                        
                                    if( data.status == 'success' ) {
                                        formSubmit = true;
                                        container.querySelector('.FeedbackForm__callback').classList.add('active');
                                        container.classList.remove('active');
                                        body.classList.remove('disableScroll');
                                        setTimeout(
                                            function() {
                                                container.querySelector('.FeedbackForm__callback').classList.remove('active');
                                            },
                                            5000
                                        );
                                    } else {
                                        t.callback( form.getAttribute('data-error') );
                                        buttonSubmit.classList.remove('disable');
                                    }

                                    if( document.querySelector('body').classList.contains('logged-in') ) {
                                        console.log('Success');
                                        console.log( data );
                                    }
    
                                })
                                .catch( (error) => {
                                    if( document.querySelector('body').classList.contains('logged-in') ) {
                                        console.log( 'Error' );
                                        console.log( error );
                                    }
                                });
                            // -- END -- //

                        });
                    });

                });
            // -- END -- //

        }
    },
    callback( message = null ) {
        let container = document.querySelector('.FeedbackForm');
        container.querySelectorAll('.form__callbacl').forEach( function(box,index) {
            box.remove();
        });
        if( message ) {
            container.querySelector('.form__submit').insertAdjacentHTML('afterEnd','<p class="form__callback form__callback--error">'+message+'</p>');
        }
    },
    finalize() {

    }
}