export default {
    init() {
        var t = this;
        var isIframe = false;

        if( window.location.search ) {
            let params = window.location.search.replace('?','').split('&');
            if( params ) {
                params.forEach( ( row, index ) => {
                    if( row.split('=')[0] == 'iframe' ) {
                        isIframe = true;
                    }
                });
            }
        }
        if( isIframe && ( document.body.classList.contains('ResourcesNew') || document.body.classList.contains('Resources') ) ) {
            // disable translation on iframe resources view
            return;
        }

        t.langSetInterval = null;
        t.langChangeInterval = null;
        var element = document.querySelector('.GoogleTranslate');
        
        if( element && ( typeof google == 'undefined' || typeof google !== 'object' || typeof google.translate !== 'object' ) ) {

            let script = document.createElement('script');
            script.setAttribute('type', 'text/javascript');
            script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';

            let exec = document.createElement('script');
            exec.setAttribute('type', 'text/javascript');
            exec.textContent = "function googleTranslateElementInit() { new google.translate.TranslateElement({pageLanguage: 'en'}, '" + element.querySelector('.GoogleTranslate__content').getAttribute('id') + "'); }";

            document.body.appendChild(exec);
            document.body.appendChild(script);

            t.setLang( element );
            t.changeLang( element );

        }

    },
    setLang( element = null ) {
        let t = this;
        let args = null;
        if( element && window.location.search ) {

            args = window.location.search;
            args = args.replace('?','');

            args.split('&').forEach( ( row, index ) => {
                if( row.split('=')[0] == 'language' ) {

                    t.langSetInterval = setInterval(
                        () => {
                            if( element.querySelector('select') ) {
                                element.querySelector('select').value = row.split('=')[1];
                                element.querySelector('select').dispatchEvent(new Event('change'));

                                if( element.querySelector('select').value == row.split('=')[1] ) {
                                    clearInterval( t.langSetInterval );
                                    t.langSetInterval = null;

                                    window.history.pushState( {}, document.querySelector('title').textContent , window.location.href.replace( window.location.search , '') );

                                    document.querySelectorAll('button[data-g-lang]').forEach( ( button, index ) => {
                                        if( button.getAttribute('data-g-lang') == element.querySelector('select').value ) {
                                            button.classList.add('active');
                                        } else {
                                            button.classList.remove('active');
                                        }
                                    });

                                }
                            }
                        },
                        500
                    )

                }
            });
        }
    },
    changeLang( element ) {
        let t = this;
        let langButtons = document.querySelectorAll('button[data-g-lang]');
        if( element && langButtons.length > 0 ) {
            t.langChangeInterval = setInterval(
                () => {
                    if( element.querySelector('select') ) {
                        element.querySelector('select').addEventListener('change', () => {

                            langButtons.forEach( ( button, index ) => {
                                if( button.getAttribute('data-g-lang') == element.querySelector('select').value ) {
                                    button.classList.add('active');
                                } else {
                                    button.classList.remove('active');
                                }
                            });

                        });
                        clearInterval( t.langChangeInterval );
                        t.langChangeInterval = null;
                    }
                },
                500
            );
        }
    },
    finalize() {}
}