import LazyLoad from '../../../common/scripts/lazyLoad';
import DataDashboardItem from '../../Components/DataDashboardItem/DataDashboardItem';
import ToolTip from '../../Components/ToolTip/ToolTip';
import Subpage from '../../Layout/Subpage';
import InfoModal from '../../Components/InfoModal/InfoModal';
import $ from 'jquery';

export default { 

	init() {

		if( document.querySelector('main.InteractiveDataDashboard') ) {
			DataDashboardItem.init();
			ToolTip.init();
			
			if( document.querySelector('.InfoModal') ) {
				InfoModal.init( 'info_modal_interactive_dashboard', true );
				InfoModal.finalize( document.querySelector('.InfoModal') );
			}

			function mobileColsShow() {

				if( $('.IDDempty').hasClass('active') ) {

					$('.IDDItems__col').addClass('hide');
					
				} else if( $('.IDDItems__col:not(.disable)').length == 1 ) {

					$('.IDDItems__col:not(.disable)').removeClass('hide');

				} else {

					$('.IDDItems__col').each( function( index, col ) {

						if( $(col).find('.DDI:not(.inactive)').length == 0 ) {
							$(col).addClass('hide');
						} else {
							$(col).removeClass('hide');
						}

					});

				}
				
			}

			var request;
			var response;
			function refreshContent(){

				document.querySelector('.IDDItems').classList.add('reload');

				let ddFilter = [];
				let ddLevel = [];
				let ddAuth = [];
				document.querySelectorAll('button[data-ddfilter].active').forEach( function( button, index ) {
					ddFilter.push( button.getAttribute('data-ddfilter') );
				});
				document.querySelectorAll('button[data-ddlevel].active').forEach( function( button, index ) {
					ddLevel.push( button.getAttribute('data-ddlevel') );
				});
				document.querySelectorAll('button[data-ddauthorities].active').forEach( function( button, index ) {
					ddAuth.push( button.getAttribute('data-ddauthorities') );
				});
				
				let counterLevel = 0;
				let counterFilter = 0;
				let counterAuth = 0;

				$('.DDI').addClass('inactive');
				$('.DDI').each( function( index, element ) {

					counterAuth = 0;
					counterLevel = 0;
					counterFilter = 0;

					// -- check auth -- //
						if( ddAuth.length > 0 ) {
							element.getAttribute('data-ddauthorities').split(',').forEach( function( str , index ) {
								if( ddAuth.includes( str ) ) {
									counterAuth ++;
								}
							});
						} else {
							counterAuth ++;
						}
					// -- END -- //

					// -- check level -- //
						if( ddLevel.length > 0 ) {
							if( element.getAttribute('data-ddlevel') ) {
								element.getAttribute('data-ddlevel').split(',').forEach( function( str , index ) {
									if( ddLevel.includes( str ) ) {
										counterLevel ++;
									}
								});
							}
						} else {
							counterLevel ++;
						}
					// -- END -- //

					// -- check filter -- // 
						if( ddFilter.length > 0 ) {
							if( element.getAttribute('data-ddfilter') ) {
								element.getAttribute('data-ddfilter').split(',').forEach( function( str , index ) {
									if( ddFilter.includes( str ) ) {
										counterFilter ++;
									}
								});
							}
						} else {
							counterFilter ++;
						}
					// -- END -- //
					
					
					if( 
						counterAuth > 0 &&
						counterLevel > 0 &&
						counterFilter > 0 
					) {
						element.classList.remove('inactive');
					}

				});

				let minValue = parseInt( document.querySelector('[data-authorities-rules-min]').getAttribute(['data-authorities-rules-min']) );
				if( ddAuth.length >= 1 && ddAuth.length <= parseInt( document.querySelector('[data-authorities-rules-min]').getAttribute(['data-authorities-rules-min']) ) ) {
					minValue = ddAuth.length;
				}

				document.querySelectorAll('.DDI').forEach( function( item, index ) {

					// -- select/deselect pills --//
						item.querySelectorAll('.DDI .DDI__grid button.grid__single').forEach( function( pill, index ) {
							if( ddAuth.includes( pill.getAttribute('data-name') ) || ddAuth.length == 0 ) {
								pill.classList.remove('grid__single--inactive');
							} else {
								pill.classList.add('grid__single--inactive');
							}
						});
					// -- END -- //

					// -- change column -- //
						let greenActivePills = item.querySelectorAll('.grid__single--green:not(.grid__single--inactive)').length;
						let redActivePills = item.querySelectorAll('.grid__single--red:not(.grid__single--inactive)').length;
						
						if( greenActivePills >= minValue && redActivePills == 0 ) {
							document.querySelector('.IDDItems__columns--tab .IDDItems__col:nth-of-type(1) .IDDItems__col-box').insertAdjacentHTML( 'beforeend', item.outerHTML );
							item.remove();
						} else if( greenActivePills == 0 && redActivePills >= minValue ) {
							document.querySelector('.IDDItems__columns--tab .IDDItems__col:nth-of-type(3) .IDDItems__col-box').insertAdjacentHTML( 'beforeend', item.outerHTML );
							item.remove();
						} else {
							document.querySelector('.IDDItems__columns--tab .IDDItems__col:nth-of-type(2) .IDDItems__col-box').insertAdjacentHTML( 'beforeend', item.outerHTML );
							item.remove();
						}
					// -- END -- //

				});

				if($('.DDI:not(.inactive)').length > 0) {
					$('.IDDempty').removeClass('active');
					$('.IDDItems').removeClass('empty');
				} else {
					$('.IDDempty').addClass('active');
					$('.IDDItems').addClass('empty');
				}

				document.querySelector('.IDDItems').classList.remove('reload');
				mobileColsShow();

			}

			function openMobileTooltip( element ) { // element => clicked item 
				if( Subpage.isTouchDevice() || window.innerWidth <= 1180 ) {
					$(".TooltipMobile .row").removeClass('active');
					$(".TooltipMobile .row[data-tooltip='" + element.data("tooltip") +"']").addClass('active');
					$('.TooltipMobile').addClass('active');
					$('body').addClass('disableScroll');
				}
			}
		

			$('main.InteractiveDataDashboard .header__filters .content__grid .grid__item .item__content').on('click', function() {
				if($(this).closest('.content__grid').hasClass('allActive')) {
					$(this).closest('.content__grid').removeClass('allActive');
					$(this).closest('.content__grid').find('button').removeClass('active');
				}
				$(this).toggleClass('active');
				refreshContent();
			});
			$('main.InteractiveDataDashboard .filters__content .content__more').on('click', function() {
				if( $(this).hasClass('active') ) {
					$(this).closest('.filters__content').addClass('filters__content--hide');
					$(this).removeClass('active');
				} else {
					$(this).closest('.filters__content').removeClass('filters__content--hide');
					$(this).addClass('active');
				}
			});


			$(".TooltipMobile__close").click(function() {
				$('.TooltipMobile').removeClass('active');
				$('body').removeClass('disableScroll');
			});


			// -- ToolTip - component - //
				$('.ToolTip').on( 'click', function() {
					if( Subpage.isTouchDevice() ) {
						$(this).removeClass('active');
						if( $(this)[0].hasAttribute('data-tooltip') ) {
							openMobileTooltip( $(this) );
						} else if( $(this)[0].hasAttribute('data-title' ) ) {
							console.log( $(this) );
							console.log( $(this).find('.content__text').text() );
							console.log( $(this).find('.content__text')[0].innerHTML );
							$(".TooltipMobile .row[data-tooltip='single_level'] .TooltipMobile__heading").text( $(this).data('title') );
							// $(".TooltipMobile .row[data-tooltip='single_level'] .TooltipMobile__description").text( $(this).find('.content__text').text() );
							$(".TooltipMobile .row[data-tooltip='single_level'] .TooltipMobile__description")[0].innerHTML = $(this).find('.content__text')[0].innerHTML;

							$(this).data('tooltip','single_level');
							openMobileTooltip( $(this) );
							$(this).data('tooltip','');
						}
					}
				} );
			// -- END -- //


			// -- legend -- //
				$('.IDD__legend .legend__row .row__item').click( function(e) {
					if( $(this).find('.item__tooltip').length != 0 ) {

						$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] a").remove();

						$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__heading").text( $(this).find('.item__content').text() );

						if( $(this).find('.tooltip__content p').length != 0 ) {

							$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__description").removeClass('hide');
							$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__description").text( $(this).find('.tooltip__content p').text() );

						} else {

							$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__description").addClass('hide');

						}

						if( $(this).find('.tooltip__content a').length == 1 ) {

							$(this).find('.tooltip__content a').clone().appendTo( $(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] ")[0] );

						}
						openMobileTooltip( $(this) );

					}
				});
			// -- END -- //


			// -- organization -- //
				$('[data-tooltip="organization"]').click( function() {

					if( $(this).find('.tooltiptext').length != 0 ) {

						$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] a").remove();

						$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__heading").text( $(this).find('span').text() );
						$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__description").text( $(this).find('.tooltiptext__content p.content__text').text() );

						if( $(this).find('.tooltiptext__content p.content__date').length != 0 ) {

							$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__date").removeClass('hide');
							$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__date").text( $(this).find('.tooltiptext__content p.content__date').text() );

						} else {

							$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__date").text('');
							$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__date").addClass('hide');

						}

						if( $(this).find('.tooltiptext__content a').length == 1 ) {

							$(this).find('.tooltiptext__content a').clone().appendTo( $(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] ")[0] );

						}

						$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__heading").removeClass('TooltipMobile__heading--green');
						$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__heading").removeClass('TooltipMobile__heading--blue');
						$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__heading").removeClass('TooltipMobile__heading--red');
						$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__heading").removeClass('TooltipMobile__heading--redborder');

						if( $(this).hasClass('grid__single--green') ) {

							$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__heading").addClass('TooltipMobile__heading--green');

						} else if( $(this).hasClass('grid__single--red') ) {

							$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__heading").addClass('TooltipMobile__heading--red');

						} else if( $(this).hasClass('grid__single--blue') ) {

							$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__heading").addClass('TooltipMobile__heading--blue');

						} else if( $(this).hasClass('grid__single--redborder') ) {

							$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__heading").addClass('TooltipMobile__heading--redborder');

						}

						openMobileTooltip( $(this) );

					}

				});
			// -- END - //


			// -- single_level -- //
				$('[data-tooltip="single_level"]').click( function() {

					$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] a").remove();

					$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__heading").text( $(this).find('span').text() );
					$(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] .TooltipMobile__description").text( $(this).find('.tooltiptext__content p').text() );

					if( $(this).find('.tooltiptext__content a').length == 1 ) {

						$(this).find('.tooltiptext__content a').clone().appendTo( $(".TooltipMobile .row[data-tooltip='" + $(this).data("tooltip") +"'] ")[0] );

					}

					openMobileTooltip( $(this) );

				});
			// -- END -- //


			// -- mobile cols tooltip -- //

				$('[data-tooltip="cols"]').click( function() { 
					openMobileTooltip( $(this) );
				});

			// -- END -- //


			// -- cols mobile switch -- //

				$('.IDDItems__switch button.switch__button').click( function(e) {

					if( $(this).hasClass('active')  ) {

						if( $('.IDDItems__switch button.switch__button.active').length > 1 )  {

							$(this).removeClass('active');
							$( $('.IDDItems__columns--tab .IDDItems__col')[$(this).index()] ).addClass('disable'); 
							mobileColsShow();

						}

					} else {

						$(this).addClass('active');
						$( $('.IDDItems__columns--tab .IDDItems__col')[$(this).index()] ).removeClass('disable'); 

						mobileColsShow();

					}
					
				});	

			// -- END -- //	
		
		}
	},
	finalize() {

	}

}