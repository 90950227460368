import smoothscroll from 'smoothscroll-polyfill';
import LazyLoad from '../../../common/scripts/lazyLoad';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import FileCarousel from '../../Sections/FileCarousel/FileCarousel';
import FilterSelect from '../../Components/FilterSelect/FilterSelect';

export default {
    init() {
        var t = this;
        t.pages = [];
        t.pagesCurrent = 0;
        t.tileID = null;

        t.fileAuthor = document.querySelector('[data-author-name]').getAttribute('data-author-name');
        document.querySelector('[data-author-name]').removeAttribute('data-author-name');

        smoothscroll.polyfill();
        FileCarousel.init();
        FilterSelect.init();

        t.drag();
        t.addPage();
        t.stickyPage();
        
        // -- display preview from url param -- //
            if( window.location.search ) {
                let search = window.location.search.replaceAll('?','');
                if( search.split('&') ) {
                    search.split('&').forEach( function( term, index ) {
                        if( term.split('=') && term.split('=')[0] == 'resource_id' ) {

                            let tileID = term.split('=')[1];
                            let tileImageID = null;
                            let tileTitle = null;
                            if( document.querySelector('.PdfPageTile [value="'+tileID+'"]') ) {
                                if( document.querySelector('.PdfPageTile [value="'+tileID+'"]').closest('.PdfPageTile').querySelector('[data-id]') ) {
                                    tileImageID = document.querySelector('.PdfPageTile [value="'+tileID+'"]').closest('.PdfPageTile').querySelector('[data-id]').getAttribute('data-id');
                                }
                                if( document.querySelector('.PdfPageTile [value="'+tileID+'"]').closest('.PdfPageTile').querySelector('[data-title]') ) {
                                    tileTitle = document.querySelector('.PdfPageTile [value="'+tileID+'"]').closest('.PdfPageTile').querySelector('[data-title]').getAttribute('data-title');
                                }
                            }
                            if( tileImageID && tileTitle ) {
                                t.zoomImage( tileImageID, tileTitle );
                            }
                            
                        }
                    });
                }
            }
        // -- END -- //

        // -- print pdf -- //
            var printBtn = document.querySelector('.generate-pdf');
            printBtn.addEventListener('click', function(e) {
                e.preventDefault();

                let itemsPerPage = document.querySelector('input[name="pdf_size"]:checked').value;

                if( printBtn.hasAttribute('disabled') ) {
                    return;
                }
                printBtn.setAttribute('disabled',1);
                
                document.querySelector('.GeneratorPDF__content .content__print').innerHTML = '';
                t.pages = [];   
                t.pages.push({
                    'url' : null,
                    'row' : 0,
                    'col' : 0,
                    'id' : Math.floor(Math.random() * Math.floor(Math.random() * Date.now())),
                });

                document.querySelectorAll('.doc__pages .pages__single').forEach( function( page, index0 ) {

                    if( page.querySelectorAll('.single__tile--empty').length != itemsPerPage ) {
                        page.querySelectorAll('.single__tile:not(.single__tile--cover) .content__source img.source__display').forEach( function( source, index1 ) {
                            let colPosition = index1;
                            if( index0 == 0 ) {
                                colPosition = index1 + 1;
                            }
                            if( source.getAttribute('src') ) {
                                t.pages.push({
                                    'url' : source.getAttribute('src'),
                                    'row' : index0,
                                    'col' : colPosition,
                                    'id' : Math.floor(Math.random() * Math.floor(Math.random() * Date.now())),
                                })
                            } else {
                                t.pages.push({
                                    'url' : '',
                                    'row' : index0,
                                    'col' : colPosition,
                                    'id' : Math.floor(Math.random() * Math.floor(Math.random() * Date.now())),
                                });
                            }
                        });
                    }

                });

                t.getContent();
            
            });
        // -- END -- //


        // -- settings -- //

            // -- change size -- //
                var sizeRadio = document.querySelectorAll('input[type=radio][name="pdf_size"]');
                if( sizeRadio ) {
                    sizeRadio.forEach( function( radio, index ) {
                        radio.addEventListener('change', function() {
                            document.querySelector('.GeneratorPDF__content .doc__pages .pages__content').innerHTML = null;
                            t.addPage();
                            if( radio.value == '2' ) {
                                t.addPage();
                            }
                            LazyLoad.init();

                        });
                    });
                }
            // -- END -- //

            
            // -- change title -- //
                var titleInput = document.querySelector('input.field__input[name="title"]');
                var titleInputTimeout = null;
                titleInput.addEventListener('input', function() {
                    clearInterval( titleInputTimeout );
                    titleInputTimeout = setTimeout(
                        function() {
                            document.querySelectorAll('.single__tile--cover').forEach( function( tile, index ) {
                                tile.querySelector('.cover__header .header__title').textContent = titleInput.value;
                            });
                        },
                        500
                    );
                });
            // -- END -- //


            // -- add logo -- //
                var logoField = document.querySelector('input.logo__input[type=file][name="logo"]');
                logoField.addEventListener('change', function(e) {
                    e.preventDefault();
                    t.callback();
                    let file = logoField.files[0];

                    if( file.size / 1024 / 1024 > 1 ) {
                        t.callback({
                            'message' : logoField.getAttribute('data-size'),
                            'position' : 'beforeend',
                            'element' : document.querySelector('.GeneratorPDF__settings .settings__row .field__logo .logo__content'),
                        });
                        if( document.body.classList.contains('logged-in') ) {
                            console.log('to big file size');
                            console.log( file.size +'(B)' );
                        }
                        return;
                    }

                    if( file.type != 'image/png' && file.type != 'image/jpeg' ) {
                        t.callback({
                            'message' : logoField.getAttribute('data-type'),
                            'position' : 'beforeend',
                            'element' : document.querySelector('.GeneratorPDF__settings .settings__row .field__logo .logo__content'),
                        });
                        if( document.body.classList.contains('logged-in') ) {
                            console.log('invalid type');
                            console.log( file.type );
                        }
                        return;
                    }

                    let reader = new FileReader();
                    let image = new Image();

                    reader.addEventListener("load", function () {

                        image.src = reader.result;
                        image.addEventListener('load', function() {

                            document.querySelectorAll('.single__tile--cover .content__cover .cover__body .body__logo img').forEach( function( node, index ) {
                                node.closest('.body__logo').classList.remove('body__logo--disable');
                                node.setAttribute('src', reader.result );
                            });
                            document.querySelector('.GeneratorPDF__settings .field__logo .logo__content .content__name').textContent = file.name;

                        });
                    }, false);
                    
                    if( file ) {
                        reader.readAsDataURL( file );
                    }
                });
                document.querySelector('.logo__button').addEventListener('click', function() {
                    logoField.click();
                });
            // -- END -- //
            
        // -- END -- //


        // -- pages -- //
            
            // -- "browse" button click -- //
                document.addEventListener('click', function( e ) {
                    if( e.target.classList.contains('empty__browse') ) {
                        document.querySelector('.content__cards').classList.add('active');
                        document.body.classList.add('disableScroll');
                        t.tileID = e.target.closest('.single__tile').getAttribute('data-id');
                    }
                    if( 
                        ( 
                            e.target.classList.contains('header__close') && 
                            e.target.closest('.content__cards.active') 
                        ) ||
                        (
                            e.target.closest('.content__cards.active') && 
                            !e.target.closest('.cards__box')
                        )
                    ) {
                        document.querySelector('.content__cards').classList.remove('active');
                        document.body.classList.remove('disableScroll');
                    }
                });
            // -- END -- //

            // -- resize cover scale -- //
                window.addEventListener('resize', function() {
                    t.coverScale();
                });
            // -- END -- //

        // -- END -- //
        

        // -- page item -- //
            var currentID = null;
            var itemTempSrc = null;
            var itemTempTitle = null;
            var itemsList = null;
            document.addEventListener('click', function(e) { 

                if( e.target.classList.contains('control__button--move-left') && e.target.closest('.tile__content') ) {
                    currentID = e.target.closest('.single__tile').getAttribute('data-id');
                    itemsList = document.querySelectorAll('.doc__pages .pages__single .single__tile:not(.single__tile--cover)');
                    itemsList.forEach( function( tile,index ) {
                        if( tile.getAttribute('data-id') == currentID && itemsList[index-1] ) {

                            itemTempSrc = itemsList[index-1].querySelector('.content__source img').getAttribute('src');
                            itemTempTitle = itemsList[index-1].querySelector('.tile__content img').getAttribute('data-title');

                            itemsList[index-1].querySelectorAll('.content__source img').forEach( function(image, index) {
                                image.setAttribute('src' , e.target.closest('.tile__content').querySelector('.content__source img').getAttribute('src') );
                            })
                            itemsList[index-1].querySelector('.tile__content').setAttribute('data-title' , e.target.closest('.tile__content').getAttribute('data-title') );
                            if( itemsList[index-1].querySelector('.content__source img').getAttribute('src') ) {
                                itemsList[index-1].classList.remove('single__tile--empty');
                            } else {
                                itemsList[index-1].classList.add('single__tile--empty');
                            }

                            e.target.closest('.tile__content').querySelectorAll('.content__source img').forEach( function( image, index ) {
                                image.setAttribute('src', itemTempSrc );
                            });
                            e.target.closest('.tile__content').setAttribute('data-title', itemTempTitle );
                            if( e.target.closest('.tile__content.clicked') ) {
                                e.target.closest('.tile__content').classList.remove('clicked');
                                itemsList[index-1].querySelector('.tile__content').classList.add('clicked');
                            }
                            if( itemTempSrc ) {
                                e.target.closest('.single__tile').classList.remove('single__tile--empty');
                            } else {
                                e.target.closest('.single__tile').classList.add('single__tile--empty');
                            }
                            t.removePage( e.target.closest('.pages__single') );
                        }
                    });
                }
                if( e.target.classList.contains('control__button--remove-src') && e.target.closest('.tile__content') ) {
                    e.target.closest('.tile__content').querySelectorAll('.content__source img').forEach( function( image, index ) {
                        image.setAttribute('src','');
                    });
                    e.target.closest('.tile__content').setAttribute('data-title','');
                    e.target.closest('.single__tile').classList.add('single__tile--empty');

                    t.removePage( e.target.closest('.pages__single') );
                }
                if( e.target.classList.contains('control__button--move-right') && e.target.closest('.tile__content') ) {
                    currentID = e.target.closest('.single__tile').getAttribute('data-id');
                    itemsList = document.querySelectorAll('.doc__pages .pages__single .single__tile:not(.single__tile--cover)');
                    itemsList.forEach( function( tile,index ) {
                        if( tile.getAttribute('data-id') == currentID && itemsList[index+1] ) {

                            itemTempSrc = itemsList[index+1].querySelector('.content__source img').getAttribute('src');
                            itemTempTitle = itemsList[index+1].querySelector('.tile__content').getAttribute('data-title');

                            itemsList[index+1].querySelectorAll('.content__source img').forEach( function(image, index) {
                                image.setAttribute('src' , e.target.closest('.tile__content').querySelector('.content__source img').getAttribute('src') );
                            });

                            itemsList[index+1].querySelector('.tile__content').setAttribute('data-title' , e.target.closest('.tile__content').getAttribute('data-title') );
                            if( itemsList[index+1].querySelector('.content__source img').getAttribute('src') ) {
                                itemsList[index+1].classList.remove('single__tile--empty');
                            } else {
                                itemsList[index+1].classList.add('single__tile--empty');
                            }

                            e.target.closest('.tile__content').querySelectorAll('.content__source img').forEach( function( image ,index ) {
                                image.setAttribute('src', itemTempSrc );
                            });
                            e.target.closest('.tile__content').setAttribute('data-title', itemTempTitle);
                            if( e.target.closest('.tile__content.clicked') ) {
                                e.target.closest('.tile__content').classList.remove('clicked');
                                itemsList[index+1].querySelector('.tile__content').classList.add('clicked');
                            }
                            if( itemTempSrc ) {
                                e.target.closest('.single__tile').classList.remove('single__tile--empty');
                            } else {
                                e.target.closest('.single__tile').classList.add('single__tile--empty');
                            }

                            t.removePage( e.target.closest('.pages__single') );

                            let lastPage = document.querySelectorAll('.doc__pages .pages__single')[ document.querySelectorAll('.doc__pages .pages__single').length-1 ];
                            if( 
                                itemsList[index+1].closest('.pages__single').querySelectorAll('.single__tile--empty').length == 0 && 
                                lastPage.querySelectorAll('.single__tile--empty').length != lastPage.querySelectorAll('.single__tile').length
                            ) {
                                t.addPage();
                            }

                        }
                    });
                }
                if( e.target.classList.contains('tile__content') || e.target.closest('.tile__content') ) {

                    if( !e.target.closest('.content__control') && !e.target.classList.contains('content__button--mask') ) {
                        document.querySelectorAll('.tile__content.clicked').forEach( function( box, index ) {
                            box.classList.remove('clicked');
                        });
                        if( e.target.closest('.tile__content') ) {
                            e.target.closest('.tile__content').classList.add('clicked');
                        } else if( e.target.classList.contains('tile__content') ) {
                            e.target.classList.add('clicked');
                        }

                    } else if( e.target.classList.contains('content__button--mask') ) {
                        e.target.closest('.tile__content').classList.remove('clicked');
                    }
                } else {
                    document.querySelectorAll('.tile__content.clicked').forEach( function( box, index ) {
                        box.classList.remove('clicked');
                    });
                }
            });
        // -- END -- //


        // -- item tile -- // 
            var itemsContainer = document.querySelector('.GeneratorPDF__content .content__cards');
            if( itemsContainer ) {

                document.addEventListener('click', function(e) {
                    if( e.target.classList.contains('control__add') && e.target.closest('.PdfPageTile') ) {
                        t.addTile( e.target.getAttribute('data-url'), e.target.getAttribute('data-title') );
                    }
                    if( e.target.classList.contains('control__zoom') && e.target.closest('.PdfPageTile') ) {
                        t.zoomImage( e.target.getAttribute('data-id'), e.target.getAttribute('data-title') );
                    } // TODO
                    if( e.target.classList.contains('PdfPageTile__image') || e.target.closest('.content__source') ) {
                        t.zoomImage( e.target.closest('.PdfPageTile').querySelector('.control__zoom').getAttribute('data-id') , e.target.closest('.PdfPageTile').querySelector('.control__zoom').getAttribute('data-title') );
                    }
                    if( e.target.classList.contains('control__button--clear') && e.target.closest('.content__cards') ) {
                        itemsContainer.querySelector('.cards__selected .selected__items').innerHTML = '';
                        itemsContainer.querySelector('label[for="display_selected"]').setAttribute('data-items', itemsContainer.querySelectorAll('.cards__selected .PdfPageTile').length );
                        itemsContainer.querySelectorAll('.cards__control button').forEach( function( button, index ) {
                            button.setAttribute('disabled','1');
                        });
                        itemsContainer.querySelectorAll('.PdfPageTile input[name="pdf_page_id"]:checked').forEach( function( input, index ) {
                            input.checked = false;
                        });
                    }
                    if( e.target.classList.contains('control__button--add') && !e.target.hasAttribute('disabled') ) {

                        let data = [];
                        itemsContainer.querySelectorAll('.cards__selected .PdfPageTile').forEach( function( tile, index ) {
                            data.push( {
                                'url' : tile.querySelector('.control__add').getAttribute('data-url'),
                                'title' : tile.querySelector('.control__add').getAttribute('data-title'),
                            });
                        });
                        t.addTiles( data, t.tileID );

                        
                        itemsContainer.querySelectorAll('.cards__selected .PdfPageTile').forEach( function( tile, index ) {
                            tile.remove();
                        });
                        itemsContainer.querySelector('label[for="display_selected"]').setAttribute('data-items', itemsContainer.querySelectorAll('.cards__selected .PdfPageTile').length );
                        itemsContainer.querySelectorAll('.cards__control button').forEach( function( button, index ) {
                            button.setAttribute('disabled','1');
                        });
                        itemsContainer.querySelectorAll('.cards__reload input[name="pdf_page_id"]:checked').forEach( function( checkbox, index ) {
                            checkbox.checked = false;
                        });

                        document.querySelector('.content__cards').classList.remove('active');
                        document.body.classList.remove('disableScroll');

                    }
                    if( e.target.hasAttribute('data-number') && e.target.closest('.Pagination') ) {
                        t.reloadPdfPaged( e.target.getAttribute('data-number') );
                    }
                });
                document.addEventListener('change', function(e) {
                    if( e.target.getAttribute('name') == 'pdf_page_id' ) {
                        if( e.target.checked ) {
                            itemsContainer.querySelector('.cards__selected .selected__items').insertAdjacentHTML('beforeend', e.target.closest('.PdfPageTile').outerHTML );
                            itemsContainer.querySelector('.cards__selected input[value="'+e.target.value+'"]').checked = true;
                        } else {
                            if( itemsContainer.querySelector('.cards__reload input[name="pdf_page_id"][value="'+e.target.value+'"]') ) {
                                itemsContainer.querySelector('.cards__reload input[name="pdf_page_id"][value="'+e.target.value+'"]').checked = false;
                            }
                            if( itemsContainer.querySelector('.cards__selected input[value="'+e.target.value+'"]') ) {
                                itemsContainer.querySelector('.cards__selected input[value="'+e.target.value+'"]').closest('.PdfPageTile').remove();
                            }
                        }

                        itemsContainer.querySelector('label[for="display_selected"]').setAttribute('data-items', itemsContainer.querySelectorAll('.cards__selected .PdfPageTile').length );
                        if( itemsContainer.querySelectorAll('.cards__selected .PdfPageTile').length > 0 ) {
                            itemsContainer.querySelectorAll('.cards__control button').forEach( function( button, index ) {
                                button.removeAttribute('disabled');
                            });
                        } else {
                            itemsContainer.querySelectorAll('.cards__control button').forEach( function( button, index ) {
                                button.setAttribute('disabled','1');
                            });
                        }
                    }
                });
                               
            }
            document.querySelectorAll('input[name="content_topic"]').forEach( function( input, index ) {
                input.addEventListener('change', function() {
                    t.reloadPdfPaged();
                });
            });
            document.querySelectorAll('input[name="content_lang"]').forEach( function(input, index) {
                input.addEventListener('change', function() {
                    t.reloadPdfPaged();
                });
            });
            document.querySelectorAll('input[name="orderby"]').forEach( function( input, index ) {
                input.addEventListener('change', function() {
                    t.reloadPdfPaged();
                });
            });
            if( document.querySelector('input[name="search-term"]') ) {
                let searchTermTimeout = null;
                document.querySelector('input[name="search-term"]').addEventListener('input', () => {
                    clearTimeout(searchTermTimeout); 
                    searchTermTimeout = setTimeout(
                        () => {
                            t.reloadPdfPaged();
                        },
                        500
                    )
                });
            }
        // -- END -- //
        
        

        // -- preview layout -- //
            var previewLayout = document.querySelector('.GeneratorPDF__preview');
            if( previewLayout ) {
                previewLayout.addEventListener('click', function(e) {
                    if( !e.target.closest('.preview__content') && !e.target.classList.contains('preview__content') ) {
                        previewLayout.classList.remove('active');
                        if( 
                            window.innerWidth > 960 || 
                            ( 
                                window.innerWidth < 961 && 
                                !document.querySelector('.content__cards').classList.contains('active') 
                            )
                        ) {
                            document.body.classList.remove('disableScroll');
                        }
                    }
                });
                previewLayout.querySelector('.row__close').addEventListener('click', function() {
                    previewLayout.classList.remove('active');
                    if( 
                        window.innerWidth > 960 || 
                        ( 
                            window.innerWidth < 961 && 
                            !document.querySelector('.content__cards').classList.contains('active') 
                        )
                    ) {
                        document.body.classList.remove('disableScroll');
                    }
                });
            }
        // -- END -- //
        
    },
    addPage() {
        var t = this;

        let size = document.querySelector('input[type=radio][name="pdf_size"]:checked').value;
        let doc = document.querySelector('.GeneratorPDF__content .content__doc');
        let pagesContent = doc.querySelector('.GeneratorPDF__content .doc__pages .pages__content');

        let page = document.querySelector('.GeneratorPDF__content .content__schema .pages__single').cloneNode( true );
        page.setAttribute('data-items', size);
        page.classList.add('pages__single--'+size);
    
        // -- add cover -- //
            if( pagesContent.querySelectorAll('.pages__single').length == 0 ) {
                page.querySelector('.single__grid').insertAdjacentHTML('afterbegin', document.querySelector('.GeneratorPDF__content .content__schema .single__tile--cover').outerHTML );
                size = size - 1;
            }
        // -- END -- //

        // -- add tiles -- //
            for( let index = 0; index < size; index++) {
                page.querySelector('.single__grid').insertAdjacentHTML('beforeend' , document.querySelector('.GeneratorPDF__content .content__schema .single__tile:not(.single__tile--cover)').outerHTML );
            }
        // -- END -- //

        // -- set tiles unique id -- //
            page.querySelectorAll('.single__tile:not(.single__tile--cover)').forEach( function( tile, index ) {
                tile.setAttribute('data-id', ( Date.now() + Math.floor(Math.random() * 100) ) );
            });
        // -- END -- //
        page.querySelectorAll('.single__tile:not(.single__tile--cover)').forEach( function( tile, index ) {
            tile.setAttribute( 'data-id', Math.floor(Math.random() * Math.floor(Math.random() * Date.now())) );
            if( doc.classList.contains('sticky') && window.innerWidth > 960 ) {
                tile.querySelector('.tile__content').style.paddingBottom = ( 12000 / doc.querySelector('.single__tile').offsetWidth ) + '%';
            }
        });
        pagesContent.insertAdjacentHTML( 'beforeend', page.outerHTML );
        t.coverScale();

    },
    removePage( page = null ){ // page is node element (.page__single)
        let t = this;
        let arr = Array.prototype.slice.call( document.querySelectorAll('.doc__pages .pages__single') );
        if( 
            page && 
            page.querySelectorAll('.single__tile--empty').length == page.querySelectorAll('.single__tile').length &&
            arr.indexOf( page ) != document.querySelectorAll('.doc__pages .pages__single').length - 1
        ) {
            page.remove();
        }
    },
    stickyPage() {
        var t = this;
        var doc = document.querySelector('.GeneratorPDF__content .content__doc');
        var timeInterval = 0;
        var obs = new IntersectionObserver( 
            function( entries, observer ) {

                entries.forEach( function( entry, index ) {
                    if( entry.time - timeInterval > 20 )  {
                        if( entry.intersectionRatio < 1 && !entry.isIntersecting && window.scrollY > 0 ) {
                            doc.classList.add('sticky');
                        } else {
                            doc.classList.remove('sticky');
                        }
                        t.setTileSpace();
                    } else {
                        doc.classList.add('sticky');
                    }
                    timeInterval = entry.time;
                });

            }, 
            {
                threshold: 0
            }
        );
        obs.observe( document.querySelector('.GeneratorPDF__content .content__print') );

        window.addEventListener('resize', function() {
            t.setTileSpace();
        }); 

    },
    setTileSpace() {
        var doc = document.querySelector('.GeneratorPDF__content .content__doc');
        var tiles = doc.querySelectorAll('.single__tile .tile__content');
        if( doc.classList.contains('sticky') ) {
            tiles.forEach( function( tile, index ) {
                tile.style.paddingBottom = ( 12000 / tile.offsetWidth ) + '%';
            });
        } else {
            tiles.forEach( function( tile, index ) {
                tile.style.paddingBottom = '';
            });
        }

    },
    addTiles( data = [], dataID = null ) { // add a group of tiles
        // data -> array
            // key -> json
                // title
                // url
        let t = this;
        let startTileFound = false;
        let dataIndex = 0;

        let tempData = []; // layout as data
        let tempDataID = null;

        let tiles = document.querySelectorAll('.GeneratorPDF__content .content__doc .doc__pages .single__grid .single__tile'); // list of all tiles
        let tilesEmpty = document.querySelectorAll('.GeneratorPDF__content .content__doc .doc__pages .single__grid .single__tile--empty') // list of all empty tiles


        // -- add new pages before add data -- //
            if( data.length > tilesEmpty.length ) {
                for( let index = 0; index < Math.round( ( data.length - tilesEmpty.length ) / document.querySelector('input[name="pdf_size"]:checked').value ) ; index++) {
                    t.addPage();
                }
                tiles = document.querySelectorAll('.GeneratorPDF__content .content__doc .doc__pages .single__grid .single__tile');
            }
        // -- END -- //
        
        tiles.forEach( function( tile, index ) {
            if( tile.getAttribute('data-id') == dataID ) {
                startTileFound = true;
            }
            if( startTileFound ) {

                if( data[dataIndex] ) {
                    if( !tile.classList.contains('single__tile--empty') ) {
                        tempData.push({
                            'url' : tile.querySelector('.source__display').getAttribute('src'),
                            'title' : tile.querySelector('.tile__content').getAttribute('data-title'),
                        });
                    }
                    t.addTile(
                        data[dataIndex]['url'],
                        data[dataIndex]['title'],
                        tile.getAttribute('data-id'),
                    );
                    dataIndex++;
                    if( tiles[index+1] ) {
                        tempDataID = tiles[index+1].getAttribute('data-id');
                    }
                }
            }
        });

        if( tempData.length > 0 ) {
            t.addTiles( tempData, tempDataID );
        }
        
    },
    addTile( url = null, title = null, dataID = null ) {
        var t = this;
        if( !url ) {
            return;
        }
        let pagesTileSelectedItem = document.querySelector('.doc__pages .single__tile .content__source .source__display[src=""]');
        if( dataID ) {
            let tempElement = document.querySelector('.doc__pages .single__tile[data-id="'+dataID+'"]');
            if( tempElement ) {
                pagesTileSelectedItem = tempElement.querySelector('.content__source .source__display');
            }
        }
        if( pagesTileSelectedItem ) {
            
            let arr = Array.prototype.slice.call( document.querySelectorAll('.doc__pages .pages__single') );
            t.pagesCurrent = arr.indexOf( pagesTileSelectedItem.closest('.pages__single') );

            document.querySelector('.doc__pages .pages__content').scroll({
                left: t.pagesCurrent * document.querySelector('.doc__pages .pages__single').offsetWidth,
                behavior: 'smooth'
            });

            pagesTileSelectedItem.setAttribute('src', url);
            pagesTileSelectedItem.closest('.content__source').querySelector('.source__drag').setAttribute('src', url);
            pagesTileSelectedItem.closest('.tile__content').setAttribute('data-title',title)
            pagesTileSelectedItem.closest('.single__tile').classList.remove('single__tile--empty');
            
            let lastPage = document.querySelectorAll('.doc__pages .pages__single')[ document.querySelectorAll('.doc__pages .pages__single').length-1 ];
            if( 
                pagesTileSelectedItem.closest('.pages__single').querySelectorAll('.single__tile--empty').length == 0 &&
                lastPage.querySelectorAll('.single__tile--empty').length == 0
            ) {
                t.addPage();
                document.querySelector('.doc__pages .pages__content').scroll({
                    left: ( t.pagesCurrent + 1 ) * document.querySelector('.doc__pages .pages__single').offsetWidth,
                    behavior: 'smooth'
                });
                
            }

        } else {
            t.addPage();
            t.addTile( url, title );
        }
    },
    reloadPdfPaged( paged = 1 ) {
        var t = this;

        let reloadItem = document.querySelector('.GeneratorPDF__content .content__cards .cards__reload');
        reloadItem.classList.add('reload');
        let args = [];
        args.push( 'page_num='+paged );

        if( document.querySelectorAll('input[name="content_topic"]:checked').length > 0 ) {
            let categories = [];
            document.querySelectorAll('input[name="content_topic"]:checked').forEach( function( input, index ) {
                categories.push( input.value );
            });
            args.push( 'content-topic='+categories.join(',') );
        }
        if( document.querySelectorAll('input[name="content_lang"]:checked').length > 0 ) {
            let categories = [];
            document.querySelectorAll('input[name="content_lang"]:checked').forEach( function( input, index ) {
                categories.push( input.value );
            });
            args.push( 'content-lang=' + categories.join(',') );
        }
        if( document.querySelector('input[name="orderby"]:checked') ) {
            args.push( 'orderby=' + document.querySelector('input[name="orderby"]:checked').value );
        }
        if( document.querySelector('input[name="search-term"]') ) {
            args.push( 'search-term='+document.querySelector('.content__cards input[name="search-term"]').value );
        }
        args = '?'+args.join('&');

        fetch( window.location.origin+window.location.pathname+args, {
            method: 'get',
        })
        .then(res => res.text())
        .then(response => {

            var html = document.createElement('div');
            html.innerHTML = response;
            document.querySelector('.GeneratorPDF__content .content__cards .cards__reload').innerHTML = html.querySelector('.GeneratorPDF__content .content__cards .cards__reload').innerHTML;
            document.querySelectorAll('.GeneratorPDF__content .content__cards .cards__selected input[name="pdf_page_id"]').forEach( function( input ,index ) {
                let tileIntoReload = document.querySelector('.GeneratorPDF__content .cards__reload input[name="pdf_page_id"][value="'+input.value+'"]');
                if( tileIntoReload ) {
                    tileIntoReload.checked = true;
                }
                
            });
            LazyLoad.init();   
            reloadItem.classList.remove('reload');

        }).catch(function(err) {
            reloadItem.classList.remove('reload');
            //console.error(` Err: ${err}`);
        });
    },
    zoomImage( attachID = null, title ) {
        if( !attachID ) {
            return;
        }
        var previewLayout = document.querySelector('.GeneratorPDF__preview');
        previewLayout.querySelector('.row__title').textContent = title;
        previewLayout.querySelector('.image__layout').innerHTML = null;
        previewLayout.classList.add('active');

        fetch( window.location.origin+'/wp-admin/admin-ajax.php?action=generatorPreview&att_id='+attachID, {
            method: "GET",
        })
        .then( function( response ){
            if( document.body.classList.contains('logged-in') ) {
                console.log(response );
            }
            return response.text();
        })
        .then( function( html ) {
            if( html ) {
                previewLayout.querySelector('.image__layout').innerHTML = html;
                document.body.classList.add('disableScroll');
                LazyLoad.init();
            }
        })
        .catch( function( error ) {
            if( document.body.classList.contains('logged-in') ) {
                console.log('ZoomImageError');
                console.log( error );
            }
        });
    },
    drag() {
        let t = this;
        var doc = document.querySelector('.doc__pages .pages__content');
        var dragElement = null;
        var dropElement = null;
        var tempSrc = '';
        var tempTitle = '';

        document.addEventListener('dragstart', function(e) {
            tempSrc = '';
            if( e.target.classList.contains('PdfPageTile__image') || e.target.closest('.content__source') ) {

                if( e.target.classList.contains('PdfPageTile__image') ) {
                    e.dataTransfer.setDragImage( e.target.querySelector('.image__drag') , 0, 0);
                } else {
                    e.dataTransfer.setDragImage( e.target.closest('.content__source').querySelector('.source__drag') , 0, 0);
                }                
                dragElement = e.target;
            }
        });
        doc.addEventListener('dragover', function(e) {
            e.preventDefault();
        });
        doc.addEventListener('dragenter', function(e) {
            if( e.target.closest('.single__tile--empty') && dragElement ) {
                e.target.closest('.single__tile--empty').classList.add('single__tile--empty-over');
            }
        });
        doc.addEventListener('dragleave', function(e) {
            if( e.target.closest('.single__tile--empty') ) {
                e.target.closest('.single__tile--empty').classList.remove('single__tile--empty-over');
            }
        });
        doc.addEventListener('drop', function(e) {
            e.preventDefault();
            if( dragElement && e.target.closest('.single__tile:not(.single__tile--cover)') ) {
                dropElement = e.target.closest('.single__tile:not(.single__tile--cover)');

                if( dragElement.closest('.content__source') || dragElement.closest('.content__control') ) {
                    if( dropElement.querySelector('.content__source .source__display').getAttribute('src') ) {
                        tempSrc = dropElement.querySelector('.content__source .source__display').getAttribute('src');
                        tempTitle = dropElement.querySelector('.tile__content').getAttribute('data-title');
                    }
                    if( dragElement.getAttribute('src') ) {
                        dropElement.classList.remove('single__tile--empty');
                    }
                    dropElement.querySelectorAll('.content__source img').forEach( function(image,index){
                        image.setAttribute( 'src', dragElement.getAttribute('src') );
                    } );

                    dropElement.querySelector('.tile__content').setAttribute('data-title', dragElement.closest('.tile__content').getAttribute('data-title') );
                    if( !tempSrc ) {
                        dragElement.closest('.single__tile').classList.add('single__tile--empty');
                    }
                    dragElement.setAttribute('src', tempSrc);
                    dragElement.closest('.tile__content').setAttribute('data-title', tempTitle);
                } else {
                    dropElement.querySelector('.tile__content').setAttribute('data-title', dragElement.getAttribute('data-title') );
                    dropElement.querySelectorAll('.content__source img').forEach( function(image,index) {
                        image.setAttribute( 'src', dragElement.getAttribute('data-url') );
                    } );

                    dropElement.classList.remove('single__tile--empty');
                }
                let lastPage = document.querySelectorAll('.doc__pages .pages__single')[ document.querySelectorAll('.doc__pages .pages__single').length-1 ];
                if( 
                    dropElement.closest('.pages__single').querySelectorAll('.single__tile--empty').length == 0  &&
                    lastPage.querySelectorAll('.single__tile--empty').length != lastPage.querySelectorAll('.single__tile').length
                ) {
                    t.addPage();
                }
                t.removePage( dragElement.closest('.pages__single') );
            }
            tempSrc = null;
            tempTitle = null;
            document.querySelectorAll('.single__tile--empty-over').forEach( function(element, index) {
                element.classList.remove('single__tile--empty-over');
            });
        });
    },
    getContent( index = 0 ) {
        var t = this;
        var current = index;


            
        var formData = new FormData();        
        formData.append('action', 'generatorPdfContent');
        formData.append('ipp', document.querySelector('input[name="pdf_size"]:checked').value ); // items per page
        formData.append('width', document.querySelector('input[name="pdf_size"]:checked').getAttribute('data-width') );
        formData.append('height', document.querySelector('input[name="pdf_size"]:checked').getAttribute('data-height') );
        if( t.pages[current]['url'] === null ) {
            formData.append('id', document.querySelector('main').getAttribute('data-id') ); // page id
            formData.append('title', document.querySelector('input[name="title"]').value);
            formData.append('logo', document.querySelector('.single__tile--cover .logo__source img').getAttribute('src') );
        } else {
            formData.append('url', t.pages[current]['url'] );
        }

        fetch( window.location.origin+'/wp-admin/admin-ajax.php' , {
            method: 'POST',
            body: formData,
        })
        .then( function( response ) {
            return response.text();
        })
        .then( function( html ) {
            if( html ) {
                let tile = document.createElement('div');
                tile.setAttribute('id', t.pages[current]['id']);
                tile.innerHTML = html;
                document.querySelector('.GeneratorPDF__content .content__print').insertAdjacentElement('beforeend', tile);
            }
            current = current+1;
            if( document.body.classList.contains('logged-in') ) {
                console.log( 'GetContent:' + current+'/'+t.pages.length );
                console.log( 'AllAction:' + current+'/'+t.pages.length*2 );
            }
            t.setLoader( current );
            if( t.pages[current] ) {
                t.getContent( current );
            } else {
                t.getPDF();
            }
        }) 
        .catch( function( error ) {
            if( document.body.classList.contains('logged-in') ) {
                console.log( 'getContent - ERROR' );
                console.log( error );
            }
            t.callback({
                'message' : document.querySelector('.generate-pdf').getAttribute('data-error'),
                'element' : document.querySelector('.doc__control'),
                'position' : 'afterbegin',
            });
            document.querySelector('.generate-pdf').removeAttribute('disabled');
            setTimeout(function(){
                t.setLoader(0);
            }, 200);
        });
    },
    getPDF() {
        var t = this;
        let counter = 0;
        let gap = 4;
        window.html2canvas = html2canvas;

        let docWidth = parseFloat( document.querySelector('input[name="pdf_size"]:checked').getAttribute('data-width') ) * 2.54 / 0.02645833;
        let tileWidth = ( docWidth / document.querySelector('input[name="pdf_size"]:checked').value ) - ( (document.querySelector('input[name="pdf_size"]:checked').value - 1) * gap / document.querySelector('input[name="pdf_size"]:checked').value );
        let docHeight = parseFloat( document.querySelector('input[name="pdf_size"]:checked').getAttribute('data-height') ) * 2.54 / 0.02645833;

        let pdfPageArgs = {
            'format' : [
                docWidth,
                docHeight
            ],
            'unit' : 'px',
            'orientation' : 'l'
        }

        var pdf = new jsPDF(pdfPageArgs);
        pdf.setProperties({
            author: t.fileAuthor,
        })
        pdf.setFillColor( '#F3F8FB' );
        pdf.rect(0, 0, docWidth, docHeight, "F");
        
        t.pages.forEach( function( row, index ) {
            
            html2canvas( document.getElementById(row['id']), {
                useCORS: true,
                allowTaint : true,
                letterRendering: true,
                windowWidth: tileWidth,
                windowHeight: docHeight,
                height: docHeight,
                width: tileWidth,
                scale: ( docHeight / tileWidth ),
            } )
            .then( function( canvas ) {

                t.pages[index]['canvas'] = canvas.toDataURL('image/jpeg','JPEG');
                counter +=1;

                if( document.body.classList.contains('logged-in') ) {
                    console.log( 'GetPDF:' + counter+'/'+t.pages.length );
                    console.log( 'AllAction:' + (counter + t.pages.length) +'/'+t.pages.length * 2 );
                }

                t.setLoader( counter + t.pages.length );
                
                if( counter == t.pages.length ) {

                    t.pages.forEach( function( row2, index2 ) {

                        if( index2%document.querySelector('input[name="pdf_size"]:checked').value == 0 && index2 !=0 ) {
                            pdf.addPage(pdfPageArgs);
                            pdf.setFillColor( '#F3F8FB' );
                            pdf.rect(0, 0, docWidth, docHeight, "F");
                        }
                        pdf.addImage( 
                            t.pages[index2]['canvas'], // imageData
                            'JPEG', // format
                            ( row2['col'] * tileWidth ) + ( gap * row2['col'] ), //X
                            0, // Y
                            tileWidth,  //width
                            docHeight  // height
                        );

                         // -- add link -- //
                            let link = document.querySelector('.doc__pages .single__tile--cover a');
                            if( link && index2 == 0 ) {
                                pdf.link(
                                    link.offsetLeft,
                                    link.offsetTop,
                                    link.offsetWidth,
                                    link.offsetHeight,
                                    {
                                        url : link.getAttribute('href'),
                                    }
                                );
                            }
                        // -- END -- //

                    });

                    pdf.save('card.pdf');
                    document.querySelector('.generate-pdf').removeAttribute('disabled');
                    setTimeout(function(){
                        t.setLoader(0);
                    }, 200);
                        
                }
            })
            .catch( function( error ) {
                if( document.body.classList.contains('logged-in') ) {
                    console.log( 'getPDF - ERROR' );
                    console.log( error );
                }
                t.callback({
                    'message' : document.querySelector('.generate-pdf').getAttribute('data-error'),
                    'element' : document.querySelector('.doc__control'),
                    'position' : 'afterbegin',
                });
                document.querySelector('.generate-pdf').removeAttribute('disabled');
                setTimeout(function(){
                    t.setLoader(0);
                }, 200);
            });

        });
        
    },
    setLoader( index = 0 ) {
        index = index/2;
        let allElements = document.querySelectorAll('.doc__pages .single__tile').length;
        let percent = Math.round(index * 100 / allElements);
        document.querySelector('.generate-pdf').setAttribute('data-percent', percent );
    },
    coverScale() {
        document.querySelectorAll('.pages__single .single__tile--cover').forEach( function( cover, index ) {
            cover.querySelector('.content__cover').style.transform = 'scale('+ ( cover.querySelector('.tile__content').offsetWidth/cover.querySelector('.content__cover').offsetWidth ) +')';
        });
    },
    callback( options = {} ) {
        if( !options['status'] ) { options['status'] = 'error' };
        if( !options['position'] ) { options['position'] = 'afterend' };
        document.querySelectorAll('.GeneratorPDF__callback').forEach( function( single, index ) {
            single.remove();
        });
        if( options['message'] && options['element'] ) {
            options['element'].insertAdjacentHTML( options['position'], '<p class="GeneratorPDF__callback '+options['status']+'">'+ options['message'] +'</p>' );
        }
    },
    finalize() {}
}