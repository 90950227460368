export default {
	init() {

		var filters = document.querySelectorAll('.FilterSearchSelect');
		if( filters.length > 0 ) {	
			var selectTimeout;

			window.addEventListener('click', function() {
				filters.forEach( function( single,index ) {
					single.querySelector('.FilterSearchSelect__button').classList.remove('active');
				});
			});

			filters.forEach( function( single,index ) {

				single.addEventListener('click', function( e ) {
					e.stopPropagation();
				});

				single.querySelector('.FilterSearchSelect__button').addEventListener('click', function() {
					single.querySelector('.FilterSearchSelect__button').classList.toggle('active');
				});

				single.querySelector('.row__button--clear').addEventListener('click', function() {
					single.querySelector('.FilterLabel__select').classList.remove('clicked');
					single.querySelectorAll('input[type="checkbox"]').forEach( function( input,index2 ) {
						input.checked = false;
					});
					single.querySelector('.button__value').innerHTML = '<span>'+single.querySelector('.button__value').getAttribute('data-default')+'</span>';
				});

				single.querySelector('.row__button--apply').addEventListener('click', function() {
					single.querySelector('.FilterSearchSelect__button').classList.remove('active');
				});



				single.querySelector('.search__input').addEventListener('input', function() {

					if( single.querySelector('.search__input').value ) {

						clearTimeout( selectTimeout );
						selectTimeout = null
						selectTimeout = setTimeout(function() {

							single.querySelectorAll('input[type="checkbox"]').forEach( function(input,index2) {
								
								if( input.getAttribute('data-name').toLowerCase().indexOf( single.querySelector('.search__input').value.toLowerCase() ) >= 0 ) {
									input.closest('.list__single').classList.remove('deactive');
								} else {
									input.closest('.list__single').classList.add('deactive');
								}

							});	

						}, 200);

					} else {

						single.querySelectorAll('input[type="checkbox"]').forEach( function( input,index2 ) {
							input.closest('.list__single').classList.remove('deactive');
						});

					}

				});

				single.querySelectorAll('input[type="checkbox"]').forEach( function( checkbox, index2 ) {
					checkbox.addEventListener('change', function() {
						let tempValue = '<span>'+single.querySelector('.button__value').getAttribute('data-default')+'</span>';

						if(  single.querySelectorAll('input[type="checkbox"]').length > 0 ) {
							tempValue = '<span>'+single.querySelector('input[type="checkbox"]:checked').getAttribute('data-name')+'</span>';
							if( single.querySelectorAll('input[type="checkbox"]:checked').length > 1 ) {
								tempValue += '<span> +'+(single.querySelectorAll('input[type="checkbox"]:checked').length-1)+' '+single.querySelector('.button__value').getAttribute('data-more')+'</span>';
							} 
						}
						single.querySelector('.button__value').innerHTML = tempValue;

					});	
				});

				if( single.querySelector('.FilterLabel__select') ) {
					single.querySelector('.FilterLabel__select').addEventListener('click', function() {

						if( single.querySelector('.FilterLabel__select').classList.contains('clicked') ) {
							single.querySelector('.FilterLabel__select').classList.remove('clicked');
							single.querySelectorAll('input[type="checkbox"]').forEach( function( input,index2 ) {
								input.checked = false;
							});
						} else {
							single.querySelector('.FilterLabel__select').classList.add('clicked');
							single.querySelectorAll('input[type="checkbox"]').forEach( function( input,index2 ) {
								input.checked = true;
							});
						}
						single.querySelector('.button__value').innerHTML = '<span>'+single.querySelector('.button__value').getAttribute('data-default')+'</span>';

					});
				}

			});
		}

	},
	finalize() {

	}
}