import Lightpick from 'lightpick';
import $ from 'jquery';
import lozad from 'lozad';
import { google, outlook, ics } from "calendar-link";


export default {

	init() {

		var container = document.querySelector('main.TrainingsArchive');

		if( container ) {

			var calendar = container.querySelector('section.TrainingsArchive__calendar');

			// -- change date (calendar) -- //

				var calendarContainer = calendar.querySelector('section.TrainingsArchive__calendar .row__calendar');
				var calendarFieldStart = calendarContainer.querySelector('input[name="date-start"]');
				var calendarFieldEnd = calendarContainer.querySelector('input[name="date-end"]');
				var calendarButton = calendarContainer.querySelector('.calendar__button');

				var picker = new Lightpick({
					field : document.getElementById('date-start'),
					secondField : document.getElementById('date-end'),
					singleDate : false,
					lang : 'en',
					onSelect: function(start, end) {

						if( start ) {

							calendar.querySelector('.date__from').textContent = start.format('MM/DD/YYYY');

						}

						if( end ) {

							calendar.querySelector('.date__to').textContent = end.format('MM/DD/YYYY');
							
						}

				    },
				    onClose: function() {

				    	reloadContent();
				    }

				});

				calendarButton.addEventListener('click', function() {

					picker.show();

				});

			// -- END -- //



			// -- change date (arrows) -- //

				var buttonDatePrev = calendar.querySelector('.calendar__row .row__button--prev');
				var buttonDateNext = calendar.querySelector('.calendar__row .row__button--next');

				var dateFrom = calendar.querySelector('.date__from');
				var dateTo = calendar.querySelector('.date__to');
				var dateStart = calendar.querySelector('#date-start');
				var dateEnd = calendar.querySelector('#date-end');

				buttonDatePrev.addEventListener('click', function() {

					let newDate = new Date( dateFrom.textContent );
					newDate.setDate( newDate.getDate() - 7 );

					dateEnd.value = dateStart.value
					dateStart.value = newDate.getDate()+'/'+(newDate.getMonth()+1)+'/'+newDate.getFullYear();

					dateTo.textContent = dateFrom.textContent;
					dateFrom.textContent = (newDate.getMonth()+1)+'/'+newDate.getDate()+'/'+newDate.getFullYear();

					reloadContent();

				});

				buttonDateNext.addEventListener('click', function() {

					let newDate = new Date( dateFrom.textContent );
					newDate.setDate( newDate.getDate() + 7 );

					dateStart.value = newDate.getDate()+'/'+(newDate.getMonth()+1)+'/'+newDate.getFullYear();
					dateFrom.textContent = (newDate.getMonth()+1)+'/'+newDate.getDate()+'/'+newDate.getFullYear();
	
					newDate.setDate( newDate.getDate() + 7 );

					dateEnd.value = newDate.getDate()+'/'+(newDate.getMonth()+1)+'/'+newDate.getFullYear();
					dateTo.textContent = (newDate.getMonth()+1)+'/'+newDate.getDate()+'/'+newDate.getFullYear();

					reloadContent();

				});	

			// -- END -- //



			// -- filters - button -- //

				var buttonsFilterContainer = container.querySelectorAll('.filter__buttons');

				buttonsFilterContainer.forEach( function( box, index1 ) {

					box.querySelectorAll('button').forEach( function( button,index2 ) {

						button.addEventListener('click', function() {

							if( box.classList.contains('filter__buttons--active') ) {
								box.classList.remove('filter__buttons--active');
							}

							if( button.classList.contains('active') ) {

								button.classList.remove('active');

							} else {

								button.classList.add('active');

							}

							reloadContent();

						});

					});

				});

			// -- END -- //



			// -- filters - select -- //

				var selects = container.querySelectorAll('.content__filter select');

				selects.forEach( function( select,index ) {

					select.addEventListener('change', function() {

						select.closest('.filter__select').setAttribute('data-text', select.querySelector('option[value="'+select.value+'"]').text ) ;

						reloadContent();

					});

				});

			// -- END -- //



			// -- filters open/close -- //

				var filterSection = container.querySelector('section.TrainingsArchive__filters');
				var filterButton = filterSection.querySelector('.filters__button');
				var filterContent = filterSection.querySelector('.filters__content');
				var filterContentAbsolute = filterSection.querySelector('.filters__content--absolute');

				// filterContent.style.height = filterContentAbsolute.offsetHeight + 'px';
				// filterContentAbsolute.classList.remove('.filters__content--relative');

				filterButton.addEventListener('click', function() {

					if( filterButton.classList.contains('active') ) {

						filterButton.classList.remove('active');
						filterContent.style.height = '0px';

					} else {

						filterButton.classList.add('active');
						filterContent.style.height = filterContentAbsolute.offsetHeight + 'px';

					}

				});

				window.addEventListener('resize', function() {

					if( filterButton.classList.contains('active') )	{
						filterContent.style.height = filterContentAbsolute.offsetHeight + 'px';
					}

				});

			// -- END -- //


			// -- load more button -- // 

				document.addEventListener('click', e => {

					if( e.target.classList.contains('list__more') || e.target.closest('.list__more') ) {

						reloadContent( parseInt( document.querySelector('.list__more').getAttribute('data-paged') ) + 1 );

					}					

				});

			// -- END -- //


			// -- history push -- //

				let newUrl = '';
				function PushStateToHistory( stringArgs ) {

					newUrl = window.location.origin+window.location.pathname+'?'+stringArgs;

					window.history.pushState( { } ,container.getAttribute('data-title'), newUrl );

				}

			// -- END -- //



			// -- reload content -- //

				var args = [];
				var requestData = [];
				var request = new XMLHttpRequest();
				var response;
				function reloadContent( paged = 1 ) {

					args = [];

					buttonsFilterContainer.forEach( function( box,index ) {

						let buttonArgs = [];

						if( box.classList.contains('filter__buttons--active') ) {

							box.querySelectorAll('button').forEach( function( button, index1 ) {

								buttonArgs.push( button.getAttribute('value') )

							});

						} else {

							box.querySelectorAll('button').forEach( function( button, index1 ) {

								if( button.classList.contains('active') )  {
									buttonArgs.push( button.getAttribute('value') )
								}

							});

						}

						args.push( box.getAttribute('data-name')+'='+ buttonArgs.join(',') );

					});	

					selects.forEach( function( select,index ) {

						if( select.value != '' ) {

							args.push( select.getAttribute('name')+'='+select.value )

						}

					});

					let dateStartArray = container.querySelector('#date-start').value.split('/');
					let dateEndArray = container.querySelector('#date-end').value.split('/');

					args.push( 'date_start=' + dateStartArray[1]+'/'+dateStartArray[0]+'/'+dateStartArray[2] );
					args.push( 'date_end=' + dateEndArray[1]+'/'+dateEndArray[0]+'/'+dateEndArray[2] );

					if( paged > 1 ) {
						args.push( 'page_num='+paged );
					}

					args = args.join('&');

					PushStateToHistory( args );

					request.onreadystatechange = function() {

						if( request.readyState == 4 && request.status == 200 ) {

							var xmlDoc = document.implementation.createHTMLDocument("example");
							xmlDoc.documentElement.innerHTML = request.responseText;

							container.querySelector('.TrainingsArchive__list .row').remove();

							container.querySelector('.TrainingsArchive__list').append( xmlDoc.querySelector('.TrainingsArchive__list .row') );

							var pictureObserverAfterLoadDOM = lozad('.lazy-load-item', {
				    			threshold: 0,
				                loaded: function(el) {
				                    el.classList.add('asset-loaded');
				                }
							});
							pictureObserverAfterLoadDOM.observe();
							generateCalendarLink();
							
						}

					}

					// Set up our request
					request.open('POST', window.location.href );

					// Add the required HTTP header for form data POST requests
					request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

					// Finally, send our data.
					request.send(args);


				}

			// -- END -- //


			// -- generate calendar link -- //

				function generateCalendarLink() {

					container.querySelectorAll('.TrainingsArchive__list .list__single').forEach( function(single,index) {

						let event = {
							title : single.getAttribute('data-title'),
							start : single.getAttribute('data-start'),
							end : single.getAttribute('data-end'),
							duration : [ single.getAttribute('data-duration'),'hours' ]
						}

						single.querySelector('.outlook_calendar').setAttribute( 'href', outlook( event ) );
						single.querySelector('.google_calendar').setAttribute( 'href', google( event ) );
						single.querySelector('.i_calendar').setAttribute('href', ics( event ) );

					});

				}
				generateCalendarLink();

			// -- END -- //

		}

	},
	finalize() {

	}

}