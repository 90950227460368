import ShareBox from '../ShareBox/ShareBox';
export default {
	init() {
		
		var endpointUrl = document.querySelector('Footer').getAttribute('data-endpoint');
		ShareBox.init();
		document.addEventListener('click', function(e) {

			if( e.target.classList.contains('ResourcesItem__counter') && e.target.closest('.ResourcesItem').getAttribute('data-id') ) {

				let request = null;
			    request = new XMLHttpRequest();
			    request.open( "POST", endpointUrl , true );
			    // request.onreadystatechange = function() {
			    //     console.log( request );
			    // }
			    request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
			    request.send( 'action=resourcesItemPopularyCounter&resourcesID='+e.target.closest('.ResourcesItem').getAttribute('data-id') );

			}

		});

	},
	finalize() {

	}
}