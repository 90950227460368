import smoothscroll from 'smoothscroll-polyfill';
export default {
    init() {
        var t = this;
        var containers = document.querySelectorAll('.CarouselHero');
        if( containers.length > 0 ) {
            containers.forEach( function( box, index ) {
                t.carouselDesktop( box );
                t.carouselMobile( box );
            });
        }
    },
    finalize() {},
    // -- carousel desktop -- //
        carouselDesktop( container , gIndex ) {
            let t = this;
            if( container ) {
                var interval = null;
                container.querySelectorAll('.CarouselHero__desktop .control__single').forEach( function( btn, index ) {
                    btn.addEventListener('click', function() {
                        t.loop( container, parseInt( btn.getAttribute('data-index') ) );
                        clearInterval( interval );
                    });
                });

                container.querySelector('.CarouselHero__desktop').addEventListener('mouseenter', function() {
                    clearInterval( interval );
                    interval = null;
                });
                container.querySelector('.CarouselHero__desktop').addEventListener('mouseleave', function() {
                    clearInterval( interval );
                    interval = setInterval(
                        () => {
                            t.loop( container );
                        }, 
                        (container.getAttribute('data-interval') * 1000) 
                    );
                });

                interval = setInterval(
                    () => {
                        t.loop( container );
                    }, 
                    (container.getAttribute('data-interval') * 1000) 
                );

            }
        },
        loop( container, cIndex = null ) {

            if( cIndex == null ) {
                cIndex = parseInt( container.querySelector('.CarouselHero__desktop .control__single.active').getAttribute('data-index') ) + 1;
            }
            if( cIndex >= container.querySelectorAll('.CarouselHero__desktop .carousel__single').length ) {
                cIndex = 0;
            }
            container.querySelectorAll('.CarouselHero__desktop .carousel__single').forEach( function( slide, index ) {
                if( cIndex != index ) {
                    slide.classList.remove('active');
                } else {
                    slide.classList.add('active');
                }
            });
            container.querySelectorAll('.CarouselHero__desktop .control__single').forEach( function( btn, index ) {
                if( cIndex != index ) {
                    btn.classList.remove('active');
                } else {
                    btn.classList.add('active');
                }
            });
            
        },
    // -- END -- //
    
    // -- carousel mobile -- //
        carouselMobile( container ) {

            smoothscroll.polyfill();

            let carousel = container.querySelector('.CarouselHero__mobile .mobile__carousel');
            let cBtn = container.querySelectorAll('.CarouselHero__mobile .control__single');

            carousel.addEventListener('scroll', function() {

                cBtn.forEach( function( btn, index ) {
                    if( index != Math.round( carousel.scrollLeft / carousel.offsetWidth ) ) {
                        btn.classList.remove('active');
                    } else {
                        btn.classList.add('active');
                    }
                });

            });
            cBtn.forEach( function( btn, index ) {
                btn.addEventListener('click', function() {

                    cBtn.forEach( function( btn2, index2 ) {
                        btn2.classList.remove('active');
                    });
                    btn.classList.add('active');
                    
                    carousel.scrollTo({ 
                        left: carousel.offsetWidth * parseInt( btn.getAttribute('data-index') ), 
                        behavior: 'smooth'
                    });

                });
            });
        }
    // -- END -- //
}