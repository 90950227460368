import FilterList from '../../Components/FilterList/FilterList.js';
import FilterSelect from '../../Components/FilterSelect/FilterSelect.js';
import CourseTile from '../../Components/CourseTile/CourseTile.js';
import LazyLoad from '../../../common/scripts/lazyLoad';
export default {
    init() {
        var container = document.querySelector('main.SuggestedTrainingsTopic2');
        if( container ) {
            FilterList.init();
            FilterSelect.init();
            CourseTile.init();
            LazyLoad.init();

            var t = this;

            var timeInput = null;
            container.querySelectorAll('.FilterList .content__list input').forEach( ( input, index ) => {
                input.addEventListener('change', () => {
                    if( document.querySelector('main').getAttribute('data-layout') == 'list' && input.getAttribute('name') == 'topic' ) {
                        t.setElementHeight( input.value, true );
                        t.setHisotry();
                    } else {
                        clearTimeout( timeInput );
                        timeInput = setTimeout(
                            () => {
                                t.reloadContent();
                            },
                            500
                        );
                    }
                })
            });
            container.querySelectorAll('input[name="layout"]').forEach( ( input, index )=> {
                input.addEventListener('change', () => {
                    document.querySelector('main').setAttribute( 'data-layout', input.value );
                    t.reloadContent();
                });
            });
            container.querySelectorAll('input[name="orderby"]').forEach( ( input, index ) => {
                input.addEventListener('change', () => {
                    t.reloadContent();
                });
            });
            if( container.querySelector('.ImageLibraryHeader form') ) {
                container.querySelector('.ImageLibraryHeader input[name="search"]').addEventListener('input', () => {
                    clearTimeout( timeInput );
                    timeInput = setTimeout(
                        () => {
                            t.reloadContent();
                        },
                        500
                    );
                });
                container.querySelector('.ImageLibraryHeader form').addEventListener('submit', ( event ) => {
                    event.preventDefault();
                    t.reloadContent();
                });
            }
            document.addEventListener('click', ( event ) => { 
                if( event.target.hasAttribute('data-number') && event.target.closest('.Pagination') ) {
                    t.reloadContent( event.target.getAttribute('data-number') );
                }

                if( event.target.classList.contains('group__button') || event.target.classList.contains('topic__button') ) {
                    t.setElementHeight( event.target.getAttribute('data-slug') );
                }

                if( event.target.classList.contains('box__filter') && event.target.closest('.SuggestedTrainingsTopic2__row') ) {
                    document.querySelector('.SuggestedTrainingsTopic2__filters').classList.add('active');
                }
                if( event.target.classList.contains('header__close') && event.target.closest('.SuggestedTrainingsTopic2__filters') ) {
                    document.querySelector('.SuggestedTrainingsTopic2__filters').classList.remove('active');
                }

            });

            setTimeout( () => {
                if( document.querySelector('main').getAttribute('data-layout') == 'list' && document.querySelector('input[name="topic"]:checked') ) {
                    t.setElementHeight( document.querySelector('input[name="topic"]:checked').value , true );
                }
            },200 );

            window.addEventListener('resize', () => {

                document.querySelectorAll('.content__topic').forEach( ( topic, index ) => {
                    if( topic.querySelector('.topic__button.active') ) {
                        topic.querySelector('.topic__wrapper').style.height = topic.querySelector('.topic__wrapper .wrapper__content').offsetHeight + 'px';
                    }
                });
                setTimeout(() => {
                    document.querySelectorAll('.content__group').forEach( ( group, index ) => {
                        if( group.querySelector('.group__button.active') ) {
                            group.querySelector('.group__wrapper').style.height = group.querySelector('.group__wrapper .wrapper__content').offsetHeight + 'px';
                        }
                    });
                }, 250 );

            });

        }
    },
    reloadContent( pageNum = 1 ) {
        let t = this;
        document.querySelector('main').classList.add('processing');
        document.querySelector('.SuggestedTrainingsTopic2__filters').classList.remove('active');
        

        let args = t.getArgs( pageNum );
        t.setHisotry( args );
        args.push('fetch=1');

        fetch( window.location.origin + window.location.pathname +'?'+args.join('&') ,{
            method: "GET"
        })
        .then( ( response ) => {
            return response.text();
        })
        .then( (html) => {
            
            let content = document.createElement('div');
            content.innerHTML = html;

            document.querySelector('.SuggestedTrainingsTopic2__content').innerHTML = content.querySelector('.SuggestedTrainingsTopic2__content').innerHTML;
            document.querySelector('.SuggestedTrainingsTopic2__row .row__heading').textContent = content.querySelector('.SuggestedTrainingsTopic2__row .row__heading').textContent;
            LazyLoad.init();

            if( document.querySelector('main').getAttribute('data-layout') == 'list' && document.querySelector('input[name="topic"]:checked') ) {
                t.setElementHeight( document.querySelector('input[name="topic"]:checked').value , true );
            }

            document.querySelector('main').classList.remove('processing');

        })
        .catch( (error) => {
            console.log( error );
            document.querySelector('main').classList.remove('processing');
        });

    },
    getArgs( pageNum = 1 ) {
        let t = this;
        let args = [];
        
        document.querySelectorAll('.FilterList').forEach( ( element, index ) => {
            let items = [];
            element.querySelectorAll('input:checked').forEach( ( input, index ) => {
                items.push( input.value );
            });
            if( items.length > 0 ) {
                args.push( element.querySelector('.content__list input').getAttribute('name')+'='+items.join(',') );
            }
        });
        if( document.querySelector('input[name="layout"]:checked') ) {
            args.push( 'layout='+document.querySelector('input[name="layout"]:checked').value );
        }
        if( document.querySelector('input[name="orderby"]:checked') ) {
            args.push( 'orderby='+document.querySelector('input[name="orderby"]:checked').value );
        }
        if( document.querySelector('.ImageLibraryHeader input[name="search"]') && document.querySelector('.ImageLibraryHeader input[name="search"]').value ) {
            args.push( 'search='+document.querySelector('.ImageLibraryHeader input[name="search"]').value );
        }
        if( pageNum != 1 ) {
            args.push( 'page_num='+pageNum );
        }

        return args;

    },
    setHisotry( args = null ) {
        let t = this;
        if( args == null ) {
            args = t.getArgs();
        }
        window.history.pushState( "", "", window.location.origin + window.location.pathname +'?'+args.join('&') );
    },
    setElementHeight( slug = null, forceOpen = false ) {
        let t = this;
        let tempHeight = 0;

        if( !slug ) {
            // -- close all tabs -- //
                document.querySelectorAll('.content__group').forEach( ( group, index ) => {
                    group.querySelector('.group__button').classList.remove('active');
                    group.querySelector('.group__wrapper').style.height = '0px';

                    group.querySelectorAll('.content__topic').forEach( (topic, indexT) => {
                        topic.querySelector('.topic__button').classList.remove('active');
                        topic.querySelector('.topic__wrapper').style.height = '0px';
                    });
                });
            // -- END -- //
            return;
        }
        let element = document.getElementById( slug );
        let elementParent = null;

        if( !element ) {
            return;
        }

        if( element.classList.contains('content__group') ) {

            // -- close other tabs -- //
                if( forceOpen ) {
                    document.querySelectorAll('.content__group').forEach( ( group, index ) => {                  
                        if( group.getAttribute('id') != slug ) {

                            group.querySelector('.group__button').classList.remove('active');
                            group.querySelector('.group__wrapper').style.height = '0px';
                            group.querySelectorAll('.content__topic').forEach( (topic, indexT) => {
                                topic.querySelector('.topic__button').classList.remove('active');
                                topic.querySelector('.topic__wrapper').style.height = '0px';
                            });

                        } else {
                            group.querySelector('.group__wrapper').style.height = ( group.querySelector('.group__wrapper').offsetHeight - tempHeight ) + 'px';
                        }
                        
                    });
                }
            // -- END -- //

            if( !element.querySelector('.group__button').classList.contains('active') || forceOpen ) {
                tempHeight = 0;
                element.querySelector('.group__button').classList.add('active');
                element.querySelectorAll('.content__topic .topic__button.active').forEach( ( btn, index ) => {
                    tempHeight += btn.closest('.content__topic').querySelector('.topic__wrapper').offsetHeight;
                    btn.classList.remove('active');
                    btn.closest('.content__topic').querySelector('.topic__wrapper').style.height = '0px';
                    console.log( tempHeight );
                });
                element.querySelector('.group__wrapper[data-slug="'+slug+'"]').style.height = ( element.querySelector('.group__wrapper[data-slug="'+slug+'"] .wrapper__content').offsetHeight - tempHeight ) + 'px';
                
            } else {
                element.querySelector('.group__button').classList.remove('active');
                element.querySelector('.group__wrapper[data-slug="'+slug+'"]').style.height = '0px';

                element.querySelectorAll('.content__topic').forEach( ( topic, index ) => {
                    topic.querySelector('.topic__button').classList.remove('active');
                    topic.querySelector('.topic__wrapper').style.height = '0px';
                });

            }
        }
        if( element.classList.contains('content__topic') ) {
            elementParent = element.closest('.content__group');

            // -- close other tabs -- //
                if( forceOpen ) {
                    
                    document.querySelectorAll('.content__group').forEach( ( group, index ) => {

                        if( group.getAttribute('id') != elementParent.getAttribute('id') ) {
                            group.querySelector('.group__button').classList.remove('active');
                            group.querySelector('.group__wrapper').style.height = '0px';
                        }

                        group.querySelectorAll('.content__topic').forEach( (topic, indexT) => {
                            if( topic.getAttribute('id') != slug ) {
                                tempHeight += topic.querySelector('.topic__wrapper').offsetHeight;
                                topic.querySelector('.topic__button').classList.remove('active');
                                topic.querySelector('.topic__wrapper').style.height = '0px';
                            }
                        });
                        
                    });
                }
            // -- END -- //

            if( !element.querySelector('.topic__button').classList.contains('active') || forceOpen ) {
                
                element.querySelector('.topic__button').classList.add('active');
                element.querySelector('.topic__wrapper[data-slug="'+slug+'"]').style.height = element.querySelector('.wrapper__content[data-slug="'+slug+'"]').offsetHeight + 'px';
                setTimeout( () => {
                    element.closest('.content__group').querySelector('.group__button').classList.add('active');
                    element.closest('.content__group').querySelector('.group__wrapper').style.height = element.closest('.content__group').querySelector('.group__wrapper .wrapper__content').offsetHeight + 'px';
                    // element.closest('.content__group').querySelector('.group__wrapper').style.height = element.closest('.content__group').querySelector('.group__wrapper .wrapper__content').offsetHeight + element.querySelector('.wrapper__content[data-slug="'+slug+'"]').offsetHeight + 'px';
                }, 300 );
                
            } else {
                element.closest('.content__group').querySelector('.group__wrapper').style.height = element.closest('.content__group').querySelector('.group__wrapper .wrapper__content').offsetHeight - element.querySelector('.topic__wrapper[data-slug="'+slug+'"]').offsetHeight + 'px';
                element.querySelector('.topic__button').classList.remove('active');
                element.querySelector('.topic__wrapper[data-slug="'+slug+'"]').style.height = '0px';
            }

        }

    },
    finalize() {}
}