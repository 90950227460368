import $ from 'jquery';

export default {
	init() {

		var container = document.querySelector('.ScrollTop');

		if( container ) {

			var checkTopScroll = this.debounce( function() {

				if( window.scrollY >= window.innerHeight && !container.classList.contains('active') ) {

					container.classList.add('active');

				} else if( window.scrollY < window.innerHeight && container.classList.contains('active') ) {

					container.classList.remove('active');

				}

			}, 300 );

			checkTopScroll();

			window.addEventListener('scroll', checkTopScroll);

			container.addEventListener('click', function() {

				$('html ,body').animate({'scrollTop': 0 }, 'smooth' );
				container.classList.remove('active');

			});	

		}

	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
	debounce( func, wait ) {

	  	let timeout;
	  	return function() {
	    	clearTimeout(timeout);
	    	timeout = setTimeout(() => func.apply(this, arguments), wait);
		}

	}
};
