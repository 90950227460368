import ShareBox2 from '../ShareBox2/ShareBox2';
export default {
	init() {
		if( document.querySelector('.FaqItem') ) {
			ShareBox2.init();
					
			var faqItems = document.querySelectorAll('.FaqItem--type1');
			if( faqItems.length > 0 ) {
				faqItems.forEach( function( container,index ) {
					if( container.querySelector('.FaqItem__elastic') ) {
						container.querySelector('button.FaqItem__heading').addEventListener('click', function() {
							if( container.querySelector('button.FaqItem__heading').classList.contains('active') ) {
								container.querySelector('button.FaqItem__heading').classList.remove('active');
								container.querySelector('.FaqItem__elastic').style.height = '0px';
								container.querySelector('.FaqItem__elastic').classList.add('FaqItem__elastic--hide');
							} else {
								container.querySelector('button.FaqItem__heading').classList.add('active');
								container.querySelector('.FaqItem__elastic').style.height = container.querySelector('.FaqItem__elastic .elastic__absolute').offsetHeight + 'px'; 
								setTimeout( ()=> {
									container.querySelector('.FaqItem__elastic').classList.remove('FaqItem__elastic--hide');
								}, 200 );
							}
						});
					}
				});
				window.addEventListener('resize', function() {
					faqItems.forEach( function( container, index ) {
						if( container.querySelector('.FaqItem__heading.active') ) {
							container.querySelector('.FaqItem__elastic').style.height = container.querySelector('.FaqItem__elastic .elastic__absolute').offsetHeight + 'px'; 
						}
					});
				})
			}
			document.querySelectorAll('.FaqItem .FaqItem__heading.open').forEach( function( heading, index ) {
				heading.closest('.FaqItem').querySelector('.FaqItem__elastic').style.height = heading.closest('.FaqItem').querySelector('.FaqItem__elastic .elastic__absolute').offsetHeight + 'px'; 
				heading.classList.remove('open');
			});
		}
	},
	finalize() {

	}
}