import LazyLoad from '../../../common/scripts/lazyLoad';
import ImageLibraryDownloadList from '../../Components/ImageLibraryDownloadList/ImageLibraryDownloadList.js';
export default {
    init() {
        ImageLibraryDownloadList.init();

        var t = this;
        document.addEventListener('click', function(e) {
            if( e.target.closest('.CourseTile--mask') ) {
                if( !e.target.closest('.CourseTile__layout') ) {
                    t.removeMask();
                }
            }
            if( e.target.classList.contains('layout__button') && e.target.closest('.CourseTile--mask') ) {
                t.removeMask();
            }
            if( e.target.classList.contains('button__modal') ) {
                t.renderMask();
                t.loadModal( e.target.getAttribute('data-entry-id') );
            }
        });
    },
    loadModal( entryID = null ) {
        var t = this;
        if( entryID ) {
            document.body.classList.add('disableScroll');

            fetch( window.location.origin+'/wp-admin/admin-ajax.php?action=GetCourseCatalogModal&ID='+entryID, {
                method: "GET",
            })
            .then( response => response.text() )
            .then( function(response) {
                if( response ) {
                    document.querySelector('.CourseTile--mask .CourseTile__layout .layout__content').insertAdjacentHTML('beforeend', response);
                    LazyLoad.init();
                } else {
                    t.removeMask();
                }
                if( document.body.classList.contains('logged-in') ) {
                    console.log( response ) ;
                }
            })
            .catch( function( error ) {
                if( document.body.classList.contains('logged-in') ) {
                    console.log("GetCourseCatalogModal reload error");
                    console.log(error);
                }
                t.removeMask();
            });
        }
    },
    renderMask() {
        if( document.querySelector('.CourseTile--mask') ) {
            document.querySelector('.CourseTile--mask .CourseTile__layout .layout__content').innerHTML = '';
            document.querySelector('.CourseTile--mask').classList.add('active');
        } else {
            document.body.insertAdjacentHTML('beforeend', '<div class="CourseTile--mask"><div class="CourseTile__layout"><div class="layout__content"></div><button class="layout__button"><span></span></button></div></div>');
            setTimeout(() => {
                document.querySelector('.CourseTile--mask').classList.add('active');
            }, 200);
        }
    },
    removeMask() {
        let mask = document.querySelector('.CourseTile--mask');
        if( mask ) {
            mask.classList.remove('active');
            document.body.classList.remove('disableScroll');
            setTimeout(
                function() {
                    mask.remove();
                },
                200 
            )
        }
    },
    finalize() {

    }
}