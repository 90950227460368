export default {

	init() {

		var container = document.querySelector('.Newsletter');

		if( container ) {

			var callback = container.querySelector('.Newsletter__error');
			var callbackMessage = callback.querySelector('.error__message p');

			var form = container.querySelector('form');
			
			var input = form.querySelector('input[name="email"]');
			var agreement = container.querySelector('input[name="agreement"]');
			var submitButton = form.querySelector('button');	

			var formSubmit = false;

			var requestData = [];
        	var request = new XMLHttpRequest();

			form.addEventListener('submit', function(e) {

				e.preventDefault();

				if( !formSubmit && !submitButton.classList.contains('disable') ) {

					callback.classList.remove('active');
					submitButton.classList.add('disable');

					if( !input.value || !agreement.checked ) {

						callbackMessage.textContent = container.getAttribute('data-error-empty');
						callback.classList.add('active');
						form.classList.remove('active');
						submitButton.classList.remove('disable');
						return '';

					}

					if( !( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input.value)  ) ) { 

	                    callbackMessage.textContent = container.getAttribute('data-error-valid');
	                    callback.classList.add('active');
	                    form.classList.remove('active');
	                    submitButton.classList.remove('disable');
	                    return '';

	                }

	                // -- Request data -- // 

	                    requestData = [];

	                    requestData.push( 'action=submitNewsletter' );
	                    requestData.push( 'securityCode=' + container.querySelector('input[name="newsletterFieldID"]').value );
	                    requestData.push( 'email='+input.value );

	                    requestData = requestData.join('&');

	                // -- END -- //     


	                var request = new XMLHttpRequest();

	                request.onreadystatechange = function() {

	                    if( request.responseText && request.readyState == 4 && request.status == 200 ) {

	                        var response = JSON.parse( request.responseText );
	                        
	                        if( response.status == 'success' ) {
	                            
	                            formSubmit = true;
	                            callback.textContent;
	                            form.classList.remove('active');
	                            container.querySelector('.Newsletter__success').classList.add('active');

	                        } else if( response.status == 'exist' ) {

	                        	submitButton.classList.remove('disable');
	                            form.classList.remove('active');
	                            callback.classList.add('active');
	                           	callbackMessage.textContent = container.getAttribute('data-form-exists');

	                        } else if( response.status == 'error' ) {

	                            submitButton.classList.remove('disable');
	                            form.classList.remove('active');
	                            callback.classList.add('active');
	                           	callbackMessage.textContent = container.getAttribute('data-form-error');

	                        }

	                    }

	                }

	                // Set up our request
	                request.open('POST', window.location.origin +'/wp-admin/admin-ajax.php' );

	                // Add the required HTTP header for form data POST requests
	                request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

	                // Finally, send our data.
	                request.send( requestData );

				}

			});

			callback.querySelector('button').addEventListener('click', e => {

				submitButton.classList.remove('disable');
				callback.classList.remove('active');
				form.classList.add('active');


			});

		}

	},
	finalize() {

	}

}